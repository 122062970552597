import React from 'react';
import { number, oneOfType, string } from 'prop-types';

export const Cell = ({ value }) => {
  return <div className="py-1">{value}</div>;
};

Cell.propTypes = {
  value: oneOfType([string, number]),
};
