import React, { useMemo } from 'react';

import { MapGeometry } from 'atoms/Map/MapGeometry/MapGeometry';
import { useValidData } from 'hooks';

export const useWorksitesLayer = (worksitesData, showSites) => {
  const validWorksitesData = useValidData(worksitesData);

  const worksites = useMemo(() => {
    if (validWorksitesData && showSites) {
      return validWorksitesData.map(({ id, geometry, color, label }) => (
        <MapGeometry key={id} geometry={geometry} color={color} label={label} />
      ));
    }
    return null;
  }, [validWorksitesData, showSites]);

  return { worksites };
};
