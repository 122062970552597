import { shape, number, string, arrayOf, instanceOf, oneOfType } from 'prop-types';
import { userType, brandType, fetchedPeriodType, dateFormatType } from '../../types';

export const singleChartDataType = arrayOf(
  shape({
    value: number,
    timestamp: oneOfType([string, instanceOf(Date)]),
  }),
);

export const chartDataType = shape({
  pageviews: singleChartDataType,
});

export const analyticsFetchedDataType = shape({
  analytics: shape({
    users: arrayOf(userType),
    brands: arrayOf(brandType),
    period: fetchedPeriodType,
    chartData: chartDataType,
    dateFormat: dateFormatType,
  }),
});
