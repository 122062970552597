import React from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import { videoType } from 'API/hooks/useFetchVideos/types';
import { dateFormatType } from 'API/types';

import styles from './VideoClip.module.scss';
import { VideoReady } from './VideoReady/VideoReady';
import { VideoProcessing } from './VideoProcessing/VideoProcessing';
import { VideoError } from './VideoError/VideoError';

momentDurationFormatSetup(moment);

export const VideoClip = ({ video, dateFormat }) => {
  return (
    <div className={`card ${styles.wrapper}`}>
      <div className="d-flex flex-column">
        <strong>Clip no. {video.id}</strong>
        {video.timestamp && (
          <span className={styles.timestamp}>
            {moment(video.timestamp).format(`${dateFormat} hh:mmA`)}
          </span>
        )}
        {video.duration &&
          (video.duration < 60 ? (
            <span className={styles.timestamp}>
              Duration {moment.duration(video.duration, 'seconds').format('ss')} s
            </span>
          ) : (
            <span className={styles.timestamp}>
              Duration {moment.duration(video.duration, 'seconds').format('m')} min
            </span>
          ))}
        {video.description && <span className={styles.description}>{video.description}</span>}
      </div>
      {(video.state === 'requested' || video.state === 'processing') && (
        <VideoProcessing video={video} />
      )}
      {video.state === 'error' && <VideoError video={video} />}
      {video.state === 'ready' && <VideoReady video={video} />}
    </div>
  );
};

VideoClip.propTypes = {
  video: videoType,
  dateFormat: dateFormatType,
};
