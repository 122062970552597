import React from 'react';

export const TrailerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <path
        d="M0 32H16 496h16V48 352H616h16v32H616 288c0 53-43 96-96 96s-96-43-96-96H16 0V368 48 32zM128 384a64 64 0 1 0 128 0 64 64 0 1 0 -128 0zm154.5-32H480V64H32V352h69.5c13.2-37.3 48.7-64 90.5-64s77.4 26.7 90.5 64zM128 96v16V240v16H96V240 112 96h32zm96 0v16V240v16H192V240 112 96h32zm96 0v16V272v16H288V272 112 96h32zm96 0v16V272v16H384V272 112 96h32z"
        stroke="#fff"
        fill="#fff"
      />
    </svg>
  );
};
