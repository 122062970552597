import React from 'react';
import PropTypes from 'prop-types';
import PaginationButton from './PaginationButton';

import styles from './TableCore.module.scss';

const NUMBER_OF_BUTTONS_BELOW_OR_ABOVE_CURRENT_PAGE = 3;
const ROWS_WITHOUT_PAGINATION = 50;

const Pagination = ({
  paginationClassName,
  gotoPage,
  canPreviousPage,
  previousPage,
  pageOptions,
  nextPage,
  canNextPage,
  pageCount,
  setPageSize,
  rows,
  state: { pageIndex, pageSize },
}) => {
  const sizeOptions = [50, 100].map(sizeOption => {
    if (sizeOption === pageSize)
      return (
        <span key={`size-option-${sizeOption}`} className="page-size-option">
          {sizeOption}
        </span>
      );
    else
      return (
        <a
          key={`size-option-${sizeOption}`}
          href="#"
          onClick={event => {
            event.preventDefault();
            setPageSize(Number(sizeOption));
          }}
          className={`text-secondary ${styles.pageSizeOption}`}
        >
          {sizeOption}
        </a>
      );
  });

  const pageButtons = () => {
    const output = [];
    for (let index = 0; index < pageOptions.length; index++) {
      if (
        index - pageIndex <= NUMBER_OF_BUTTONS_BELOW_OR_ABOVE_CURRENT_PAGE &&
        index - pageIndex >= -NUMBER_OF_BUTTONS_BELOW_OR_ABOVE_CURRENT_PAGE
      )
        output.push(
          <PaginationButton
            key={`page-button-${index}`}
            isActive={index === pageIndex}
            onClick={() => gotoPage(index)}
          >
            {Math.abs(index - pageIndex) ===
            NUMBER_OF_BUTTONS_BELOW_OR_ABOVE_CURRENT_PAGE
              ? '...'
              : `${index + 1}`}
          </PaginationButton>,
        );
    }

    return output;
  };

  return (
    <div className={`${styles.corePagination} ${paginationClassName}`}>
      {pageOptions.length > 1 && (
        <div className="btn-group" role="group" aria-label="pagination">
          {canPreviousPage && (
            <PaginationButton onClick={() => gotoPage(0)}>
              {'« First'}
            </PaginationButton>
          )}
          {canPreviousPage && (
            <PaginationButton onClick={() => previousPage()}>
              {'‹ Prev'}
            </PaginationButton>
          )}
          {pageButtons()}
          {canNextPage && (
            <PaginationButton onClick={() => nextPage()}>
              {'Next ›'}
            </PaginationButton>
          )}
          {canNextPage && (
            <PaginationButton onClick={() => gotoPage(pageCount - 1)}>
              {'Last »'}
            </PaginationButton>
          )}
        </div>
      )}
      <div>
        {rows.length > ROWS_WITHOUT_PAGINATION ? (
          <>Per page {sizeOptions} | </>
        ) : (
          ''
        )}
        Total {rows.length}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  paginationClassName: PropTypes.string,
  page: PropTypes.array.isRequired,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageOptions: PropTypes.array,
  pageCount: PropTypes.number,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  setPageSize: PropTypes.func,
  rows: PropTypes.array.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
};

export default Pagination;
