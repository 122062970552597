import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import polylabel from 'polylabel';
import GeoPropTypes from 'geojson-prop-types';
import { debounce } from 'debounce';
import { isGeoJsonCircle } from 'utils';
import { getGeoJsonFromShape } from 'utils';
import { addInfoWindow } from 'utils';
import { ContextualMapMarker } from '../MapMarkers/ContextualMapMarker';
import MapLabel from '../../../vendor/map-label';

const DRAWN_GEOMETRY_OPACITY = 0.3;

export const MapGeometry = ({
  geometry,
  properties,
  color,
  label,
  googleMapInstance,
  editable = false,
  hidden = false,
  onChange,
}) => {
  const [drawnShapes, setDrawnShapes] = useState([]);

  const addDrawnShape = shape => {
    drawnShapes.push(shape);
    setDrawnShapes([...drawnShapes]);
  };
  useEffect(() => {
    renderNewGeometry();

    function renderNewGeometry() {
      if (googleMapInstance) {
        clearPaths();

        const geometryShape = renderShape();
        renderShapeLabel(geometryShape);
      }
    }

    function clearPaths() {
      drawnShapes.forEach((path, index) => {
        path && path.setMap(null);
        drawnShapes[index] = null;
      });

      setDrawnShapes([]);
    }

    function renderShape() {
      const shapesCommonProps = {
        strokeColor: color,
        strokeOpacity: 0.15,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: DRAWN_GEOMETRY_OPACITY,
        map: googleMapInstance.map,
        draggable: editable,
        editable: editable,
        visible: !hidden,
      };
      let shape;

      if (isGeoJsonCircle(geometry)) {
        const [lng, lat] = geometry.geometry.coordinates;
        shape = new googleMapInstance.maps.Circle({
          ...shapesCommonProps,
          center: { lat, lng },
          radius: geometry.properties.radius,
        });
        setCircleOnChangeListeners(shape);
      } else if (geometry.type === 'Polygon') {
        const paths = getPathsFromGeometry(geometry);
        shape = new googleMapInstance.maps.Polygon({
          ...shapesCommonProps,
          paths: paths,
        });
        shape = addInfoWindow(
          googleMapInstance,
          shape,
          properties,
          getApproximateCenter,
        );
        setPolygonOnChangeListeners(shape);
      } else {
        return;
      }

      shape.setMap(googleMapInstance.map);
      addDrawnShape(shape);
      return shape;
    }

    function getPathsFromGeometry(geometry) {
      if (geometry.type === 'Polygon') {
        return geometry.coordinates[0].map(coord => {
          const [lng, lat] = coord;
          return { lat, lng };
        });
      }
    }

    function setCircleOnChangeListeners(circleShape) {
      setShapeListeners(circleShape, ['center_changed', 'radius_changed']);
    }

    function setPolygonOnChangeListeners(polygonShape) {
      setShapeListeners(polygonShape, ['insert_at', 'remove_at', 'set_at']);
    }

    function setShapeListeners(shape, eventsToListen) {
      if (!onChange) {
        return;
      }

      const ON_CHANGE_DELAY = 100;
      const onShapeChange = _event => {
        onChange(getGeoJsonFromShape(shape));
      };
      const debouncedOnChange = debounce(onShapeChange, ON_CHANGE_DELAY);

      const shapeType = geometry.type === 'Polygon' ? shape.getPath() : shape;

      eventsToListen.forEach(eventName => {
        googleMapInstance.maps.event.addListener(
          shapeType,
          eventName,
          debouncedOnChange,
        );
      });
    }

    function renderShapeLabel(shape) {
      if (!label || editable) {
        return;
      }

      const centerPoint = getApproximateCenter(shape);
      const drawnLabel = new MapLabel({
        text: label,
        position: centerPoint,
        map: googleMapInstance.map,
        fontSize: 13,
        align: 'center',
        strokeWeight: 0.5,
        fontColor: 'rgba(0, 0, 0, 0.8)',
      });

      addDrawnShape(drawnLabel);
    }

    function getApproximateCenter(shape) {
      const nativeCenter = shape.getCenter ? shape.getCenter() : null;
      if (nativeCenter) {
        return nativeCenter;
      }
      const geoJsonPolygon = getGeoJsonFromShape(shape);
      if (geometry.coordinates.every(c => typeof c === 'number')) {
        var [lng, lat] = geometry.coordinates;
      } else {
        const centerCoords = polylabel([geoJsonPolygon.coordinates[0]]);
        var [lng, lat] = centerCoords;
      }

      return new google.maps.LatLng(lat, lng);
    }

    return () => {
      clearPaths();
    };
  }, [googleMapInstance, geometry, properties, color, label, editable]);

  if (!isGeoJsonCircle(geometry) && geometry.type !== 'Polygon') {
    return (
      <ContextualMapMarker
        properties={properties}
        mapInstance={googleMapInstance}
        position={geometry.coordinates}
      />
    );
  }

  return null;
};

MapGeometry.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  googleMapInstance: PropTypes.object,
  geometry: PropTypes.oneOfType([
    GeoPropTypes.Polygon,
    GeoPropTypes.Feature,
    GeoPropTypes.Point,
  ]).isRequired,
  properties: PropTypes.object,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  editable: PropTypes.bool,
  hidden: PropTypes.bool,
  onChange: PropTypes.func,
};

export const mapGeometryFactory = ({
  key,
  geometry,
  properties,
  color,
  label,
  googleMapInstance,
  editable = false,
  hidden = false,
  onChange,
}) => {
  if (!geometry) {
    return null;
  } else {
    var [lng, lat] = [null, null];
    if (
      geometry.hasOwnProperty('coordinates') &&
      geometry.coordinates.every(c => typeof c === 'number')
    ) {
      [lng, lat] = geometry.coordinates;
    }
    return (
      <MapGeometry
        lat={lat}
        lng={lng}
        {...{
          key,
          geometry,
          properties,
          color,
          label,
          googleMapInstance,
          editable,
          hidden,
          onChange,
        }}
      />
    );
  }
};
