import React from 'react';
import { string } from 'prop-types';

import styles from '../TableSection.module.scss';

export const EditEntityCell = ({ value }) => {
  return (
    <>
      {value && (
        <div className={styles.iconCell}>
          <a
            className={`d-flex justify-content-center align-items-center btn btn-dark ${styles.iconWrapper}`}
            href={value}
          >
            <i className={`fa-light fa-gear ${styles.icon}`} />
          </a>
        </div>
      )}
    </>
  );
};

EditEntityCell.propTypes = {
  value: string,
};
