import { useState, useEffect } from 'react';

export const useShowMessage = () => {
  const [msgToDisplay, setMsgToDisplay] = useState({ msg: '', className: '' });

  // useEffect(() => {
  //   let timeoutId;
  //   if (msgToDisplay.msg) {
  //     timeoutId = setTimeout(
  //       () => setMsgToDisplay({ msg: '', className: '' }),
  //       5000,
  //     );
  //   }
  //   return () => clearTimeout(timeoutId);
  // }, [msgToDisplay]);

  return { msgToDisplay, setMsgToDisplay };
};
