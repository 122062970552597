import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

export const Modal = ({
  children,
  title,
  footer,
  className,
  isOpen,
  onClose,
  onClickAway,
}) => {
  const returnTitle = () => {
    if (typeof title === 'string') {
      return (
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
      );
    } else {
      return title;
    }
  };

  return !isOpen ? null : (
    <div
      className={`${styles.modalAtom} ${styles.modalBackdrop} ${className}`}
      onClick={onClickAway}
      role="dialog"
      aria-labelledby="modalComponent"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className={`modal-header title-${!!title}`}>
            {returnTitle()}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickAway: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footer: PropTypes.element,
};

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onClickAway: () => {},
  className: '',
};
