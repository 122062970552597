import React, { useState } from 'react';

import { MultiSelect, IncursionsChart } from '../../../atoms';
import { singleChartDataType } from '../../../API/hooks/useFetchInsights/types';
import { dateFormatType } from '../../../API/types';
import styles from './TimelineChart.module.scss';
import { periodType } from '../../../atoms/DatePicker/types';

const options = [{ value: 'incursionHistory', label: 'Incursion History' }];

export const TimelineChart = ({
  acknowledgedIncursions,
  unacknowledgedIncursions,
  period,
  dateFormat,
}) => {
  const [dropdownOption, setDropdownOption] = useState(options[0]);

  return (
    <div className="pb-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className={styles.title}>Timeline</h5>
        <MultiSelect
          currentValues={dropdownOption}
          initialValues={dropdownOption}
          options={options}
          onChange={(selectedValues) => setDropdownOption(selectedValues)}
          configOptions={{
            isMulti: false,
            closeMenuOnSelect: true,
            isSearchable: true,
          }}
          className={styles.multiSelect}
        />
      </div>
      <IncursionsChart
        acknowledgedIncursions={acknowledgedIncursions}
        unacknowledgedIncursions={unacknowledgedIncursions}
        period={period}
        dateFormat={dateFormat}
        wrapperClassName={styles.chart}
      />
    </div>
  );
};

TimelineChart.propTypes = {
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
  period: periodType,
  dateFormat: dateFormatType,
};
