import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['splashable'];

  get time() {
    return this.data.get('time');
  }

  connect() {
    const element = this.splashableTarget;
    $(element).on('ajax:success', (e, data) => {
      this.showMessage(e, data);
      this.splashableTarget.innerHTML = data.html;
      this.splashableTarget
        .querySelectorAll('.g-recaptcha')
        .forEach(element => grecaptcha.render(element));
    });
  }

  showMessage(e, data) {
    if (data.status == 'ok') {
      e.target.style.display = 'none';
      setTimeout(function() {
        window.location.href = data.redirect;
      }, this.time);
    }
  }
}
