import useSWR from 'swr';

import { fetchProjectsErrorMsg } from '../../utils/errorMsg';
import { useShowMessage } from '../../../hooks';

export const useFetchProjects = ({ initialData }) => {
  const { msgToDisplay, setMsgToDisplay } = useShowMessage();

  const { data, mutate } = useSWR(`${process.env.API_URL}/projects`, {
    fallbackData: initialData,
    errorRetryCount: 3,
    onError: (err) => {
      setMsgToDisplay({
        msg: `${fetchProjectsErrorMsg}. Error: ${err.message}`,
        className: 'text-danger',
      });
    },
    onErrorRetry: (err) =>
      setMsgToDisplay({
        msg: `${fetchProjectsErrorMsg}. Error: ${err.message}`,
        className: 'text-danger',
      }),
    onSuccess: () => {
      setMsgToDisplay({ msg: '', className: '' });
    },
    revalidateOnMount: true,
    keepPreviousData: true,
  });

  return { data, msgToDisplay, mutate };
};
