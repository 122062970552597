import React, { useState } from 'react';
import axios from 'axios';
import { SWRConfig } from 'swr';

import { fetchApi } from '../../utils';
import { useFetchWorksites } from '../../API/hooks';
import { worksiteFetchedDataType } from '../../API/hooks/useFetchWorksites/types';
import Worksites from './Worksites';
import styles from './worksites.module.scss';

export const APILinkedWorksites = ({ initialData: { worksites: initialWorksites, projects } }) => {
  const {
    data: worksites,
    mutate,
    msgToDisplay,
  } = useFetchWorksites({
    initialData: initialWorksites,
  });

  const [isLoading, setIsLoading] = useState(false);

  function updateWorksiteData(newWorksiteObj, remove = false) {
    const currentWorksiteIndex = worksites.findIndex((site) => site.id === newWorksiteObj.id);

    const isWorksiteExistAlready = currentWorksiteIndex !== -1;

    if (remove) {
      deleteWorksite();
    } else if (isWorksiteExistAlready) {
      updateWorksite();
    } else {
      addNewWorksite();
    }

    function addNewWorksite() {
      setIsLoading(true);
      const endpointUrl = `${process.env.API_URL}/worksites`;
      axios
        .post(endpointUrl, newWorksiteObj)
        .then(() => mutate())
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          alert(`Unable to create such site. Error: ${error.message}`);
        });
    }

    function updateWorksite() {
      setIsLoading(true);
      const endpointUrl = `${process.env.API_URL}/worksites/${newWorksiteObj.id}`;
      axios
        .put(endpointUrl, newWorksiteObj)
        .then(() => mutate())
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          alert(`Unable to update such site. Error: ${error.message}`);
        });
    }

    function deleteWorksite() {
      const CONFIRMATION_MESSAGE = 'The site will be deleted. Are you sure?';
      const endpointUrl = `${process.env.API_URL}/worksites/${newWorksiteObj.id}`;

      if (!isWorksiteExistAlready) {
        return;
      }

      if (window.confirm(CONFIRMATION_MESSAGE)) {
        setIsLoading(true);
        axios
          .delete(endpointUrl)
          .then(() => mutate())
          .then(() => setIsLoading(false))
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
            alert(`Unable to delete such site. Error: ${error.message}`);
          });
      }
    }
  }

  return (
    <SWRConfig
      value={{
        fetcher: fetchApi,
      }}
    >
      {isLoading ? (
        <div className="text-center">
          <div className={styles.ldsDualRing} />
          <p>Loading...</p>
        </div>
      ) : (
        <Worksites
          updateWorksiteData={updateWorksiteData}
          worksites={worksites}
          projects={projects}
          fetchWorksitesErrorMsg={msgToDisplay}
        />
      )}
    </SWRConfig>
  );
};

APILinkedWorksites.propTypes = {
  initialData: worksiteFetchedDataType,
};

export default (props) => <APILinkedWorksites {...props} />;
