import React from 'react';
import { arrayOf, oneOf } from 'prop-types';

import { machineSchema } from '../../../../API/hooks/useFetchEntities/types';
import { useMachinesMarkers } from './Markers/useMachinesMarkers';
import { Map } from '../../../../atoms/Map/Map';

export const MapSection = ({ machinesData, mapStyle = '' }) => {
  const { machineMarkers } = useMachinesMarkers(machinesData);

  const getMapComponents = () => {
    let mapComponents = [];
    if (machineMarkers) {
      mapComponents = [...mapComponents, ...machineMarkers];
    }

    return mapComponents;
  };

  return (
    <Map connectPoints mapStyle={mapStyle}>
      {getMapComponents()}
    </Map>
  );
};

MapSection.propTypes = {
  machinesData: arrayOf(machineSchema).isRequired,
  mapStyle: oneOf(['silver', '']),
};
