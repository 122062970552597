import React from 'react';
import PropTypes from 'prop-types';

import styles from './HintView.module.scss';

export const HintView = ({ title, hintText, handleIconXClick }) => {
  return (
    <div className="d-flex justify-content-between align-items-start">
      <div>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{hintText}</p>
      </div>
      <button
        className={`d-flex p-0 ml-3 ${styles.button}`}
        onClick={handleIconXClick}
      >
        <i className={`fa-light fa-rectangle-xmark ${styles.icon}`} />
      </button>
    </div>
  );
};

HintView.propTypes = {
  title: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  handleIconXClick: PropTypes.func,
};
