import { useState, useCallback } from 'react';

import { useFetchEntities } from '../../API/hooks';
import { useDebounce, useGetMultiselectOptions } from '../../hooks';
import { useStorageFilters } from '../../contexts/StorageFiltersContext/useStorageFilters';

import { ENTITIES_SIZE_PER_PAGE } from './consts';

export const useEntities = (initialDataFromProps, types) => {
  const [showInactive, setShowInactive] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    perPage: ENTITIES_SIZE_PER_PAGE[0],
  });
  const [sortingParams, setSortingParams] = useState({
    sort: null,
    sortBy: null,
  });
  const [showModal, setShowModal] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);
  const debouncedPaginationParams = useDebounce(paginationParams, 500);
  const debouncedSortingParams = useDebounce(sortingParams, 500);

  const {
    currentProjects,
    setCurrentProjects,
    debouncedProjects,
    currentSites,
    setCurrentSites,
    debouncedSites,
    period,
    setPeriod,
    currentMachineKinds,
    setCurrentMachineKinds,
    debouncedMachineKinds,
  } = useStorageFilters();

  const {
    data: {
      projects: projectsFromApi,
      sites: sitesFromApi,
      entitiesList,
      dateFormat,
      pagination: { totalPages, totalCount },
      availableKinds: fetchedMachineKinds,
    },
    msgToDisplay,
    mutate: revalidateEntities,
    isLoading: fetchEntitiesIsLoading,
  } = useFetchEntities({
    initialData: initialDataFromProps,
    shouldFetch: true,
    currentProjects: debouncedProjects,
    currentSites: debouncedSites,
    period,
    searchText: debouncedSearchText,
    types,
    page: debouncedPaginationParams.currentPage,
    per: debouncedPaginationParams.perPage,
    sort: debouncedSortingParams.sort,
    sortBy: debouncedSortingParams.sortBy,
    showInactive,
    showArchived,
    machineKinds: debouncedMachineKinds,
  });

  const projectOptions = useGetMultiselectOptions(projectsFromApi);
  const siteOptions = useGetMultiselectOptions(sitesFromApi);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleTableHeaderClick = useCallback((clickedColumnHeader) => {
    setSortingParams(({ sort, sortBy }) => {
      if (sortBy === clickedColumnHeader) {
        if (sort === 'asc') {
          return { sort: 'desc', sortBy };
        }
        return { sort: null, sortBy: null };
      }
      return { sort: 'asc', sortBy: clickedColumnHeader };
    });
  }, []);

  return {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    period,
    setPeriod,
    dateFormat,
    searchText,
    setSearchText,
    projectOptions,
    siteOptions,
    msgToDisplay,
    entities: entitiesList,
    setShowInactive,
    showInactive,
    setShowArchived,
    showArchived,
    showModal,
    setShowModal,
    handleClose,
    handleShow,
    revalidateEntities,
    totalPages,
    totalCount,
    paginationParams,
    setPaginationParams,
    sortingParams,
    handleTableHeaderClick,
    fetchEntitiesIsLoading,
    currentMachineKinds,
    setCurrentMachineKinds,
    fetchedMachineKinds,
  };
};
