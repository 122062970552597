import React, { useMemo } from 'react';
import moment from 'moment';

import { InsightsMapMarker } from '../../../Map/MapMarkers/InsightsMapMarker';
import { useValidData } from '../../../../hooks';

export const useLocationHistoryLayer = (locationsData, showLocationHistory, dateFormat) => {
  const validLocationsData = useValidData(locationsData);

  const locations = useMemo(() => {
    if (validLocationsData && validLocationsData.length > 0) {
      const data = showLocationHistory
        ? validLocationsData
        : [validLocationsData[validLocationsData.length - 1]];

      return data.map(({ lng, lat, timestamp }, index) => (
        <InsightsMapMarker
          key={`${timestamp}-${lat}-${lng}`}
          lat={lat}
          lng={lng}
          mostRecent={index === data.length - 1}
          hoverText={<>{moment(timestamp).format(`${dateFormat}, HH:mm:ss Z`)}</>}
        />
      ));
    }
    return null;
  }, [validLocationsData, showLocationHistory]);

  return { locations };
};
