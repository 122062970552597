import React from 'react';

import { Skeleton } from 'atoms/Skeleton/Skeleton';
import { DarkCard } from '../../DarkCard/DarkCard';

import styles from './SCSInsightsTableSkeleton.module.scss';

export const SCSInsightsTableSkeleton = () => (
  <DarkCard className={styles.wrapper}>
    <Skeleton className={styles.skeleton} />
    <div className={styles.wrapper}>
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
    </div>
    <Skeleton className={styles.skeleton} />
  </DarkCard>
);
