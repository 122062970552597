import React, { useState, useRef } from 'react';
import { arrayOf, shape, number, bool, oneOfType, string } from 'prop-types';

import { geometryPolygonType, geometryCircleType, dateFormatType } from '../../API/types';
import { heatmapDataType, locationsType } from '../../API/hooks/useFetchBaseStation/types';
import { SwitcherDefault } from '../Switcher';
import { MapSection } from './MapSection/MapSection';
import { useMapWithLayers } from './useMapWithLayers';
import { MapWrapper } from './MapWrapper/MapWrapper';

export const MapWithLayers = ({
  switchers,
  defaultLayers,
  heatmapData,
  worksitesData,
  locationsData,
  wrapperClassName = '',
  dateFormat,
}) => {
  const [showSites, setShowSites] = useState(false);
  const [showHeatmap, setShowHeatmap] = useState(true);
  const [showLocationHistory, setShowLocationHistory] = useState(false);

  const togglersRef = useRef(null);

  const { togglersHeight } = useMapWithLayers(togglersRef);

  return (
    <MapWrapper wrapperClassName={wrapperClassName}>
      <div className="d-flex flex-wrap" ref={togglersRef}>
        {switchers.heatmap && (
          <SwitcherDefault
            onChange={setShowHeatmap}
            checked={showHeatmap}
            labelText="Incursion Heatmap"
            wrapperClassName="mr-4"
          />
        )}
        {switchers.locationHistory && (
          <SwitcherDefault
            onChange={setShowLocationHistory}
            checked={showLocationHistory}
            labelText="Location History"
            wrapperClassName="mr-4"
          />
        )}
        {switchers.sites && (
          <SwitcherDefault onChange={setShowSites} checked={showSites} labelText="Sites" />
        )}
      </div>
      <MapSection
        defaultLayers={defaultLayers}
        showSites={showSites}
        showHeatmap={showHeatmap}
        showLocationHistory={showLocationHistory}
        heatmapData={heatmapData}
        worksitesData={worksitesData}
        locationsData={locationsData}
        togglersHeight={togglersHeight}
        dateFormat={dateFormat}
      />
    </MapWrapper>
  );
};

MapWithLayers.propTypes = {
  switchers: shape({
    sites: bool,
    heatmap: bool,
    locationHistory: bool,
  }).isRequired,
  defaultLayers: shape({
    heatmap: bool,
  }).isRequired,
  heatmapData: heatmapDataType,
  worksitesData: arrayOf(
    shape({
      id: oneOfType([string, number]),
      label: string,
      color: string,
      geometry: oneOfType([geometryPolygonType, geometryCircleType]),
    }),
  ),
  locationsData: locationsType,
  wrapperClassName: string,
  dateFormat: dateFormatType,
};
