import { useContext } from 'react';
import { StorageFiltersContext } from './StorageFiltersContext';

export const useStorageFilters = () => {
  const context = useContext(StorageFiltersContext);

  if (context === undefined) {
    throw new Error('useStorageFilters must be within StorageFiltersContext');
  }

  return context;
};
