import React from 'react';
import { arrayOf, bool, shape, number, oneOfType, string } from 'prop-types';

import { geometryPolygonType, geometryCircleType, dateFormatType } from '../../../API/types';
import { heatmapDataType, locationsType } from '../../../API/hooks/useFetchBaseStation/types';
import { Map } from '../../Map';
import { useHeatmapLayer, useWorksitesLayer, useLocationHistoryLayer } from './LayersLogic';

export const MapSection = ({
  defaultLayers,
  showSites,
  showHeatmap,
  showLocationHistory,
  heatmapData,
  worksitesData,
  locationsData,
  togglersHeight = 0,
  dateFormat,
}) => {
  const { heatmapMarkers, heatmapOptions } = useHeatmapLayer(
    heatmapData,
    showHeatmap,
    defaultLayers.heatmap,
  );
  const { worksites } = useWorksitesLayer(worksitesData, showSites);
  const { locations } = useLocationHistoryLayer(locationsData, showLocationHistory, dateFormat);

  const getMapComponents = () => {
    let mapComponents = [];
    if (heatmapMarkers) {
      mapComponents = [...mapComponents, ...heatmapMarkers];
    }
    if (worksites) {
      mapComponents = [...mapComponents, ...worksites];
    }
    if (locations) {
      mapComponents = [...mapComponents, ...locations];
    }

    return mapComponents;
  };

  return (
    <div style={{ height: `calc(100% - ${togglersHeight}px)` }}>
      <Map heatmapData={heatmapOptions} connectPoints>
        {getMapComponents()}
      </Map>
    </div>
  );
};

MapSection.propTypes = {
  defaultLayers: shape({
    heatmap: bool,
  }).isRequired,
  showSites: bool.isRequired,
  showHeatmap: bool.isRequired,
  showLocationHistory: bool.isRequired,
  heatmapData: heatmapDataType,
  worksitesData: arrayOf(
    shape({
      id: oneOfType([string, number]),
      label: string,
      color: string,
      geometry: oneOfType([geometryPolygonType, geometryCircleType]),
    }),
  ),
  locationsData: locationsType,
  togglersHeight: number,
  dateFormat: dateFormatType,
};
