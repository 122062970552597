import { shape, number, string, arrayOf, instanceOf, oneOfType } from 'prop-types';
import {
  projectType,
  worksiteType,
  fetchedPeriodType,
  dateFormatType,
  topEntities,
} from '../../types';

export const incursionsType = shape({
  totalIncursionsCount: number,
  totalIncursionsPercentageChange: number,
  acknowledgedIncursionsCount: number,
  acknowledgedIncursionsPercentageChange: number,
  unacknowledgedIncursionsCount: number,
  unacknowledgedIncursionsPercentageChange: number,
  acknowledgedPercentage: number,
  acknowledgedPercentageChange: number,
  activeMachinesCount: number,
  activeTagsCount: number,
  topVehiclesIds: topEntities,
  topTagsIds: topEntities,
});

export const singleChartDataType = arrayOf(
  shape({
    value: number,
    timestamp: oneOfType([string, instanceOf(Date)]),
  }),
);

export const unacknowledgedPercentageChartType = arrayOf(
  shape({
    id: oneOfType([string, number]),
    name: string,
    value: number,
  }),
);

export const chartDataType = shape({
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
  unacknowledgedPercentagePerProject: unacknowledgedPercentageChartType,
  unacknowledgedPercentagePerSite: unacknowledgedPercentageChartType,
  unacknowledgedPercentagePerEntity: unacknowledgedPercentageChartType,
});

export const insightsFetchedDataType = shape({
  insights: shape({
    projects: arrayOf(projectType),
    sites: arrayOf(worksiteType),
    period: fetchedPeriodType,
    incursionsData: incursionsType,
    chartData: chartDataType,
    dateFormat: dateFormatType,
  }),
});
