import React, { useMemo } from 'react';

import { HeatmapMapMarker } from 'atoms/Map/MapMarkers/HeatmapMapMarker';
import { useValidData } from 'hooks';

export const useHeatmapLayer = (
  heatmapData,
  showHeatmap,
  defaultHeatmapLayer,
) => {
  const validHeatmapData = useValidData(heatmapData);

  const heatmapMarkers = useMemo(() => {
    if (validHeatmapData && (showHeatmap || defaultHeatmapLayer)) {
      return validHeatmapData.map(({ location, id }) => (
        <HeatmapMapMarker key={id} lat={location.lat} lng={location.lng} />
      ));
    }
    return null;
  }, [validHeatmapData, showHeatmap, defaultHeatmapLayer]);

  const heatmapOptions = useMemo(() => {
    return {
      positions:
        validHeatmapData && (showHeatmap || defaultHeatmapLayer)
          ? validHeatmapData.map(({ location }) => location)
          : [],
      options: { radius: 15, maxIntensity: 50 },
    };
  }, [validHeatmapData, showHeatmap, defaultHeatmapLayer]);

  return { heatmapMarkers, heatmapOptions };
};
