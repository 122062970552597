import { useMemo } from 'react';

import { useValidData } from './useValidData';

export const useGetHeatmapData = incursions => {
  const validIncursionsData = useValidData(incursions);

  const heatmapData = useMemo(() => {
    if (validIncursionsData) {
      const locations = validIncursionsData.map(incursion => ({
        id: incursion.id,
        location: incursion.location,
      }));

      const validLocations = locations.filter(({ location }) => !!location);

      return validLocations;
    }

    return null;
  }, [validIncursionsData]);

  return heatmapData;
};
