import React, { useMemo } from 'react';
import { arrayOf } from 'prop-types';

import { machineSchema } from '../../../../../API/hooks/useFetchEntities/types';
import { MachinesMapMarker } from '../../../../../atoms/Map/MapMarkers/MachinesMapMarker';
import { useActiveMachineContext } from '../../../../../contexts/ActiveMachineContext';

export const useMachinesMarkers = (machinesData = []) => {
  const { activeMachineId } = useActiveMachineContext();

  const validMachines = useMemo(
    () =>
      machinesData.filter(
        (machine) =>
          machine.lastLocationCoordinates &&
          machine.lastLocationCoordinates?.lat &&
          machine.lastLocationCoordinates?.lng &&
          machine.id &&
          machine.showPath &&
          machine.label &&
          machine.kind,
      ),
    [machinesData],
  );

  const machineMarkers = useMemo(() => {
    if (validMachines && validMachines.length > 0) {
      return validMachines.map((machine) => (
        <MachinesMapMarker
          key={machine.id}
          lat={machine.lastLocationCoordinates.lat}
          lng={machine.lastLocationCoordinates.lng}
          path={machine.showPath}
          id={machine.id}
          internalId={machine.label}
          machineKind={machine.kind}
          active={machine.id === activeMachineId}
        />
      ));
    }
    return null;
  }, [validMachines, activeMachineId]);

  return { machineMarkers };
};

useMachinesMarkers.propTypes = {
  coordinates: arrayOf(machineSchema),
};
