import React from 'react';
import { string } from 'prop-types';

import styles from './LastActivityFormattedCell.module.scss';
import cellStyles from '../TableSection.module.scss';

export const LastActivityFormattedCell = ({ value }) => (
  <div className={`${styles.lastActive} ${cellStyles.cell}`}>{value}</div>
);

LastActivityFormattedCell.propTypes = {
  value: string,
};
