import { getDefaultPath, getMultiQueryPathWithId } from '../../utils';

export const getPath = ({ target, period, currentProjects, currentSites, currentEntities }) => {
  let path = '';

  path += getDefaultPath(null, target, period);
  path += getMultiQueryPathWithId(currentProjects, 'projectsIds');
  path += getMultiQueryPathWithId(currentSites, 'sitesIds');
  path += getMultiQueryPathWithId(currentEntities, 'entitiesIds');

  return path;
};
