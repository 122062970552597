import React from 'react';
import { number, oneOfType, string, bool } from 'prop-types';

import { dateFormatType } from '../../../../API/types';

import { RequestVideoForm } from './RequestVideoForm/RequestVideoForm';
import { VideosHistory } from './VideosHistory/VideosHistory';
import { useVideos } from './useVideos';

export const Videos = ({ dateFormat, baseStationId, online }) => {
  const {
    videos,
    isHistoricVideosLoading,
    requestVideo,
    isRequestVideoLoading,
    requestVideoError,
  } = useVideos(baseStationId);

  return (
    <>
      <RequestVideoForm
        dateFormat={dateFormat}
        requestVideo={requestVideo}
        isRequestVideoLoading={isRequestVideoLoading}
        requestVideoError={requestVideoError}
        online={online}
      />
      <VideosHistory
        videos={videos}
        isHistoricVideosLoading={isHistoricVideosLoading}
        dateFormat={dateFormat}
      />
    </>
  );
};

Videos.propTypes = {
  dateFormat: dateFormatType,
  baseStationId: oneOfType([string, number]),
  online: bool,
};
