import moment from 'moment';

import {
  shape,
  number,
  string,
  arrayOf,
  instanceOf,
  oneOfType,
} from 'prop-types';

export const currentPeriodType = arrayOf(
  oneOfType([string, instanceOf(Date), instanceOf(moment)]),
);

export const optionsType = arrayOf(
  shape({
    value: oneOfType([string, number]),
    label: string,
  }),
);
