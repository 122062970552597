import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import Uppy from '@uppy/core';
import ActiveStorageUpload from 'uppy-activestorage-upload';
import toArray from '@uppy/utils/lib/toArray';

const useUpload = ({
  uploader: { attributeName, directUploadPath, saveUploadPath },
  restrictions,
  handleSave,
  onError,
  onFinish,
}) => {
  const [uppyInstance] = useState(
    Uppy({
      debug: false,
      restrictions: restrictions,
    }),
  );
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    let timeoutRef = null;

    uppyInstance
      .use(ActiveStorageUpload, {
        directUploadUrl: directUploadPath,
      })
      .on('file-added', _file => {
        if (onError) {
          onError(false);
        }

        uppyInstance.upload();
        setProgress(1);
      })
      .on('upload-progress', (_file, progress) => {
        const progressInPercents =
          (progress.bytesUploaded / progress.bytesTotal) * 100;
        if (progressInPercents < 100) {
          setProgress(progressInPercents);
        }
      })
      .on('upload-success', async (file, response) => {
        setProgress(99);

        if (saveUploadPath && !handleSave) {
          let data = {};
          data[attributeName] = response.signed_id;

          await Axios.put(saveUploadPath, data)
            .then(response => {
              setProgress(100);
              delayProgressReset();

              if (
                response.headers['content-type'].match(
                  /\b(?:java|ecma)script\b/,
                )
              ) {
                const scriptElement = document.createElement('script');
                scriptElement.text = response.data;
                document.head
                  .appendChild(scriptElement)
                  .parentNode.removeChild(scriptElement);
              }
            })
            .catch(function(error) {
              setError(error);
              console.error(error);
            });
        } else {
          await handleSave(file, response)
            .then(_response => {
              setProgress(100);
              delayProgressReset();
            })
            .catch(error => {
              console.error(error);
            });
        }

        if (typeof onFinish === 'function') {
          onFinish();
        }
      });

    const delayProgressReset = () => {
      const RESET_DELAY = 4000;
      timeoutRef = setTimeout(resetProgress, RESET_DELAY);
    };

    const resetProgress = () => {
      setProgress(0);
    };

    return () => {
      uppyInstance.close();
      clearTimeout(timeoutRef);
    };
  }, [uppyInstance]);

  function handleInputChange(event) {
    const files = toArray(event.target.files);

    files.forEach(file => {
      try {
        uppyInstance.addFile({
          source: 'Local',
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (error) {
        if (onError) {
          onError(error.message);
        }
        console.error(error);
      }
    });
  }

  return {
    handleInputChange,
    progress,
    error,
  };
};

export default useUpload;
