import React from 'react';
import { func, string } from 'prop-types';
import moment from 'moment';

import { dateFormatType } from '../../../../API/types';
import { periodType } from '../../../DatePicker/types';
import { DrawerTrigger } from '../../DrawerTrigger/DrawerTrigger';
import { Drawer } from '../../Drawer';
import { RangeDateAndTimePicker } from '../../../DatePicker/components/RangeDateAndTimePicker/RangeDateAndTimePicker';

export const DrawerWithDatePicker = ({ dateFormat, period, setPeriod, wrapperClassName }) => {
  return (
    <div className={`d-flex justify-content-end align-items-center ${wrapperClassName || ''}`}>
      <p className="mb-0 mr-2">
        From <strong>{moment(period.startDate).format(`${dateFormat} hh:mmA`)}</strong> to{' '}
        <strong>{moment(period.endDate).format(`${dateFormat} hh:mmA`)}</strong>
      </p>
      <DrawerTrigger
        label={
          <div>
            <i className="fa-solid fa-calendar-days" />
          </div>
        }
        styleOption="icon"
      >
        {(isVisible, onClose) => (
          <Drawer isVisible={isVisible} onClose={onClose} portalDrawerId="drawer-with-date-picker">
            <RangeDateAndTimePicker period={period} setPeriod={setPeriod} dateFormat={dateFormat} />
          </Drawer>
        )}
      </DrawerTrigger>
    </div>
  );
};

DrawerWithDatePicker.propTypes = {
  dateFormat: dateFormatType.isRequired,
  period: periodType.isRequired,
  setPeriod: func.isRequired,
  wrapperClassName: string,
};
