import moment from 'moment';
import { shape, string, instanceOf, oneOfType } from 'prop-types';

export const periodType = shape({
  startDate: oneOfType([string, instanceOf(Date), instanceOf(moment)]),
  endDate: oneOfType([string, instanceOf(Date), instanceOf(moment)]),
});

export const singlePeriodType = shape({
  date: oneOfType([string, instanceOf(Date), instanceOf(moment)]),
});
