import React from 'react';
import { SWRConfig } from 'swr';

import { fetchApi } from '../../utils';
import BaseStation from './BaseStation';
import { baseStationFetchedDataType } from '../../API/hooks/useFetchBaseStation/types';
import { StorageFiltersProvider } from '../../contexts/StorageFiltersContext/StorageFiltersProvider/StorageFiltersProvider';

export const APILinkedBaseStation = (props) => {
  return (
    <SWRConfig
      value={{
        fetcher: fetchApi,
      }}
    >
      <StorageFiltersProvider>
        <BaseStation {...props} />
      </StorageFiltersProvider>
    </SWRConfig>
  );
};

export default (props) => <APILinkedBaseStation {...props} />;

APILinkedBaseStation.propTypes = {
  initialData: baseStationFetchedDataType,
};
