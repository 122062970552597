import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { func, string } from 'prop-types';
import moment from 'moment';

import { periodType } from '../../types';

const DATE_FORMAT = 'YYYY/MM/DD';

export const RangeDatePicker = ({
  period,
  setPeriod,
  wrapperClassName = '',
  resetDateRange,
  label,
}) => {
  const periodInputRef = useRef(null);

  useEffect(() => {
    let calendar;

    if (periodInputRef.current) {
      const parsedPeriod = [
        period.startDate.format(DATE_FORMAT),
        period.endDate.format(DATE_FORMAT),
      ];

      calendar = flatpickr(periodInputRef.current, {
        mode: 'range',
        maxDate: new Date(),
        defaultDate: parsedPeriod,
        inline: true,
        onChange: ([periodFrom, periodTo]) => {
          if (resetDateRange) {
            resetDateRange();
          }

          if (periodFrom && periodTo) {
            return setPeriod({
              startDate: moment(periodFrom),
              endDate: moment(periodTo).endOf('day'),
            });
          }

          return period;
        },
      });
    }

    return () => {
      calendar?.destroy();
    };
  }, [period.startDate, period.endDate, periodInputRef]);

  return (
    <div className={`d-flex flex-column ${wrapperClassName}`}>
      {label && (
        <label htmlFor="datepicker-period-field" className="mb-0">
          {label}
        </label>
      )}
      <div className="align-self-center">
        <input id="datepicker-period-field" ref={periodInputRef} hidden />
      </div>
    </div>
  );
};

RangeDatePicker.propTypes = {
  period: periodType.isRequired,
  setPeriod: func.isRequired,
  wrapperClassName: string,
  resetDateRange: func,
  label: string,
};
