import React, { useMemo } from 'react';
import { oneOf, func, number, shape, string } from 'prop-types';

import { entitiesListType } from '../../../../API/hooks/useFetchEntities/types';
import { TableControlledPagination } from '../../../../atoms';
import { IdCell } from './IdCell/IdCell';
import { EditEntityCell } from './EditEntityCell/EditEntityCell';
import { ShowEntityCell } from './ShowEntityCell/ShowEntityCell';
import { InternalIdCell } from './InternalIdCell/InternalIdCell';
import { ArchiveOrUnarchiveEntityCell } from './ArchiveOrUnarchiveEntityCell';
import { IncursionsCell } from './IncursionsCell';
import { MachineKindCell } from './MachineKindCell/MachineKindCell';
import { LastActivityFormattedCell } from './LastActivityFormattedCell/LastActivityFormattedCell';
import { AcknowledgeScoreCell } from './AcknowledgeScoreCell';
import { sortingParamsSchema } from '../../types';
import { ENTITIES_SIZE_PER_PAGE } from '../../consts';

export const TableSection = ({
  source,
  entities,
  revalidateEntities,
  paginationParams,
  setPaginationParams,
  totalPages,
  totalCount,
  sortingParams,
  handleTableHeaderClick,
  tableHeaderClassName,
}) => {
  const tableData = useMemo(() => entities, [entities]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        Cell: IdCell,
      },
      {
        Header: source === 'machines' ? '' : 'Internal ID',
        accessor: 'label',
        Cell: InternalIdCell,
      },
      {
        Header: 'Total Incursions',
        accessor: 'totalIncursionsCount',
        Cell: IncursionsCell,
      },
      {
        Header: 'Acknowledged Incursions',
        accessor: 'acknowledgedIncursionsCount',
        Cell: IncursionsCell,
      },
      {
        Header: 'Unacknowledged Incursions',
        accessor: 'unacknowledgedIncursionsCount',
        Cell: IncursionsCell,
      },
      {
        Header: source === 'machines' ? 'Last Active' : 'Last Tag Test',
        accessor: 'lastActivityFormatted',
        Cell: LastActivityFormattedCell,
      },
      { Header: '', accessor: 'showPath', Cell: ShowEntityCell },
      { Header: '', accessor: 'editPath', Cell: EditEntityCell },
      {
        Header: '',
        id: 'archive',
        accessor: (row) => row.archivePath,
        Cell: ArchiveOrUnarchiveEntityCell,
      },
    ],
    [],
  );

  if (source === 'machines') {
    columns.splice(0, 1, {
      Header: '',
      accessor: 'id',
      Cell: MachineKindCell,
    });
    columns.splice(3, 2, {
      Header: 'Acknowledge Score\u00A0(%)',
      accessor: 'acknowledgementScore',
      Cell: AcknowledgeScoreCell,
    });
  }

  return (
    <TableControlledPagination
      columns={columns}
      data={tableData}
      initialPaginationState={paginationParams}
      setPaginationParams={setPaginationParams}
      pageCount={totalPages}
      totalCount={totalCount}
      pageSizeOptions={ENTITIES_SIZE_PER_PAGE}
      sortingParams={sortingParams}
      handleTableHeaderClick={handleTableHeaderClick}
      headerClassName={tableHeaderClassName}
      customCellProps={{ revalidateEntities }}
      isFirstColumnDark={source === 'machines'}
    />
  );
};

TableSection.propTypes = {
  source: oneOf(['machines', 'people']),
  entities: entitiesListType,
  paginationParams: shape({
    currentPage: number,
    perPage: number,
  }),
  setPaginationParams: func,
  totalPages: number,
  totalCount: number,
  sortingParams: sortingParamsSchema,
  handleTableHeaderClick: func,
  tableHeaderClassName: string,
  revalidateEntities: func,
};
