import React from 'react';
import { number, oneOfType, string } from 'prop-types';

import styles from './LinkCell.module.scss';

export const LinkCell = ({ value }) => {
  return (
    <a href={`/tags/${value}`} className="py-1 pr-2">
      <i className={`fa-regular fa-arrow-up-right-from-square ${styles.icon}`} />
    </a>
  );
};

LinkCell.propTypes = {
  value: oneOfType([string, number]),
};
