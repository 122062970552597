import { Controller } from 'stimulus';
import { Html5QrcodeScanner } from 'html5-qrcode';

export default class extends Controller {
  static targets = ['source'];

  scan() {
    this.sourceTarget.value = '';

    const html5QrcodeScanner = new Html5QrcodeScanner(
      'reader',
      { fps: 10, qrbox: 250 },
      /* verbose= */ false,
    );

    html5QrcodeScanner.render((decodedText) => this.onScanSuccess(decodedText, html5QrcodeScanner));
  }

  onScanSuccess(decodedText, html5QrcodeScanner) {
    this.sourceTarget.value = decodedText;

    html5QrcodeScanner.clear();
  }
}
