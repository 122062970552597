import React from 'react';

import { Skeleton } from 'atoms/Skeleton/Skeleton';

import styles from './MachineTableSkeleton.module.scss';

export const MachineTableSkeleton = () => (
  <div className={`card ${styles.wrapper}`}>
    <div className={styles.header}>
      <Skeleton className={styles.skeleton} />
    </div>
    <div className={styles.content}>
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
      <Skeleton className={styles.contentSkeleton} />
    </div>
    <div className={styles.footer}>
      <Skeleton className={styles.skeleton} />
    </div>
  </div>
);
