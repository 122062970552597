import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import { SCSInsightsTable } from './SCSInsightsTable/SCSInsightsTable';

export const SCSInsights = ({ dateFormat, baseStationId }) => {
  return <SCSInsightsTable dateFormat={dateFormat} baseStationId={baseStationId} />;
};

SCSInsights.propTypes = {
  dateFormat: string,
  baseStationId: oneOfType([string, number]),
};
