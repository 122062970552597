import React from 'react';
import PropTypes from 'prop-types';

export const InfoCardBox = ({ children, boxClassName = '' }) => (
  <div className={`card ${boxClassName}`}>{children}</div>
);

InfoCardBox.propTypes = {
  children: PropTypes.node.isRequired,
  boxClassName: PropTypes.string,
};
