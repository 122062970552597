import { Controller } from 'stimulus';
import consumer from '../channels/consumer';
export default class extends Controller {
  static targets = ['formWrapper', 'form', 'spinner'];

  get time() {
    return this.data.get('time');
  }

  connect() {
    const element = this.formTarget;
    $(element).on('ajax:success', (e, data) => {
      this.showMessage(e, data);

      if (data.status != 'pending') {
        if (this.hasWrapperTarget)
          this.formWrapperTarget.style.display = 'block';
        if (this.hasWrapperTarget) this.spinnerTarget.style.display = 'none';
        this.formTarget.innerHTML = data.html;
      }
    });
  }

  showMessage(e, data) {
    if (data.status == 'pending') {
      this.formWrapperTarget.style.display = 'none';
      this.spinnerTarget.style.display = 'block';
      this.subscribe(data);
    } else if (data.status == 'ok') {
      e.target.style.display = 'none';
      e.target.parentNode.innerHTML =
        '<p class="panel__info panel__info--success">' + data.message + '</p>';
      setTimeout(function() {
        window.location.href = data.redirect;
      }, this.time);
    } else {
      if ($('.panel__info').length > 0) {
        $('.panel__info').html(data.message);
      } else {
        $(e.target)
          .parent()
          .prepend('<p class="panel__info">' + data.message + '</p>');
      }
    }
  }

  subscribe = data => {
    consumer.subscriptions.create(
      { channel: 'MagicLinkChannel', user: data['user'] },
      {
        received(data) {
          // Called when there's incoming data on the websocket for this channel
          // Stimulus targets do not work here
          const form = $('#admin-login-form');
          form.find('input[name="user[token]"]').val(data.token);
          form.trigger('submit');
        },
      },
    );
  };
}
