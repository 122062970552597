import { useState } from 'react';

export const useProjectsAndSitesChart = () => {
  const [filter, setFilter] = useState('projects');

  const getChartData = (currentItems, unacknowledgedPercentagePerItem) => {
    const slicedCurrentItems = currentItems.slice(0, 5);

    const dataToChart = slicedCurrentItems.map(currentItem => {
      const itemWithUnacknowledgedPercentageValue = unacknowledgedPercentagePerItem.find(
        item => currentItem.id === item.id,
      );
      if (itemWithUnacknowledgedPercentageValue) {
        return {
          ...currentItem,
          value: itemWithUnacknowledgedPercentageValue.value,
        };
      }
      return {
        ...currentItem,
        value: 0,
      };
    });

    const data = dataToChart.map(item => item.value);
    const labels = dataToChart.map(item => item.name);

    return { data, labels };
  };

  return { filter, setFilter, getChartData };
};
