/* eslint-disable react/jsx-key  */
/* eslint-disable react/forbid-prop-types  */

import React, { useEffect, useCallback } from 'react';
import { array, func, number, shape, arrayOf, object, string, bool } from 'prop-types';
import { useTable, usePagination } from 'react-table';

import { Pagination } from '../Pagination';
import { sortingParamsSchema } from '../../../components/Entities/types';
import styles from '../Table.module.scss';

export const TableControlledPagination = ({
  columns,
  data,
  setPaginationParams,
  initialPaginationState,
  pageCount: controlledPageCount,
  totalCount,
  pageSizeOptions,
  sortingParams: { sort, sortBy },
  handleTableHeaderClick,
  headerClassName = '',
  customCellProps = {},
  isFirstColumnDark = false,
  isPagination = true,
  isSorting = true,
  isDarkMode = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    // pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPaginationState.currentPage - 1,
        pageSize: initialPaginationState.perPage,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
  );

  const isData = page.length > 0;
  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  };

  useEffect(() => {
    setPaginationParams({ currentPage: pageIndex + 1, perPage: pageSize });
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (pageIndex + 1 > controlledPageCount && controlledPageCount !== 0) {
      gotoPage(controlledPageCount - 1);
      setPaginationParams((prevProps) => ({
        ...prevProps,
        currentPage: controlledPageCount,
      }));
    }
  }, [pageIndex, controlledPageCount]);

  const renderSortIcon = useCallback(
    (columnHeader) => {
      if (sortBy === columnHeader) {
        if (sort === 'asc') {
          return <i className="far fa-long-arrow-alt-up ml-1" />;
        }
        return <i className="far fa-long-arrow-alt-down ml-1" />;
      }
      return <i className="fas fa-sort ml-1" />;
    },
    [sort, sortBy],
  );

  return (
    <>
      {isData ? (
        <>
          <div className={`${styles.wrapper} pb-1 mb-2`}>
            <table
              {...getTableProps()}
              className={`${styles.table}${isDarkMode ? ` ${styles.dark}` : ''}`}
            >
              <thead className={headerClassName}>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) =>
                      column.Header ? (
                        <th
                          {...column.getHeaderProps()}
                          key={column.id}
                          onClick={
                            isSorting && column.isSorting !== false
                              ? () => handleTableHeaderClick(column.id)
                              : undefined
                          }
                        >
                          <div className={styles.thContent}>
                            {column.Header && <span>{column.render('Header')}</span>}
                            {isSorting && column.isSorting !== false && renderSortIcon(column.id)}
                          </div>
                        </th>
                      ) : (
                        <th {...column.getHeaderProps()} key={column.id} />
                      ),
                    )}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={isFirstColumnDark ? styles.dark : ''}
                          >
                            {cell.render('Cell', customCellProps)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isPagination && (
            <Pagination
              pageSizeOptions={pageSizeOptions}
              paginationProps={paginationProps}
              totalCount={totalCount}
              isDarkMode={isDarkMode}
            />
          )}
        </>
      ) : (
        <div className="text-center my-4">No data to display!</div>
      )}
    </>
  );
};

TableControlledPagination.propTypes = {
  columns: array,
  data: array,
  setPaginationParams: func,
  initialPaginationState: shape({
    currentPage: number,
    perPage: number,
  }),
  pageCount: number,
  totalCount: number,
  pageSizeOptions: arrayOf(number),
  sortingParams: sortingParamsSchema,
  handleTableHeaderClick: func,
  headerClassName: string,
  customCellProps: object,
  isFirstColumnDark: bool,
  isPagination: bool,
  isSorting: bool,
  isDarkMode: bool,
};
