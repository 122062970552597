import React from 'react';
import { string, func } from 'prop-types';
import styles from './Input.module.scss';

export const Input = ({ value, onChange, placeholder }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={e => onChange(e.target.value)}
      className={`form-control ${styles.input}`}
      placeholder={placeholder}
    />
  );
};

Input.propTypes = {
  value: string,
  onChange: func,
  placeholder: string,
};
