import { Controller } from 'stimulus';
import Cookie from 'js-cookie';

export default class extends Controller {
  static targets = [
    'accordionLeft',
    'accordionRight',
    'sidebarLeft',
    'sidebarRight',
    'currentUserPanel',
    'overlay',
    'buttonCloseLeft',
    'buttonCloseRight',
    'item',
  ];

  connect() {
    SmartListing.config.merge();
    this.active();
  }

  toggleNavbar() {
    $('body').toggleClass('sidenav-toggled');
    $('.navbar-sidenav .nav-link-collapse').addClass('collapsed');
    $('.navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level').removeClass(
      'show',
    );
    if ($('body').hasClass('sidenav-toggled')) {
      Cookie.set('sidenav_toggled', false, { expires: 365 });
    } else {
      Cookie.set('sidenav_toggled', true, { expires: 365 });
    }
  }

  toggleOverlay() {
    $(this.overlayTarget).stop().fadeToggle(250);
  }

  toggleSidebarLeft() {
    $(this.sidebarLeftTarget).stop().toggle(0);
    $(this.accordionLeftTarget).toggleClass('toggle-left');
    $(this.buttonCloseLeftTarget).toggleClass('toggle-left');
    if ($(this.sidebarRightTarget).is(':visible')) {
      $(this.sidebarRightTarget).stop().toggle();
      $(this.accordionRightTarget).toggleClass('toggle-right');
      $(this.buttonCloseRightTarget).toggleClass('toggle-right');
    } else {
      this.toggleOverlay();
    }
  }

  toggleSidebarRight() {
    $(this.sidebarRightTarget).stop().toggle(0);
    $(this.accordionRightTarget).toggleClass('toggle-right');
    $(this.buttonCloseRightTarget).toggleClass('toggle-right');
    if ($(this.sidebarLeftTarget).is(':visible')) {
      $(this.sidebarLeftTarget).stop().toggle();
      $(this.accordionLeftTarget).toggleClass('toggle-left');
      $(this.buttonCloseLeftTarget).toggleClass('toggle-left');
    } else {
      this.toggleOverlay();
    }
  }

  closeSidebar() {
    if ($(this.sidebarLeftTarget).is(':visible')) {
      $(this.sidebarLeftTarget).stop().toggle(0);
      $(this.accordionLeftTarget).toggleClass('toggle-left');
      $(this.buttonCloseLeftTarget).toggleClass('toggle-left');
      this.toggleOverlay();
    } else {
      $(this.sidebarRightTarget).stop().toggle(0);
      $(this.accordionRightTarget).toggleClass('toggle-right');
      $(this.buttonCloseRightTarget).toggleClass('toggle-right');
      this.toggleOverlay();
    }
  }

  active() {
    const homePageElement = this.itemTargets.find((item) => item.dataset.path === '/insights');

    if (homePageElement && window.location.pathname === '/') {
      homePageElement.classList.add('currentActive');
    }

    this.itemTargets.forEach((item) => {
      if (window.location.pathname.includes(item.dataset.path)) {
        item.classList.add('currentActive');
      }
    });
  }
}
