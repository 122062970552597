import ReactOnRails from 'react-on-rails';

import Uploader from 'atoms/Uploader/Uploader';
import InputLinkedMultiSelect from '../components/MultiSelect/InputLinkedMultiSelect';
import UploaderFormWrapper from '../components/UploaderFormWrapper';
// import ColorPickerFormWrapper from '../components/ColorPickerFormWrapper';
import { basic as FakeWorksites } from '../components/Worksites/Worksites.stories';
// safety zone
import Insights from '../components/Insights/APILinkedInsights';
import Worksites from '../components/Worksites/APILinkedWorksites';
import Heatmap from '../components/Heatmap/APILinkedHeatmap';
import BaseStation from '../components/BaseStation/APILinkedBaseStation';
import Tag from '../components/Tag/APILinkedTag';
import People from '../components/Entities/People/APILinkedPeople';
import Machines from '../components/Entities/Machines/APILinkedMachines';
import Reports from '../components/Reports/APILinkedReports';
import Analytics from '../components/Analytics/APILinkedAnalytics';

export const registerGlobalReactComponents = () => {
  ReactOnRails.register({
    Uploader,
    InputLinkedMultiSelect,
    UploaderFormWrapper,
    // ColorPickerFormWrapper,
    FakeWorksites,
    // safety zone
    Insights,
    Worksites,
    Heatmap,
    BaseStation,
    Tag,
    People,
    Machines,
    Reports,
    Analytics,
  });
};

export default registerGlobalReactComponents;
