import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal'];

  connect() {
    $(this.modalTarget).on('show.bs.modal', function(event) {
      $(this)
        .find('.modal-content')
        .load(event.relatedTarget.href, function() {
          $(SmartListing.config.class_name('main')).smart_listing();
          $(
            SmartListing.config.class_name('controls'),
          ).smart_listing_controls();
        });
    });
  }

  changeInterval(e) {
    var form = e.target.parentNode.parentNode;
    $(form).submit();
  }
}
