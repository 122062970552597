import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Html5QrcodeScanner } from 'html5-qrcode';
import styles from './Scanner.module.scss';

const qrcodeRegionId = 'html5qr-code-full-region';

const createConfig = ({ fps, qrbox, aspectRatio, disableFlip }) => {
  const config = {};

  if (fps) {
    config.fps = fps;
  }
  if (qrbox) {
    config.qrbox = qrbox;
  }
  if (aspectRatio) {
    config.aspectRatio = aspectRatio;
  }
  if (disableFlip !== undefined) {
    config.disableFlip = disableFlip;
  }

  return config;
};

export const Scanner = ({
  verbose,
  qrCodeSuccessCallback,
  qrCodeErrorCallback,
  fps,
  qrbox,
  aspectRatio,
  disableFlip,
}) => {
  useEffect(() => {
    const config = createConfig({ fps, qrbox, aspectRatio, disableFlip });
    const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);

    return () => {
      html5QrcodeScanner.clear();
    };
  }, []);

  return <div className={styles.reader} id={qrcodeRegionId} />;
};

Scanner.propTypes = {
  verbose: PropTypes.bool,
  qrCodeSuccessCallback: PropTypes.func.isRequired,
  qrCodeErrorCallback: PropTypes.func,
  fps: PropTypes.number,
  qrbox: PropTypes.number,
  aspectRatio: PropTypes.number,
  disableFlip: PropTypes.bool,
};
