import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['imeiInput', 'imeiInputsWrapper'];

  connect() {
    this.children = this.imeiInputsWrapperTarget.querySelectorAll('input');

    this.forceMaxLength();

    this.imeiInputsWrapperTarget.onkeyup = e => {
      var target = e.srcElement;
      var maxLength = parseInt(target.attributes['maxlength'].value, 10);
      var myLength = target.value.length;
      if (myLength >= maxLength) {
        var nextIndex = parseInt(target.dataset['part']);
        var next = this.children[nextIndex + 1];

        if (next) {
          next.focus();
        }
      }
    };

    this.imeiInputsWrapperTarget.onpaste = e => {
      this.handlePaste(e);
    };
  }

  forceMaxLength() {
    this.children.forEach(element => {
      element.addEventListener('keyup', function(event) {
        event.target.value = event.target.value.substring(
          0,
          parseInt(element.dataset['maxlength']),
        );
      });
    });
  }

  handlePaste = e => {
    var pastedData = e.clipboardData.getData('Text');
    var dataParts = pastedData.match(/.{1,4}/g);

    this.children.forEach(element => {
      element.value = dataParts[parseInt(element.dataset['part'])];
    });
  };
}
