import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import { useFetchBaseStationParameter } from 'API/hooks/useFetchBaseStationParameter/useFetchBaseStationParameter';

import styles from './MachineInsightsData.module.scss';
import { MachineInsightsParam } from './MachineInsightsParam/MachineInsightsParam';
import { MachineInsightsDataSkeleton } from './MachineInsightsDataSkeleton/MachineInsightsDataSkeleton';

export const MachineInsightsData = ({ dateFormat, baseStationId }) => {
  const { data, isLoading, error, msgToDisplay } = useFetchBaseStationParameter({
    shouldFetch: true,
    baseStationId,
  });

  if (isLoading) {
    return <MachineInsightsDataSkeleton />;
  }

  if (error) {
    return (
      <div className={styles.wrapper}>
        <div className="text-center">{msgToDisplay}</div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {data && data.length > 0 ? (
        data.map((param) => (
          <MachineInsightsParam key={param.id} param={param} dateFormat={dateFormat} />
        ))
      ) : (
        <div className="text-center">No data to display!</div>
      )}
    </div>
  );
};

MachineInsightsData.propTypes = {
  dateFormat: string,
  baseStationId: oneOfType([string, number]),
};
