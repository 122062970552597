import React from 'react';
import { func, bool, string } from 'prop-types';

import styles from './Switcher.module.scss';

export const SwitcherInput = ({ onChange, checked, labelText }) => {
  const handleChange = e => onChange(e.target.checked);

  return (
    <>
      <input
        type="checkbox"
        id={`switcher-${labelText}`}
        onChange={handleChange}
        checked={checked}
        className={styles.switcherLogic}
      />
      <label
        htmlFor={`switcher-${labelText}`}
        className={styles.switcher}
        tabIndex={0}
      />
    </>
  );
};

SwitcherInput.propTypes = {
  onChange: func.isRequired,
  checked: bool.isRequired,
  labelText: string.isRequired,
};
