import React from 'react';
import { string } from 'prop-types';

import styles from './DateCell.module.scss';

export const DateCell = ({ value }) => <div className={styles.cell}>{value}</div>;

DateCell.propTypes = {
  value: string,
};
