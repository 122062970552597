import React from 'react';
import { string } from 'prop-types';

import { entitiesListType } from 'API/hooks/useFetchEntities/types';
import styles from '../TableSection.module.scss';

export const ShowEntityCell = ({ value, data }) => {
  const currentRow = data.find((row) => row.showPath === value);

  if (!currentRow) return;

  return (
    <>
      {value && (
        <div className={styles.iconCell}>
          <a
            className={`d-flex justify-content-center align-items-center btn ${styles.link} ${styles.linkSmall}`}
            href={value}
          >
            info
          </a>
        </div>
      )}
    </>
  );
};

ShowEntityCell.propTypes = {
  value: string,
  data: entitiesListType,
};
