import React from 'react';
import { string, node } from 'prop-types';
import styles from './ChartWrapper.module.scss';

export const ChartWrapper = ({ children, wrapperClassName = '' }) => {
  return <div className={`card h-100 ${styles.chart} ${wrapperClassName}`}>{children}</div>;
};

ChartWrapper.propTypes = {
  children: node.isRequired,
  wrapperClassName: string,
};
