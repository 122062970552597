import React from 'react';
import { bool, func, node } from 'prop-types';

import styles from './Pagination.module.scss';

export const PaginationButton = ({ onClick, disabled, children }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn btn-outline-secondary mr-1 ${styles.button}`}
    >
      {children}
    </button>
  );
};

PaginationButton.propTypes = {
  onClick: func,
  disabled: bool,
  children: node,
};
