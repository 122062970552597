import React from 'react';
import { func } from 'prop-types';
import moment from 'moment';

import { singlePeriodType } from '../../types';
import { dateFormatType } from '../../../../API/types';
import { SingleTimePicker } from '../../TimePicker/SingleTimePicker/SingleTimePicker';
import { SingleDatePicker } from '../../DatePicker/SingleDatePicker/SingleDatePicker';
import styles from '../DateAndTimePicker.module.scss';

export const SingleDateAndTimePicker = ({ period, setPeriod, dateFormat }) => {
  return (
    <div className={styles.wrapper}>
      <SingleDatePicker period={period} setPeriod={setPeriod} />
      <div className={styles.timePickerWrapper}>
        <SingleTimePicker period={period} setPeriod={setPeriod} label="Time" />
      </div>
      <div>
        <h2 className={styles.title}>Selected date</h2>
        <div className={styles.selectedPeriodWrapper}>
          <strong>Date</strong>
          <p className="mb-0">{moment(period.date).format(`${dateFormat} hh:mmA`)}</p>
        </div>
      </div>
    </div>
  );
};

SingleDateAndTimePicker.propTypes = {
  period: singlePeriodType.isRequired,
  setPeriod: func.isRequired,
  dateFormat: dateFormatType.isRequired,
};
