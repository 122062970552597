import useSWR from 'swr';

import { errorMessage } from '../../utils';
import { useShowMessage } from '../../../hooks';

export const useFetchReports = ({ initialData, shouldFetch }) => {
  const { msgToDisplay, setMsgToDisplay } = useShowMessage();

  const { data, isLoading } = useSWR(shouldFetch ? `${process.env.API_URL}/reports` : null, {
    fallbackData: initialData,
    errorRetryCount: 3,
    onError: (err) => {
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      });
    },
    onErrorRetry: (err) =>
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      }),
    onSuccess: () => {
      setMsgToDisplay({ msg: '', className: '' });
    },
    revalidateOnMount: true,
    keepPreviousData: true,
  });

  return { data, msgToDisplay, isLoading };
};
