export generateFakerArray from './generateFakerArray/generateFakerArray';
export timeoutedPromise from './timeoutedPromise/timeoutedPromise';
export { capitalize } from './capitalize/capitalize';
export fetchApi from './fetchApi/fetchApi';
export getApiDateString from './getApiDateString/getApiDateString';
export getGeoJsonFromShape from './getGeoJsonFromShape/getGeoJsonFromShape';
export isGeoJsonCircle from './isGeoJsonCircle/isGeoJsonCircle';
export addInfoWindow from '../atoms/Map/MapGeometry/addInfoWindow';
export { simpleMovingAverage } from './simpleMovingAverage/simpleMovingAverage';
export { capitalizeAll } from './capitalizeEveryWord/capitalizeEveryWord';
