import React from 'react';
import { func, string } from 'prop-types';
import moment from 'moment';

import { dateFormatType } from '../../../../API/types';
import { periodType } from '../../../DatePicker/types';
import { DrawerTrigger } from '../../DrawerTrigger/DrawerTrigger';
import { Drawer } from '../../Drawer';
import { SingleDateAndTimePicker } from '../../../DatePicker/components/SingleDateAndTimePicker/SingleDateAndTimePicker';

export const DrawerWithSingleDateAndTimePicker = ({
  dateFormat,
  period,
  setPeriod,
  wrapperClassName,
}) => {
  return (
    <div className={`d-flex align-items-center ${wrapperClassName || ''}`}>
      <p className="mb-0 mr-2">
        <strong>{moment(period.date).format(`${dateFormat} hh:mmA`)}</strong>
      </p>
      <DrawerTrigger
        label={
          <div>
            <i className="fa-solid fa-calendar-days" />
          </div>
        }
        styleOption="icon"
      >
        {(isVisible, onClose) => (
          <Drawer
            isVisible={isVisible}
            onClose={onClose}
            portalDrawerId="drawer-with-single-date-and-time-picker"
          >
            <SingleDateAndTimePicker
              period={period}
              setPeriod={setPeriod}
              dateFormat={dateFormat}
            />
          </Drawer>
        )}
      </DrawerTrigger>
    </div>
  );
};

DrawerWithSingleDateAndTimePicker.propTypes = {
  dateFormat: dateFormatType.isRequired,
  period: periodType.isRequired,
  setPeriod: func.isRequired,
  wrapperClassName: string,
};
