import React, { useState } from 'react';
import { number, oneOfType, string, bool } from 'prop-types';

import { singleChartDataType } from 'API/hooks/useFetchAnalytics/types';
import { periodType } from 'atoms/DatePicker/types';
import { dateFormatType, notesType } from 'API/types';
import { incursionsDataType } from 'API/hooks/useFetchBaseStation/types';

import { Tabs } from './Tabs/Tabs';
import { Videos } from './Videos/Videos';
import { ProximityWarnings } from './ProximityWarnings/ProximityWarnings';
import { SCSInsights } from './SCSInsights/SCSInsights';
import { MachineInsights } from './MachineInsights/MachineInsights';

export const MachineDetails = ({
  incursionsData,
  acknowledgedIncursions,
  unacknowledgedIncursions,
  period,
  dateFormat,
  baseStationId,
  dvrId,
  online,
  initialNotes,
  userName,
}) => {
  const [activeTab, setActiveTab] = useState('Proximity Warning');

  return (
    <>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} dvrId={dvrId} />
      {activeTab === 'Proximity Warning' && (
        <ProximityWarnings
          incursionsData={incursionsData}
          acknowledgedIncursions={acknowledgedIncursions}
          unacknowledgedIncursions={unacknowledgedIncursions}
          period={period}
          dateFormat={dateFormat}
          baseStationId={baseStationId}
          initialNotes={initialNotes}
          userName={userName}
        />
      )}
      {activeTab === 'Vision 360' && (
        <Videos dateFormat={dateFormat} baseStationId={baseStationId} online={online} />
      )}
      {activeTab === 'SCS Insights' && (
        <SCSInsights dateFormat={dateFormat} baseStationId={baseStationId} />
      )}
      {activeTab === 'Machine Insights' && (
        <MachineInsights dateFormat={dateFormat} baseStationId={baseStationId} />
      )}
    </>
  );
};

MachineDetails.propTypes = {
  incursionsData: incursionsDataType,
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
  period: periodType,
  dateFormat: dateFormatType,
  baseStationId: oneOfType([string, number]),
  dvrId: string,
  online: bool,
  initialNotes: notesType,
  userName: string,
};
