import React from 'react';
import { SWRConfig } from 'swr';
import { fetchApi } from '../../../utils';
import Machines from './Machines';
import { ActiveMachineContextProvider } from '../../../contexts/ActiveMachineContext';
import { machinesFetchedDataType } from '../../../API/hooks/useFetchEntities/types';
import { StorageFiltersProvider } from '../../../contexts/StorageFiltersContext/StorageFiltersProvider/StorageFiltersProvider';

export const APILinkedMachines = (props) => {
  return (
    <SWRConfig
      value={{
        fetcher: fetchApi,
      }}
    >
      <StorageFiltersProvider>
        <ActiveMachineContextProvider>
          <Machines {...props} />
        </ActiveMachineContextProvider>
      </StorageFiltersProvider>
    </SWRConfig>
  );
};

export default (props) => <APILinkedMachines {...props} />;

APILinkedMachines.propTypes = {
  initialData: machinesFetchedDataType,
};
