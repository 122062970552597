import { bool, func, node, string } from 'prop-types';
import React, { useEffect } from 'react';

import { Portal } from '../Portal/Portal';
import { useClickOutsideTimePickerDrawer } from '../../hooks/useClickOutsideTimePickerDrawer';
import { Overlay } from '../Overlay/Overlay';

import styles from './Drawer.module.scss';

export const Drawer = ({ isVisible, onClose, children, portalDrawerId, className }) => {
  useClickOutsideTimePickerDrawer(onClose, isVisible);

  useEffect(() => {
    const reactWrapper = document.querySelector('.react-container');
    if (isVisible && reactWrapper) {
      reactWrapper.classList.add(styles.blur);
    }

    return () => {
      if (reactWrapper) {
        reactWrapper.classList.remove(styles.blur);
      }
    };
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <Portal idName="portal-drawer-overlay">
          <Overlay />
        </Portal>
      )}
      <Portal idName={`portal-${portalDrawerId}`}>
        <div className={`${styles.drawer} ${isVisible ? styles.visible : ''} ${className || ''}`}>
          {isVisible && (
            <>
              <button type="button" onClick={onClose} className={styles.close}>
                <div className={styles.iconWrapper}>
                  <i className="fa-regular fa-xmark" />
                </div>
                Close
              </button>
              {children}
            </>
          )}
        </div>
      </Portal>
    </>
  );
};

Drawer.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
  portalDrawerId: string.isRequired,
  className: string,
};
