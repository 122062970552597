import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import NoteButton from './NoteButton';
import moment from 'moment';
import styles from './Note.module.scss';

const Note = ({
  date,
  author,
  text,
  isEditing,
  onOpen,
  handleSave,
  onClose,
  onDelete,
}) => {
  const noteInputRef = useRef(null);

  const onSave = () => handleSave(noteInputRef.current.value);

  return (
    <div className="d-flex justify-content-between pb-1">
      <div className={styles.noteRow}>
        <div className={styles.noteDate}>
          {moment(date).format('MMMM Do YYYY, h:mm:ss A')}
        </div>
        <div>
          <span className={`${styles.noteAuthor} pr-2`}>{author}:</span>
          {isEditing ? (
            <>
              <textarea
                ref={noteInputRef}
                className="form-control"
                maxLength={100}
                defaultValue={text}
                autoFocus
              />
              <NoteButton
                onClick={onSave}
                icon="far fa-check"
                color="success"
                aria-label="save"
                title="save"
              />
              <NoteButton
                onClick={onClose}
                icon="far fa-times"
                color="secondary"
                aria-label="close"
                title="close"
              />
              <NoteButton
                onClick={onDelete}
                icon="far fa-trash-alt"
                color="danger"
                aria-label="delete"
                title="delete"
              />
            </>
          ) : (
            <span>{text}</span>
          )}
        </div>
      </div>
      {!isEditing && (
        <div className={styles.editBtn}>
          <NoteButton
            onClick={onOpen}
            icon="fas fa-pencil-alt"
            aria-label="edit"
            title="edit"
          />
        </div>
      )}
    </div>
  );
};

Note.propTypes = {
  id: PropTypes.number,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  author: PropTypes.string,
  text: PropTypes.string,
  isEditing: PropTypes.bool,
  onOpen: PropTypes.func,
  handleSave: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Note;
