import React from 'react';
import PropTypes from 'prop-types';

const PaginationButton = ({ children, isActive, ...rest }) => (
  <button
    className={`btn btn-outline-secondary ${isActive ? 'active' : ''}`}
    {...rest}
  >
    {children}
  </button>
);

PaginationButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isActive: PropTypes.bool,
};

export default PaginationButton;
