import React, { useMemo, useState } from 'react';
import { number, oneOfType, string } from 'prop-types';

import { TableControlledPagination } from 'atoms';
import { periodType } from '../../../../../../atoms/DatePicker/types';
import { useFetchOperators } from '../../../../../../API/hooks/useFetchOperators/useFetchOperators';
import { MachineTableSkeleton } from '../MachineTableSkeleton/MachineTableSkeleton';

import styles from './MachineOperatorsTable.module.scss';
import { Cell } from './Cell/Cell';
import { IdCell } from './IdCell/IdCell';
import { LinkCell } from './LinkCell/LinkCell';

const PER_PAGE = [10, 20];

export const MachineOperatorsTable = ({ period, baseStationId }) => {
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    perPage: PER_PAGE[0],
  });

  const { data, isLoading, error } = useFetchOperators({
    shouldFetch: true,
    period,
    baseStationId,
    page: paginationParams.currentPage,
    per: paginationParams.perPage,
  });

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        Cell: IdCell,
      },
      {
        Header: 'Internal ID',
        accessor: 'label',
        Cell,
      },
      {
        Header: 'Total Operator Incursions',
        accessor: 'totalIncursionsCount',
        Cell,
      },
      {
        Header: 'Acknowledged Operator Incursions',
        accessor: 'acknowledgedIncursionsCount',
        Cell,
      },
      {
        Header: 'Unacknowledged Operator Incursions',
        accessor: 'unacknowledgedIncursionsCount',
        Cell,
      },
      {
        Header: '',
        accessor: 'slug',
        Cell: LinkCell,
      },
    ],
    [],
  );

  if (isLoading) {
    return <MachineTableSkeleton />;
  }

  if (error) {
    return (
      <div className={`card p-4 ${styles.wrapper}`}>
        <div className="d-flex justify-content-center align-items-center">
          <p className="text-danger m-0">Error! Something went wrong. Try again later</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`card ${styles.wrapper}`}>
      {!!data && (
        <TableControlledPagination
          columns={columns}
          data={data.operators}
          initialPaginationState={{
            currentPage: paginationParams.currentPage,
            perPage: paginationParams.perPage,
          }}
          setPaginationParams={setPaginationParams}
          pageCount={data.pagination.totalPages}
          totalCount={data.pagination.totalCount}
          pageSizeOptions={PER_PAGE}
          sortingParams={{
            sort: null,
            sortBy: null,
          }}
          handleTableHeaderClick={() => {}}
          isSorting={false}
        />
      )}
    </div>
  );
};

MachineOperatorsTable.propTypes = {
  period: periodType,
  baseStationId: oneOfType([string, number]),
};
