import React from 'react';
import { string, node } from 'prop-types';

import { InfoCardBox } from '../InfoCardBox';
import styles from './ListInfoCardWrapper.module.scss';

export const ListInfoCardWrapper = ({
  title,
  subtitle = '',
  boxClassName = '',
  children,
}) => {
  return (
    <InfoCardBox boxClassName={`text-center ${styles.box} ${boxClassName}`}>
      <h3 className={styles.title}>{title}</h3>
      {subtitle && <h3 className={styles.title}>{subtitle}</h3>}
      {children}
    </InfoCardBox>
  );
};

ListInfoCardWrapper.propTypes = {
  title: string.isRequired,
  subtitle: string,
  boxClassName: string,
  children: node,
};
