import React from 'react';
import { number, shape, oneOfType } from 'prop-types';

import { tagSchema, machineSchema } from '../../../../../API/hooks/useFetchEntities/types';
import cellStyles from '../TableSection.module.scss';

const formatScore = (score) => {
  if (score === 0) return '0 %';
  return `${score.toFixed(1)} %`;
};

export const AcknowledgeScoreCell = ({ value, row: { original } }) => {
  return (
    <div className={cellStyles.cell}>{original.type === 'Tester' ? '-' : formatScore(value)}</div>
  );
};

AcknowledgeScoreCell.propTypes = {
  value: number,
  row: shape({
    original: oneOfType([tagSchema, machineSchema]),
  }),
};
