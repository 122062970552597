import React from 'react';

import { Skeleton } from 'atoms/Skeleton/Skeleton';

import styles from './MachineInsightsDataSkeleton.module.scss';

export const MachineInsightsDataSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.dataWrapper}>
        <Skeleton className={styles.dataSkeleton} />
        <Skeleton className={styles.timestampSkeleton} />
      </div>
      <div className={styles.dataWrapper}>
        <Skeleton className={styles.dataSkeleton} />
        <Skeleton className={styles.timestampSkeleton} />
      </div>
      <div className={styles.dataWrapper}>
        <Skeleton className={styles.dataSkeleton} />
        <Skeleton className={styles.timestampSkeleton} />
      </div>
      <div className={styles.dataWrapper}>
        <Skeleton className={styles.dataSkeleton} />
        <Skeleton className={styles.timestampSkeleton} />
      </div>
      <div className={styles.dataWrapper}>
        <Skeleton className={styles.dataSkeleton} />
        <Skeleton className={styles.timestampSkeleton} />
      </div>
    </div>
  );
};
