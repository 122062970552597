import { useMemo } from 'react';
import useSWR from 'swr';

import { errorMessage } from '../../utils';
import { useShowMessage } from '../../../hooks';

import { getPath } from './getPath';

export const useFetchVideos = ({ shouldFetch, baseStationId }) => {
  const { msgToDisplay, setMsgToDisplay } = useShowMessage();

  const path = useMemo(
    () =>
      getPath({
        target: 'base_stations',
        baseStationId,
      }),
    [baseStationId],
  );

  const { data, isLoading, error, mutate } = useSWR(
    shouldFetch ? `${process.env.API_URL}${path}` : null,
    {
      errorRetryCount: 3,
      onError: (err) => {
        setMsgToDisplay({
          msg: `${errorMessage}. Error: ${err.message}`,
          className: 'text-danger',
        });
      },
      onErrorRetry: (err) =>
        setMsgToDisplay({
          msg: `${errorMessage}. Error: ${err.message}`,
          className: 'text-danger',
        }),
      onSuccess: () => {
        setMsgToDisplay({ msg: '', className: '' });
      },
      revalidateOnMount: true,
      keepPreviousData: true,
    },
  );

  return { data, msgToDisplay, isLoading, error, mutate };
};
