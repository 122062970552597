import React from 'react';
import PropTypes from 'prop-types';

const BasicRowComponent = ({ row }) => (
  <tr className="basic-row" {...row.getRowProps()}>
    {row.cells.map((cell, i) => {
      return (
        <td key={`row-cell-${i}`} {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      );
    })}
  </tr>
);

BasicRowComponent.propTypes = {
  row: PropTypes.shape({
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        getCellProps: PropTypes.func.isRequired,
        render: PropTypes.func.isRequired,
      }),
    ).isRequired,
    getRowProps: PropTypes.func.isRequired,
  }),
};

export default BasicRowComponent;
