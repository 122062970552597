import React from 'react';
import { node } from 'prop-types';

import styles from './DarkCard.module.scss';

export const DarkCard = ({ children }) => {
  return <div className={`card ${styles['card-dark']}`}>{children}</div>;
};

DarkCard.propTypes = {
  children: node,
};
