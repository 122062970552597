import { useMemo } from 'react';

export const useGetMultiselectOptions = (itemsFromApi) => {
  const options = useMemo(
    () =>
      itemsFromApi.map((item) => ({
        value: item.id || item.key,
        label: item.name,
        ...item,
      })),
    [itemsFromApi],
  );

  const sortedOptions = useMemo(
    () =>
      options.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      }),
    [options],
  );

  return sortedOptions;
};
