import React from 'react';
import { func, string, arrayOf, shape, number, oneOfType, node } from 'prop-types';

import { dateFormatType } from '../../../API/types';
import { periodType } from '../../DatePicker/types';
import { DrawerWithDatePicker } from '../../Drawer/components/DrawerWithDatePicker/DrawerWithDatePicker';

import styles from './Header.module.scss';

export const Header = ({ data, dateFormat, period, setPeriod, children }) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.identitiesWrapper}>
        <div className={styles.identities}>
          {data.map(
            (identity) =>
              identity.value && (
                <div key={identity.key} className={styles.identity}>
                  <h6 className={styles.idTitle}>{identity.title}</h6>
                  <span className={styles.idValue}>{identity.value}</span>
                </div>
              ),
          )}
        </div>
        {/* TODO machine image displayed here */}
        {/* <div>machine image</div> */}
      </div>
      <div className={styles.filters}>
        {children && children}
        <div>
          <DrawerWithDatePicker dateFormat={dateFormat} period={period} setPeriod={setPeriod} />
        </div>
      </div>
    </section>
  );
};

Header.propTypes = {
  data: arrayOf(
    shape({
      key: number,
      title: string,
      value: oneOfType([string, number]),
    }),
  ),
  dateFormat: dateFormatType,
  period: periodType,
  setPeriod: func,
  children: node,
};
