import React, { useMemo } from 'react';

import { reportsType } from '../../../API/hooks/useFetchReports/types';
import { Report } from '../Report';
import styles from './ReportsList.module.scss';
import { dateFormatType } from '../../../API/types';

export const ReportsList = ({ reports, dateFormat }) => {
  const sortedReports = useMemo(
    () =>
      reports.sort((a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);

        if (aDate < bDate) return 1;
        if (aDate > bDate) return -1;
        return 0;
      }),
    [reports],
  );

  return (
    <>
      <h6 className="mt-4 mb-2">Historical Reports</h6>
      <div className={styles.wrapper}>
        <ol className={styles.list}>
          {sortedReports.map((report) => (
            <Report key={report.id} report={report} dateFormat={dateFormat} />
          ))}
        </ol>
      </div>
    </>
  );
};

ReportsList.propTypes = {
  reports: reportsType.isRequired,
  dateFormat: dateFormatType,
};
