import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import { notesType } from '../../API/types';
import { useModal, TextField } from 'atoms';
import Note from './Note/Note';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { useEntityNotes } from './useEntityNotes';
import styles from './EntityNotes.module.scss';

export const EntityNotes = ({ entityId, initialNotes = [], userName = '' }) => {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useModal();

  const {
    handleNoteAdd,
    handleNoteEdit,
    handleNoteDelete,
    onEnterPress,
    onChange,
    handleNoteClose,
    notesData,
    openedNoteId,
    setOpenedNoteId,
    inputText,
  } = useEntityNotes(entityId, initialNotes, userName, closeModal);

  const onDelete = () => {
    openModal();
  };

  return (
    <>
      <h5 className={styles.title}>Notes</h5>
      <div className="card p-3 mb-4">
        <div className={styles.deviceNotes}>
          <div className={styles.inputRow}>
            <TextField
              placeholder="Leave a comment..."
              className="flex-grow-1"
              {...{
                onChange,
                value: inputText,
                maxLength: 100,
                onKeyDown: onEnterPress,
              }}
            />
            <button
              className={`btn btn-success ${styles.saveButton}`}
              onClick={handleNoteAdd}
            >
              Save
            </button>
          </div>
          {notesData.map(note => (
            <Note
              key={note.id}
              onOpen={() => setOpenedNoteId(note.id)}
              handleSave={text => handleNoteEdit(note.id, text)}
              onClose={handleNoteClose}
              onDelete={onDelete}
              isEditing={note.id === openedNoteId}
              {...note}
            />
          ))}
          <ConfirmationModal
            {...{
              isOpen: isModalOpen,
              onClose: closeModal,
              onConfirm: handleNoteDelete,
              onCancel: closeModal,
              text: 'Delete this note?',
            }}
          />
        </div>
      </div>
    </>
  );
};

EntityNotes.propTypes = {
  entityId: oneOfType([string, number]).isRequired,
  initialNotes: notesType.isRequired,
  userName: string.isRequired,
};
