import { useMemo, useState } from 'react';

import { useGetWorksitesData } from '../../hooks';
import { useFetchTag } from '../../API/hooks';
import { useStorageFilters } from '../../contexts/StorageFiltersContext/useStorageFilters';
import { usePeriodComparison } from 'hooks/usePeriodComparison';

export const useTag = (initialDataFromProps) => {
  const [selectedBaseStations, setSelectedBaseStations] = useState(null);

  const selectedBaseStationsIds = useMemo(
    () =>
      selectedBaseStations ? selectedBaseStations.map((baseStation) => baseStation.value) : [],
    [selectedBaseStations],
  );

  const slug = useMemo(() => {
    const { pathname } = document.location;
    const urlSegments = pathname.split('/');
    return urlSegments[urlSegments.length - 1];
  }, []);

  const { period, setPeriod } = useStorageFilters();

  const { arePeriodsTheSame } = usePeriodComparison({
    period,
    initialPropsPeriod: initialDataFromProps.period,
  });

  const {
    data: {
      sites: sitesFromApi,
      heatmapData,
      pedestrianIncursionsData,
      driverIncursionsData,
      tag: tagData,
      chartData,
      notes,
      dateFormat,
      baseStationFilterData,
    },
    isLoading,
  } = useFetchTag({
    initialData: initialDataFromProps,
    shouldFetch: !arePeriodsTheSame,
    period,
    tagId: slug,
    baseStationsIds: selectedBaseStationsIds,
  });

  const worksitesData = useGetWorksitesData(sitesFromApi);

  const tagInfo = useMemo(
    () => [
      {
        key: 1,
        title: 'Tag ID',
        value: tagData.serialNumber || '',
      },
      {
        key: 2,
        title: 'Internal ID (opt)',
        value: tagData.label || '',
      },
      {
        key: 3,
        title: 'Average number of days between tag tests',
        value: tagData.avgDaysBetweenTests ? `${tagData.avgDaysBetweenTests.toFixed(1)} Days` : '',
      },
    ],
    [tagData.serialNumber, tagData.label, tagData.avgDaysBetweenTests],
  );

  const baseStationFilterOptions = baseStationFilterData.map((baseStationFilter) => ({
    value: baseStationFilter.id,
    label: `${baseStationFilter.serialNumber}${
      baseStationFilter.label ? ` (${baseStationFilter.label})` : ''
    }`,
  }));

  return {
    period,
    setPeriod,
    tagInfo,
    selectedBaseStations,
    setSelectedBaseStations,
    tagData,
    worksitesData,
    heatmapData,
    chartData,
    pedestrianIncursionsData,
    driverIncursionsData,
    notes,
    dateFormat,
    baseStationFilterOptions,
    isLoading,
  };
};
