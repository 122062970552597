import React from 'react';

import { Header } from '../../atoms/TagAndBaseStation';
import { NavSpinner, MapWithLayers, EntityNotes, MultiSelect } from '../../atoms';
import { tagFetchedDataType } from '../../API/hooks/useFetchTag/types';
import { VehicleOperatorCards } from './VehicleOperatorCards/VehicleOperatorCards';
import { PedestrianCards } from './PedestrianCards/PedestrianCards';
import { TimelineChart } from './TimelineChart/TimelineChart';
import { useTag } from './useTag';
import styles from './Tag.module.scss';

const Tag = ({ initialData }) => {
  const {
    period,
    setPeriod,
    tagInfo,
    selectedBaseStations,
    setSelectedBaseStations,
    tagData,
    worksitesData,
    heatmapData,
    chartData: { acknowledgedIncursions, unacknowledgedIncursions },
    pedestrianIncursionsData,
    driverIncursionsData,
    notes,
    dateFormat,
    baseStationFilterOptions,
    isLoading,
  } = useTag(initialData);

  return (
    <div className={styles.page}>
      {isLoading && <NavSpinner />}
      <Header period={period} setPeriod={setPeriod} data={tagInfo} dateFormat={dateFormat}>
        <MultiSelect
          currentValues={selectedBaseStations}
          initialValues={selectedBaseStations}
          options={baseStationFilterOptions}
          onChange={(selectedValues) => setSelectedBaseStations(selectedValues)}
          configOptions={{
            isMulti: true,
            closeMenuOnSelect: false,
            isSearchable: true,
          }}
          label="Filter by a specific Machine"
        />
      </Header>
      <MapWithLayers
        heatmapData={heatmapData}
        worksitesData={worksitesData}
        switchers={{ sites: true, heatmap: true, locationHistory: false }}
        defaultLayers={{ heatmap: false }}
        wrapperClassName={styles.map}
      />
      <section className={styles.infoSection}>
        <PedestrianCards pedestrianIncursionsData={pedestrianIncursionsData} />
        <VehicleOperatorCards driverIncursionsData={driverIncursionsData} />
        <TimelineChart
          acknowledgedIncursions={acknowledgedIncursions}
          unacknowledgedIncursions={unacknowledgedIncursions}
          period={period}
          dateFormat={dateFormat}
        />
        <EntityNotes entityId={tagData.id} initialNotes={notes} userName={initialData.userName} />
      </section>
    </div>
  );
};

export default Tag;

Tag.propTypes = {
  initialData: tagFetchedDataType,
};
