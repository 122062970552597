import React from 'react';

import moment from 'moment';
import { DataInfo } from './DataInfo';
import { reportType } from '../../../API/hooks/useFetchReports/types';
import styles from './Report.module.scss';
import { LoadingSpinner } from '../../../atoms';
import { dateFormatType } from '../../../API/types';

export const Report = ({
  report: { period, projects, sites, status, createdAt, downloadUrl, filename },
  dateFormat,
}) => {
  return (
    <li>
      <div className={styles.wrapper}>
        {downloadUrl ? (
          <a href={downloadUrl} className={styles.link}>
            {filename}
          </a>
        ) : (
          <p className={styles.link}>Generating report...</p>
        )}
        {period[0] && period[1] && (
          <DataInfo label="Period">
            {moment(period[0]).format(dateFormat)} - {moment(period[1]).format(dateFormat)}
          </DataInfo>
        )}
        <DataInfo label="Projects">
          {projects.length > 0
            ? projects.map((project, index) => (
                <span key={project.id} className={styles.break}>{`${project.name}${
                  index === projects.length - 1 ? '' : ', '
                }`}</span>
              ))
            : '-'}
        </DataInfo>
        <DataInfo label="Sites">
          {sites.length > 0
            ? sites.map((site, index) => (
                <span key={site.id} className={styles.break}>{`${site.name}${
                  index === sites.length - 1 ? '' : ', '
                }`}</span>
              ))
            : '-'}
        </DataInfo>
        {createdAt && (
          <DataInfo label="Created at">{moment(createdAt).format(`${dateFormat} HH:mmA`)}</DataInfo>
        )}
        <DataInfo label="Status">
          <div className={styles.statusWrapper}>
            <span className={styles[status]}>{status}</span>
          </div>
        </DataInfo>
        {status === 'ready' ? (
          <div>
            <a href={downloadUrl} className={styles.button}>
              Download
            </a>
          </div>
        ) : (
          <LoadingSpinner size={{ width: 16, height: 16 }} />
        )}
      </div>
    </li>
  );
};

Report.propTypes = {
  report: reportType.isRequired,
  dateFormat: dateFormatType,
};
