import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { func, string, oneOf } from 'prop-types';
import moment from 'moment';

import { periodType } from '../../types';
import styles from '../TimePicker.module.scss';

const isDateTheSame = (startDate, endDate) => {
  return (
    startDate.get('year') === endDate.get('year') &&
    startDate.get('month') === endDate.get('month') &&
    startDate.get('date') === endDate.get('date')
  );
};

export const RangeTimePicker = ({
  period,
  setPeriod,
  periodOption,
  label,
  wrapperClassName = '',
  inputWrapperClassName = '',
  resetDateRange,
}) => {
  const periodInputRef = useRef(null);

  useEffect(() => {
    let calendar;

    if (periodInputRef.current) {
      calendar = flatpickr(periodInputRef.current, {
        altInput: true,
        altFormat: 'G:i K',
        altInputClass: `${styles.periodInput} form-control`,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
        minuteIncrement: 10,
        defaultDate: `${period[periodOption].get('hour')}:${period[periodOption].get('minute')}`,
        onChange: (selectedDates) => {
          if (resetDateRange) {
            resetDateRange();
          }

          const hour = moment(selectedDates[0]).get('hour');
          const minute = moment(selectedDates[0]).get('minute');

          const updatedPeriod = period[periodOption].set({ hour, minute, second: 0 });

          setPeriod((prevPeriod) => {
            if (
              isDateTheSame(prevPeriod.startDate, prevPeriod.endDate) &&
              prevPeriod.startDate.valueOf() >= prevPeriod.endDate.valueOf()
            ) {
              return {
                startDate:
                  periodOption === 'startDate'
                    ? moment(prevPeriod.endDate).subtract(1, 'hour')
                    : prevPeriod.startDate,
                endDate:
                  periodOption === 'endDate'
                    ? moment(prevPeriod.startDate).add(1, 'hour')
                    : prevPeriod.endDate,
              };
            }

            return {
              ...prevPeriod,
              [periodOption]: updatedPeriod,
            };
          });
        },
      });
    }

    return () => {
      calendar?.destroy();
    };
  }, [period.startDate, period.endDate, periodInputRef]);

  return (
    <div className={`d-flex align-items-center ${wrapperClassName}`}>
      <label htmlFor="datepicker-period-field" className={`mb-0 ${styles.label}`}>
        {label}
      </label>
      <div className={`${styles.periodInputWrapper} ${inputWrapperClassName}`}>
        <input id="datepicker-period-field" ref={periodInputRef} />
        <i className={`fa-thin fa-clock-three mr-2 ${styles.insideIcon}`} />
      </div>
    </div>
  );
};

RangeTimePicker.propTypes = {
  period: periodType.isRequired,
  setPeriod: func.isRequired,
  periodOption: oneOf(['startDate', 'endDate']).isRequired,
  label: string.isRequired,
  wrapperClassName: string,
  inputWrapperClassName: string,
  resetDateRange: func,
};
