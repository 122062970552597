import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'deploymentWrapper',
    'deploymentSelector',
  ];

  connect() {
    this.update();
  }

  update() {
    if (this.hasDeploymentSelectorTarget) {
      switch (this.deploymentSelectorTarget.checked) {
        case true:
          this.deploymentWrapperTarget.style.display = 'block';
          break;
        case false:
          this.deploymentWrapperTarget.style.display = 'none';
          break;
      }
    }
  }
}