import React from 'react';
import { SWRConfig } from 'swr';

import { fetchApi } from '../../utils';
import Reports from './Reports';

export const APILinkedInsights = (props) => (
  <SWRConfig
    value={{
      fetcher: fetchApi,
    }}
  >
    <Reports {...props} />
  </SWRConfig>
);

export default (props) => <APILinkedInsights {...props} />;
