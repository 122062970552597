import React from 'react';
import { string, arrayOf, number } from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { ChartWrapper } from '../ChartWrapper';
import colors from '../../../../assets/stylesheets/_colors.scss';

export const BarChart = ({ data, valuesLabel, labels, chartTitle, wrapperClassName = '' }) => {
  const barData = {
    labels,
    datasets: [
      {
        label: valuesLabel,
        backgroundColor: colors.blue1,
        hoverBackgroundColor: colors.blue1,
        borderColor: colors.blue1,
        borderWidth: 1,
        data,
      },
    ],
  };

  const chartOptions = {
    title: {
      display: true,
      padding: 20,
      text: chartTitle,
      fontSize: 16,
      fontColor: '#000',
      fontStyle: 'normal',
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            max: 100,
          },
        },
      ],
    },
    layout: {
      padding: {
        top: 40,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          return `${valuesLabel}: ${tooltipItem.value}%`;
        },
      },
    },
  };

  return (
    <ChartWrapper wrapperClassName={wrapperClassName}>
      <Bar
        data={barData}
        options={chartOptions}
        legend={{ display: false }}
        // height={250}
      />
    </ChartWrapper>
  );
};

BarChart.propTypes = {
  data: arrayOf(number).isRequired,
  valuesLabel: string.isRequired,
  labels: arrayOf(string).isRequired,
  chartTitle: string.isRequired,
  wrapperClassName: string,
};
