import React from 'react';
import PropTypes from 'prop-types';

import styles from '../worksitesTable.module.scss';
import cellStyles from './ColorCell.module.scss';

const Color = ({ color }) => (
  <div className={styles.centeredCell}>
    <div
      className={cellStyles.zoneColourIcon}
      style={{ backgroundColor: color }}
    />
  </div>
);
Color.propTypes = {
  color: PropTypes.string,
};

export const ColorCell = props => <Color color={props.row.values.color} />;
ColorCell.propTypes = {
  row: PropTypes.shape({
    values: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
    }),
  }),
};
