export default ({
  callback = () => null,
  timeout = 1000,
  success = true,
  resolve: resolveData = null,
  reject: rejectData = null,
}) => () => {
  const q = new Promise(function(resolve, reject) {
    console.log('Fetching Data');
    setTimeout(function() {
      if (success) resolve(resolveData);
      else reject(rejectData);
      callback();
    }, timeout);
  });

  return q;
};
