export const changeCurrentItemsFormat = (currentItems) => {
  if (currentItems && currentItems.length > 0) {
    const currentItemsToSelect = currentItems.map((item) => ({
      value: item.id || item.key,
      label: item.name,
      ...item,
    }));

    return currentItemsToSelect;
  }
  return null;
};

export const changeSelectedItemsFormat = (currentItems) => {
  if (currentItems && currentItems.length > 0) {
    const currentItemsToState = currentItems.map((item) => {
      const { value, label, ...rest } = item;
      const newItem = rest;
      return newItem;
    });

    return currentItemsToState;
  }
  return null;
};
