import { useMemo } from 'react';
import useSWR from 'swr';

import { errorMessage } from '../../utils';
import { getPath } from './getPath';
import { useShowMessage } from '../../../hooks';

export const useFetchEntities = ({
  initialData,
  shouldFetch,
  currentProjects,
  currentSites,
  period,
  searchText,
  types,
  page,
  per,
  sort,
  sortBy,
  showInactive,
  showArchived,
  machineKinds,
}) => {
  const { msgToDisplay, setMsgToDisplay } = useShowMessage();

  const path = useMemo(
    () =>
      getPath({
        target: 'entities',
        period,
        currentProjects,
        currentSites,
        searchText,
        types,
        page,
        per,
        sort,
        sortBy,
        showInactive,
        showArchived,
        machineKinds,
      }),
    [
      period,
      currentProjects,
      currentSites,
      searchText,
      types,
      page,
      per,
      sort,
      sortBy,
      showInactive,
      showArchived,
      machineKinds,
    ],
  );

  const { data, mutate, isLoading } = useSWR(shouldFetch ? `${process.env.API_URL}${path}` : null, {
    fallbackData: initialData,
    errorRetryCount: 3,
    onError: (err) => {
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      });
    },
    onErrorRetry: (err) =>
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      }),
    onSuccess: () => {
      setMsgToDisplay({ msg: '', className: '' });
    },
    revalidateOnMount: true,
    keepPreviousData: true,
    revalidateOnFocus: false,
  });

  return { data, msgToDisplay, mutate, isLoading };
};
