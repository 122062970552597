import React from 'react';
import { SWRConfig } from 'swr';
import { fetchApi } from '../../../utils';
import People from './People';
import { peopleFetchedDataType } from '../../../API/hooks/useFetchEntities/types';
import { StorageFiltersProvider } from '../../../contexts/StorageFiltersContext/StorageFiltersProvider/StorageFiltersProvider';

export const APILinkedPeople = (props) => {
  return (
    <SWRConfig
      value={{
        fetcher: fetchApi,
      }}
    >
      <StorageFiltersProvider>
        <People {...props} />
      </StorageFiltersProvider>
    </SWRConfig>
  );
};

export default (props) => <APILinkedPeople {...props} />;

APILinkedPeople.propTypes = {
  initialData: peopleFetchedDataType,
};
