import React from 'react';

import { Filters, MapWithLayers, NavSpinner } from '../../atoms';
import { heatmapFetchedDataType } from '../../API/hooks/useFetchIncursions/types';
import { useHeatmap } from './useHeatmap';
import styles from './Heatmap.module.scss';

const Heatmap = ({ initialData }) => {
  const {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    period,
    setPeriod,
    dateFormat,
    projectOptions,
    siteOptions,
    heatmapData,
    worksitesData,
    isLoading,
  } = useHeatmap(initialData);

  return (
    <div className={styles.page}>
      {isLoading && <NavSpinner />}
      <Filters
        source="heatmap"
        period={period}
        setPeriod={setPeriod}
        dateFormat={dateFormat}
        projectOptions={projectOptions}
        siteOptions={siteOptions}
        currentProjects={currentProjects}
        setCurrentProjects={setCurrentProjects}
        currentSites={currentSites}
        setCurrentSites={setCurrentSites}
      />
      <MapWithLayers
        heatmapData={heatmapData}
        worksitesData={worksitesData}
        switchers={{ sites: true, heatmap: false, locationHistory: false }}
        defaultLayers={{ heatmap: true }}
        wrapperClassName="mb-4"
      />
    </div>
  );
};

export default Heatmap;

Heatmap.propTypes = {
  initialData: heatmapFetchedDataType,
};
