import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { func, string } from 'prop-types';
import moment from 'moment';

import { singlePeriodType } from '../../types';
import styles from '../TimePicker.module.scss';

export const SingleTimePicker = ({
  period,
  setPeriod,
  label,
  wrapperClassName = '',
  inputWrapperClassName = '',
}) => {
  const periodInputRef = useRef(null);

  useEffect(() => {
    let calendar;

    if (periodInputRef.current) {
      calendar = flatpickr(periodInputRef.current, {
        altInput: true,
        altFormat: 'G:i K',
        altInputClass: `${styles.periodInput} form-control`,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
        minuteIncrement: 10,
        defaultDate: `${period.date.get('hour')}:${period.date.get('minute')}`,
        onChange: ([selectedDate]) => {
          const hour = moment(selectedDate).get('hour');
          const minute = moment(selectedDate).get('minute');

          const updatedPeriod = period.date.set({ hour, minute, second: 0 });

          setPeriod({ date: updatedPeriod });
        },
      });
    }

    return () => {
      calendar?.destroy();
    };
  }, [period.date, periodInputRef]);

  return (
    <div className={`d-flex align-items-center ${wrapperClassName}`}>
      <label htmlFor="datepicker-period-field" className={`mb-0 ${styles.label}`}>
        {label}
      </label>
      <div className={`${styles.periodInputWrapper} ${inputWrapperClassName}`}>
        <input id="datepicker-period-field" ref={periodInputRef} />
        <i className={`fa-thin fa-clock-three mr-2 ${styles.insideIcon}`} />
      </div>
    </div>
  );
};

SingleTimePicker.propTypes = {
  period: singlePeriodType.isRequired,
  setPeriod: func.isRequired,
  label: string.isRequired,
  wrapperClassName: string,
  inputWrapperClassName: string,
};
