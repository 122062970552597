import colors from '../../../assets/stylesheets/_colors.scss';

export class PathRenderer {
  constructor(google) {
    this.google = google;
    this.drawnPaths = [];
  }

  renderNewLines(points = []) {
    this.clearPaths();
    this.renderLinesBetweenPoints(points);
  }

  renderLinesBetweenPoints(points = []) {
    const path = points.map(({ props: { lat, lng } }) => {
      return { lat, lng };
    });
    const locationPath = new this.google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: colors.markerBlue,
      strokeOpacity: 1,
      strokeWeight: 2,
    });

    locationPath.setMap(this.google.map);
    this.drawnPaths.push(locationPath);
  }

  clearPaths() {
    this.drawnPaths.forEach(path => path.setMap(null));
  }
}
