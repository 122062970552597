import React from 'react';
import { func, bool, string } from 'prop-types';

import {
  SwitcherWrapper,
  SwitcherLabelDefault,
  SwitcherInput,
} from '../components';

export const SwitcherDefault = ({
  onChange,
  checked,
  labelText,
  wrapperClassName = '',
}) => (
  <SwitcherWrapper wrapperClassName={wrapperClassName}>
    <SwitcherLabelDefault labelText={labelText} />
    <SwitcherInput
      onChange={onChange}
      checked={checked}
      labelText={labelText}
    />
  </SwitcherWrapper>
);

SwitcherDefault.propTypes = {
  onChange: func.isRequired,
  checked: bool.isRequired,
  labelText: string.isRequired,
  wrapperClassName: string,
};
