import { oneOf, func, node, string } from 'prop-types';
import React, { useState } from 'react';

import styles from './DrawerTrigger.module.scss';

export const DrawerTrigger = ({ children, label, styleOption, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  const onOpen = () => setIsVisible(true);
  const onClose = () => setIsVisible(false);

  return (
    <>
      <button
        type="button"
        className={`${styleOption ? styles[styleOption] : ''} ${className || ''}`}
        onClick={onOpen}
      >
        {label}
      </button>
      {children(isVisible, onClose)}
    </>
  );
};

DrawerTrigger.propTypes = {
  children: func.isRequired,
  label: node.isRequired,
  styleOption: oneOf(['icon']),
  className: string,
};
