import Wkt from 'wicket/wicket-gmap3';

export function getGeoJsonFromShape(shape) {
  const wicket = new Wkt.Wkt();

  if (isGoogleShapeACircle(shape)) {
    const radius = shape.getRadius();
    const center = shape.getCenter();

    const geoJsonCircle = {
      type: 'Feature',
      properties: {
        radius,
      },
      geometry: { type: 'Point', coordinates: [center.lng(), center.lat()] },
    };

    return geoJsonCircle;
  } else {
    const wktPolygon = wicket.fromObject(shape);
    const geoJsonPolygon = wktPolygon.toJson();

    return geoJsonPolygon;
  }
}

export default getGeoJsonFromShape;

function isGoogleShapeACircle(shape) {
  return !!shape.getRadius;
}
