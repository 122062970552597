import React from 'react';
import { string } from 'prop-types';
import moment from 'moment';

import { machineParameterType } from 'API/hooks/useFetchBaseStationParameter/types';

import styles from './MachineInsightsParam.module.scss';

const formatName = (name) => name.split('.').slice(1).join(' ');
const formatUnit = (unit) => {
  switch (true) {
    case unit === 'celsius':
      return '°C';
    default:
      return unit;
  }
};

export const MachineInsightsParam = ({ param, dateFormat }) => {
  const { value, name, timestamp, unit } = param;

  return (
    <div className={styles.wrapper}>
      <span className={styles.paramValue}>
        {value} {formatUnit(unit)}
      </span>
      <span className={styles.paramName}>{formatName(name)}</span>
      <span className={styles.timestamp}>
        {moment(timestamp).format(`${dateFormat} [@] HH:mm Z`)}
      </span>
    </div>
  );
};

MachineInsightsParam.propTypes = {
  param: machineParameterType,
  dateFormat: string,
};
