import axios from 'axios';

export const downloadReport = ({ projectIds, siteIds, period, multiplesheets, type }) =>
  axios.post(`${process.env.API_URL}/reports`, {
    project_ids: projectIds,
    site_ids: siteIds,
    period: [period[0].toISOString(true), period[1].toISOString(true)], // Convert to ISO 8601 String preserving the time zone.
    multiple_sheets: multiplesheets,
    type,
  });
