import moment from 'moment';

import { dateFormatType } from '../../../../API/types';
import { periodType } from '../../../DatePicker/types';

export const usePeriodName = ({ period, dateFormat }) => {
  return `${moment(period.startDate).format(`${dateFormat} hh:mmA`)} to ${moment(
    period.endDate,
  ).format(`${dateFormat} hh:mmA`)}`;
};

usePeriodName.propTypes = {
  currentPeriod: periodType,
  dateFormat: dateFormatType,
};
