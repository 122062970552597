import React from 'react';
import { arrayOf } from 'prop-types';

import { projectType } from 'API/types';
import styles from './ProjectCell.module.scss';

export const ProjectCell = ({ value }) => {
  return (
    <>
      {value.length > 0 && (
        <ul className={styles.list}>
          {value.map((project, index) => (
            <li key={project.id}>
              {project.name.trim()}
              {index === value.length - 1 ? '' : ','}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

ProjectCell.propTypes = {
  value: arrayOf(projectType),
};
