import React from 'react';
import { SWRConfig } from 'swr';

import { fetchApi } from '../../utils';
import Heatmap from './Heatmap';
import { heatmapFetchedDataType } from '../../API/hooks/useFetchIncursions/types';
import { StorageFiltersProvider } from '../../contexts/StorageFiltersContext/StorageFiltersProvider/StorageFiltersProvider';

export const APILinkedHeatmap = (props) => {
  return (
    <SWRConfig
      value={{
        fetcher: fetchApi,
      }}
    >
      <StorageFiltersProvider>
        <Heatmap {...props} />
      </StorageFiltersProvider>
    </SWRConfig>
  );
};

export default (props) => <APILinkedHeatmap {...props} />;

APILinkedHeatmap.propTypes = {
  initialData: heatmapFetchedDataType,
};
