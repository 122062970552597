import React from 'react';
import { string } from 'prop-types';

import { Icon } from '../../../../../atoms';
import { machineIcons } from '../../../../../atoms/Icon/utils';
import { DefaultMachineIcon } from '../../../../../atoms/Icon/Icons';
import { entitiesListType } from '../../../../../API/hooks/useFetchEntities/types';
import styles from './MachineKindCell.module.scss';
import cellStyles from '../TableSection.module.scss';
import { useActiveMachineContext } from '../../../../../contexts/ActiveMachineContext';

export const MachineKindCell = ({ value, data }) => {
  const currentRow = data.find((row) => row.id === String(value));
  const lowerValue = currentRow.kind.toLowerCase();

  const { toggleActiveMachineId } = useActiveMachineContext();

  const handleClick = (id) => {
    toggleActiveMachineId(id);
  };

  return (
    <div className={cellStyles.cell}>
      <button type="button" className={styles.button} onClick={() => handleClick(currentRow.id)}>
        <Icon size="extraLarge" className="mx-auto">
          {machineIcons[lowerValue] || <DefaultMachineIcon />}
        </Icon>
      </button>
    </div>
  );
};

MachineKindCell.propTypes = {
  value: string,
  data: entitiesListType,
};
