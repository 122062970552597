import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['notificationWrapper', 'contentWrapper'];

  connect() {
    this.setContentWrapperHeight(this.getNotificationWrapperHeight());
  }

  getNotificationWrapperHeight() {
    return this.notificationWrapperTarget.clientHeight;
  }

  setContentWrapperHeight(height) {
    this.contentWrapperTarget.setAttribute(
      'style',
      `height: calc(100% - ${height}px)`,
    );
  }
}
