import { useMemo } from 'react';

import { useGetWorksitesData } from '../../hooks';
import { useFetchBaseStation } from '../../API/hooks';
import { useStorageFilters } from '../../contexts/StorageFiltersContext/useStorageFilters';
import { usePeriodComparison } from 'hooks/usePeriodComparison';

export const useBaseStation = (initialDataFromProps) => {
  const slug = useMemo(() => {
    const { pathname } = document.location;
    const urlSegments = pathname.split('/');
    return urlSegments[urlSegments.length - 1];
  }, []);

  const { period, setPeriod } = useStorageFilters();

  const { arePeriodsTheSame } = usePeriodComparison({
    period,
    initialPropsPeriod: initialDataFromProps.period,
  });

  const {
    data: {
      incursionsData,
      baseStation: baseStationData,
      heatmapData,
      sites: sitesFromApi,
      chartData,
      locations: locationsData,
      notes,
      dateFormat,
    },
    isLoading,
  } = useFetchBaseStation({
    initialData: initialDataFromProps,
    shouldFetch: !arePeriodsTheSame,
    period,
    baseStationId: slug,
  });

  const worksitesData = useGetWorksitesData(sitesFromApi);

  const baseStationInfo = useMemo(
    () => [
      {
        key: 1,
        title: 'Name / Internal ID',
        value: baseStationData.name || '',
      },
      {
        key: 2,
        title: 'Manufacturer',
        value: baseStationData.manufacturer || '',
      },
      {
        key: 3,
        title: 'Model',
        value: baseStationData.model || '',
      },
      {
        key: 4,
        title: 'Owner',
        value: baseStationData.owner || '',
      },
      // {
      //   key: 5,
      //   title: 'Machine / Plant Number',
      //   value: baseStationData.numberPlate || '',
      // },
      // {
      //   key: 6,
      //   title: 'ID (Connectivity Module)',
      //   value: baseStationData.serialNumber || '',
      // },
    ],
    [
      baseStationData.name,
      baseStationData.manufacturer,
      baseStationData.model,
      baseStationData.owner,
      baseStationData.numberPlate,
      baseStationData.serialNumber,
    ],
  );

  return {
    baseStationInfo,
    heatmapData,
    worksitesData,
    incursionsData,
    chartData,
    locationsData,
    notes,
    baseStationData,
    dateFormat,
    isLoading,
    period,
    setPeriod,
  };
};
