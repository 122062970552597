import { DATE_FORMAT_FOR_REQUESTS } from '../../consts/api';
import moment from 'moment';

export const getApiDateString = dateObj => {
  if (!dateObj || !(dateObj instanceof Date)) {
    return '';
  }

  return moment(dateObj).format(DATE_FORMAT_FOR_REQUESTS);
};

export default getApiDateString;
