import React from 'react';
import { func, arrayOf, bool } from 'prop-types';

import { changeCurrentItemsFormat, changeSelectedItemsFormat } from 'atoms/MultiSelect/utils';
import { dateFormatType, projectType } from '../../../API/types';
import { periodType } from 'atoms/DatePicker/types';
import { DrawerWithDatePicker } from 'atoms/Drawer/components/DrawerWithDatePicker/DrawerWithDatePicker';
import { MultiSelect, Button } from 'atoms';
import { optionsType } from 'atoms/Filters/types';

import styles from './GenerateReportForm.module.scss';

const getDefaultReportType = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('type') || 'TagIncursions';
};

const GenerateReportForm = ({
  currentProjects,
  setCurrentProjects,
  currentSites,
  setCurrentSites,
  period,
  setPeriod,
  projectOptions,
  siteOptions,
  dateFormat,
  isLoading,
  handleClick,
}) => {
  const reportType = getDefaultReportType();

  return (
    <div className={styles.box}>
      <div className={styles.section}>
        <p className={styles.label}>Select a Report Type</p>
        <div className={styles.actions}>
          <a
            href="/reports?type=TagIncursions"
            className={`${styles.actionLink} ${reportType === 'TagIncursions' && styles.active}`}
          >
            <i
              className="fa-duotone fa-solid fa-user-helmet-safety"
              style={{
                '--fa-primary-color': '#B2E1BB',
                '--fa-secondary-color': '#3EB456',
                '--fa-secondary-opacity': 1,
                fontSize: 48,
              }}
            />
            <p className={styles.actionLinkText}>
              <strong>Operator & Pedestrian</strong>
              Report
            </p>
          </a>
          <a
            href="/reports?type=TagTesting"
            className={`${styles.actionLink} ${reportType === 'TagTesting' && styles.active}`}
          >
            <i
              className="fa-duotone fa-solid fa-shield-check"
              style={{
                '--fa-primary-color': '#3EB456',
                '--fa-secondary-color': '#3EB456',
                fontSize: 48,
              }}
            />
            <p className={styles.actionLinkText}>
              <strong>Tag testing</strong>
              Report
            </p>
          </a>
        </div>
      </div>
      <div className={styles.section}>
        <p className={styles.label}>Define Scope</p>
        <div className={styles.inputContainer}>
          <MultiSelect
            currentValues={changeCurrentItemsFormat(currentProjects)}
            initialValues={changeCurrentItemsFormat(currentProjects)}
            options={projectOptions}
            placeholder="All Projects"
            onChange={(selectedValues) =>
              setCurrentProjects(changeSelectedItemsFormat(selectedValues))
            }
            wrapperClassName={styles.projects}
          />
          <MultiSelect
            currentValues={changeCurrentItemsFormat(currentSites)}
            initialValues={changeCurrentItemsFormat(currentSites)}
            options={siteOptions}
            placeholder="All Sites"
            onChange={(selectedValues) =>
              setCurrentSites(changeSelectedItemsFormat(selectedValues))
            }
            wrapperClassName={styles.sites}
          />
        </div>
      </div>
      <div className={styles.section}>
        <p className={styles.label}>Select period</p>
        <DrawerWithDatePicker
          period={period}
          setPeriod={setPeriod}
          dateFormat={dateFormat}
          wrapperClassName={styles.dateWrapper}
        />
      </div>
      <Button
        disabled={isLoading}
        kind="success"
        onClick={() => handleClick(reportType)}
        className={styles.button}
      >
        Generate Report
      </Button>
    </div>
  );
};

GenerateReportForm.propTypes = {
  currentProjects: arrayOf(projectType),
  setCurrentProjects: func,
  currentSites: arrayOf(projectType),
  setCurrentSites: func,
  period: periodType,
  setPeriod: func,
  projectOptions: optionsType,
  siteOptions: optionsType,
  dateFormat: dateFormatType,
  isLoading: bool,
  handleClick: func,
};

export default GenerateReportForm;
