import { useState } from 'react';
import { createNote, updateNote, deleteNote } from '../../API/mutations';

export const useEntityNotes = (
  entityId,
  initialNotes,
  userName,
  closeModal,
) => {
  const [notesData, setNotesData] = useState(initialNotes);
  const [openedNoteId, setOpenedNoteId] = useState(null);
  const [inputText, setInputText] = useState('');

  const handleNoteAdd = async () => {
    if (inputText) {
      const note = {
        date: new Date(),
        author: userName,
        text: inputText,
      };

      try {
        const { data } = await createNote(entityId, note);
        note.id = data.id;
        setNotesData([...notesData, note]);
        setInputText('');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleNoteEdit = async (noteId, text) => {
    if (text) {
      try {
        await updateNote(entityId, { noteId, author: userName, text });
        const newNotesData = notesData.map(note =>
          note.id === noteId ? { ...note, text } : note,
        );
        setNotesData(newNotesData);
        setOpenedNoteId(null);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleNoteDelete = async () => {
    setOpenedNoteId(null);

    try {
      await deleteNote(entityId, openedNoteId);
      const newNotesData = notesData.filter(note => note.id !== openedNoteId);
      setNotesData(newNotesData);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const onEnterPress = event => {
    const ENTER_KEY_CODE = 13;

    if (event.which == ENTER_KEY_CODE || event.keyCode == ENTER_KEY_CODE) {
      handleNoteAdd();
    }
  };

  const onChange = event => setInputText(event.target.value);

  const handleNoteClose = () => {
    setOpenedNoteId(null);
  };

  return {
    handleNoteAdd,
    handleNoteEdit,
    handleNoteDelete,
    onEnterPress,
    onChange,
    handleNoteClose,
    notesData,
    openedNoteId,
    setOpenedNoteId,
    inputText,
  };
};
