import React from 'react';

export const DefaultMachineIcon = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1704.86 1099"
    >
      <path
        d="M296.2,664.2c-76.42,0-138.6,62.17-138.6,138.6s62.18,138.6,138.6,138.6,138.6-62.18,138.6-138.6-62.18-138.6-138.6-138.6Zm0,227.2c-48.85,0-88.6-39.75-88.6-88.6s39.75-88.6,88.6-88.6,88.6,39.75,88.6,88.6-39.75,88.6-88.6,88.6Z"
        fill="#fff"
      />
      <path
        d="M997.24,664.2c-76.42,0-138.6,62.17-138.6,138.6s62.18,138.6,138.6,138.6,138.6-62.18,138.6-138.6-62.18-138.6-138.6-138.6Zm0,227.2c-48.85,0-88.6-39.75-88.6-88.6s39.75-88.6,88.6-88.6,88.6,39.75,88.6,88.6-39.75,88.6-88.6,88.6Z"
        fill="#fff"
      />
      <path
        d="M1679.86,983.5h-39.97l-120.06-458.25c-2.88-11-12.82-18.66-24.18-18.66h-71.42c-12.23,0-22.66,8.85-24.66,20.91l-15.67,94.56-193.67-44c-53.68-46.24-121.39-71.47-192.98-71.47-16.31,0-32.42,1.33-48.2,3.9L820.11,18.66c-2.88-10.99-12.82-18.66-24.18-18.66H296.2c-13.81,0-25,11.19-25,25v242.11h-99.97c-71.28,0-129.28,57.99-129.28,129.28v253.92c0,.09,0,.18,0,.27C14.61,696.09,0,748.32,0,802.79c0,79.12,30.81,153.5,86.76,209.45,55.95,55.95,130.33,86.76,209.45,86.76s153.5-30.81,209.45-86.76c27-27,48.12-58.3,62.77-92.34h156.59c14.65,34.04,35.78,65.34,62.77,92.34,55.95,55.95,130.33,86.76,209.45,86.76s153.5-30.81,209.45-86.76c27-27,48.12-58.3,62.77-92.34h76.8c5.32,22.33,15.84,43.37,31.08,61.35,28.16,33.21,69.28,52.25,112.82,52.25h189.7c13.81,0,25-11.19,25-25s-11.19-25-25-25ZM321.2,50h455.43l121,461.58-306.25-69.58-1.46-150.13c-.13-13.71-11.29-24.76-25-24.76h-243.73V50Zm-25,999c-135.76,0-246.2-110.45-246.2-246.21s110.45-246.2,246.2-246.2,246.21,110.45,246.21,246.2-110.45,246.21-246.21,246.21Zm288.6-179.1c5.01-21.75,7.61-44.23,7.61-67.11,0-79.12-30.81-153.5-86.76-209.45-55.95-55.95-130.33-86.76-209.45-86.76s-148.93,28.95-204.24,81.68v-191.88c0-43.71,35.56-79.28,79.28-79.28h368.93l1.41,145.13c.11,11.58,8.17,21.57,19.46,24.14l281.21,63.9c-19.54,12.04-37.82,26.44-54.45,43.08-55.95,55.95-86.76,130.33-86.76,209.45,0,22.88,2.59,45.35,7.61,67.11h-123.84Zm412.44,179.1c-135.76,0-246.2-110.45-246.2-246.21,0-113.98,77.85-210.1,183.17-238.01,1.02-.14,2.04-.33,3.06-.6,.87-.23,1.71-.5,2.53-.81,18.44-4.42,37.67-6.78,57.44-6.78,135.76,0,246.21,110.45,246.21,246.2s-110.45,246.21-246.21,246.21Zm248.88-406.96l129.58,29.44-31.47,189.93c-.47,2.83-.85,5.66-1.15,8.49h-57.24c5.01-21.75,7.61-44.23,7.61-67.11,0-57.92-16.53-113.29-47.33-160.76Zm244.04,341.46c-28.82,0-56.04-12.61-74.68-34.59-18.64-21.98-26.63-50.9-21.92-79.33l51.86-312.99h30.93l111.85,426.91h-98.05Z"
        fill="#fff"
      />
    </svg>
  );
};
