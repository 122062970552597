import React from 'react';
import { number } from 'prop-types';

import { LongInfoCard } from 'atoms';
import { incursionsType } from '../../../API/hooks/useFetchInsights/types';
import styles from './DeploymentInfoCard.module.scss';

export const DeploymentInfoCard = ({ incursionsData, activeSitesCount }) => {
  const totalDeploymentParagraph = [
    {
      activeItemsNr: incursionsData.activeTagsCount,
      text: 'Active tags in period',
    },
    {
      activeItemsNr: incursionsData.activeMachinesCount,
      text: 'Active machines in period',
    },
    {
      activeItemsNr: activeSitesCount,
      text: 'Active sites in period',
    },
  ];

  return (
    <LongInfoCard
      firstTitle="Total deployment"
      // secondTitle="Battery & Testing"
      firstParagraph={totalDeploymentParagraph}
      // secondParagraph={[]}
      boxClassName="h-100"
      wrapperClassName={`mb-3 ${styles.wrapper}`}
    />
  );
};

DeploymentInfoCard.propTypes = {
  incursionsData: incursionsType,
  activeSitesCount: number,
};
