import { useState, useEffect } from 'react';

const useMultiSelect = (save, initialValues, options) => {
  const [optionValues, setOptionValues] = useState(initialValues);
  const [startValues, setStartValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = selectedOptions => {
    setOptionValues(selectedOptions);
    if (selectedOptions === null) {
      selectedOptions = new Array();
    }
    if (selectedOptions) {
      save(selectedOptions.map(option => option.value));
    }
  };

  useEffect(() => {
    if (initialValues.length > 0) {
      for (const id of initialValues) {
        options.map(option => {
          if (option.value === id) {
            setStartValues(prevStartValues => [...prevStartValues, option]);
          } else {
            return null;
          }
        });
      }
    }
    setIsLoading(false);
  }, [initialValues]);

  return { handleChange, startValues, isLoading };
};

export default useMultiSelect;
