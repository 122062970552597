import { shape, number, string, arrayOf, instanceOf, oneOfType, oneOf } from 'prop-types';

export const geometryPolygonType = shape({
  coordinates: arrayOf(arrayOf(arrayOf(number))),
  type: oneOf(['Polygon']),
});

export const geometryCircleType = shape({
  geometry: shape({
    coordinates: arrayOf(number),
    type: oneOf(['Point']),
  }),
  properties: shape({
    radius: number,
  }),
  type: oneOf(['Feature']),
});

export const siteNameType = oneOf([
  'infrastructure',
  'residential construction',
  'commercial construction',
  'rail maintenance',
  'waste & recycling',
  'agriculture',
  'forestry',
  'logistics',
  'other',
]);

export const projectType = shape({
  id: oneOfType([string, number]),
  name: string,
});

export const worksiteType = shape({
  id: oneOfType([string, number]),
  name: string,
  color: string,
  geometry: oneOfType([geometryPolygonType, geometryCircleType]),
  projects: arrayOf(projectType),
  siteType: siteNameType,
});

export const entityType = shape({
  id: oneOfType([string, number]),
  name: string,
});

export const fetchedPeriodType = arrayOf(oneOfType([string, instanceOf(Date)]));

export const notesType = arrayOf(
  shape({
    id: oneOfType([string, number]),
    author: string,
    text: string,
    date: oneOfType([string, instanceOf(Date)]),
  }),
);

export const dateFormatType = oneOf(['DD/MM/YYYY', 'MM/DD/YYYY']);

export const topEntities = arrayOf(
  shape({
    id: string,
    incursion: number,
    slug: string,
  }),
);
export const userType = shape({
  id: oneOfType([string, number]),
  name: string,
});

export const brandType = shape({
  id: oneOfType([string, number]),
  name: string,
});
