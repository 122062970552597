import { bool } from 'prop-types';
import React from 'react';

import styles from './OnlineStatus.module.scss';

export const OnlineStatus = ({ online }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="mr-2">
        <div
          className={`rounded-circle ${online ? 'bg-success' : 'bg-secondary'} ${styles.circle}`}
        />
      </div>
      <span className="mr-2">Vision360:</span>
      <span className="font-weight-bold">{online ? 'Online' : 'Offline'}</span>
    </div>
  );
};

OnlineStatus.propTypes = {
  online: bool,
};
