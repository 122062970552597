/* eslint-disable no-use-before-define */

import { getDefaultPath, getMultiQueryPathWithId } from '../../utils';

export const getPath = ({ target, period, currentUsers, currentBrands }) => {
  let path = '';

  path += getDefaultPath(null, target, period);
  path += getMultiQueryPathWithId(currentUsers, 'usersIds');
  path += getMultiQueryPathWithId(currentBrands, 'brandsIds');

  return path;
};
