import { useRef, useEffect } from 'react';

const useSaveToInput = inputId => {
  const input = useRef();

  useEffect(() => {
    if (inputId) {
      input.current = document.getElementById(inputId);
    }
  }, []);

  const save = data => {
    if (input) {
      input.current.value = JSON.stringify(data);
    }
  };

  return { save };
};

export default useSaveToInput;
