import React from 'react';

import { siteNameType } from '../../../../../API/types';
import styles from './TypeCell.module.scss';

export const TypeCell = ({ value }) => <span className={styles.siteType}>{value}</span>;

TypeCell.propTypes = {
  value: siteNameType,
};
