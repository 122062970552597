import React from 'react';
import { arrayOf } from 'prop-types';

import { projectType, worksiteType, entityType } from '../../../API/types';
import { unacknowledgedPercentageChartType } from '../../../API/hooks/useFetchInsights/types';
import { BarChart } from 'atoms';
import { useProjectsAndSitesChart } from './useProjectsAndSitesChart';
import styles from './ProjectsAndSitesChart.module.scss';

const buttons = [
  {
    key: 'projects',
    label: 'Projects',
    className: 'mr-2',
  },
  {
    key: 'sites',
    label: 'Sites',
    className: 'mr-2',
  },
];

export const ProjectsAndSitesChart = ({
  projects,
  sites,
  unacknowledgedPercentagePerProject,
  unacknowledgedPercentagePerSite,
}) => {
  const { filter, setFilter, getChartData } = useProjectsAndSitesChart();

  return (
    <div className={`mb-3 ${styles.wrapper}`}>
      <div className={`d-flex ${styles.buttonsWrapper}`}>
        {buttons.map(btn => (
          <button
            key={btn.key}
            type="button"
            onClick={() => setFilter(btn.key)}
            className={`btn ${btn.className} ${styles.button} ${
              filter === btn.key ? 'btn-success' : 'btn-outline-success'
            }`}
          >
            {btn.label}
          </button>
        ))}
      </div>
      <BarChart
        data={
          {
            sites: getChartData(sites, unacknowledgedPercentagePerSite).data,
            projects: getChartData(projects, unacknowledgedPercentagePerProject)
              .data,
          }[filter]
        }
        labels={
          {
            sites: getChartData(sites, unacknowledgedPercentagePerSite).labels,
            projects: getChartData(projects, unacknowledgedPercentagePerProject)
              .labels,
          }[filter]
        }
        valuesLabel="Unacknowledged incursions"
        chartTitle="Percentage of incursions that are unacknowledged"
      />
    </div>
  );
};

ProjectsAndSitesChart.propTypes = {
  projects: arrayOf(projectType),
  sites: arrayOf(worksiteType),
  entities: arrayOf(entityType),
  unacknowledgedPercentagePerProject: unacknowledgedPercentageChartType,
  unacknowledgedPercentagePerSite: unacknowledgedPercentageChartType,
};
