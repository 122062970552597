import { useMemo } from 'react';

export const useMapWithLayers = (togglersRef) => {
  const togglersHeight = useMemo(() => {
    if (togglersRef.current) {
      return togglersRef.current.clientHeight;
    }
    return 0;
  }, [togglersRef.current]);
  return { togglersHeight };
};
