import React from 'react';
import { number, shape, oneOfType } from 'prop-types';

import { tagSchema, machineSchema } from 'API/hooks/useFetchEntities/types';
import cellStyles from '../TableSection.module.scss';

export const IncursionsCell = ({ value, row: { original } }) => {
  return <div className={cellStyles.cell}>{original.type === 'Tester' ? '-' : value}</div>;
};

IncursionsCell.propTypes = {
  value: number,
  row: shape({
    original: oneOfType([tagSchema, machineSchema]),
  }),
};
