/* eslint-disable react/jsx-key */

import React from 'react';
import { array, object } from 'prop-types';
import { useTable, useSortBy, usePagination } from 'react-table';

import { Pagination } from './Pagination/Pagination';
import styles from './Table.module.scss';

export const TableSZ = ({ columns, data, customCellProps = {} }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    // pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 25 } },
    useSortBy,
    usePagination,
  );

  const isData = page.length > 0;

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  };

  const renderThIcon = (isSorted, isSortedDesc, isThText) => {
    if (isSorted) {
      if (isSortedDesc) {
        return <i className="far fa-long-arrow-alt-down ml-1" />;
      } else {
        return <i className="far fa-long-arrow-alt-up ml-1" />;
      }
    } else {
      if (isThText) {
        return <i className="fas fa-sort ml-1" />;
      } else {
        return '';
      }
    }
  };

  return (
    <>
      {isData ? (
        <>
          <div className={`${styles.wrapper} pb-1 mb-2 mt-4`}>
            <table {...getTableProps()} className={styles.table}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...(!!column.Header
                          ? column.getHeaderProps(column.getSortByToggleProps())
                          : column.getHeaderProps())}
                      >
                        <div className={styles.thContent}>
                          <span>{column.render('Header')}</span>
                          {renderThIcon(
                            column.isSorted,
                            column.isSortedDesc,
                            column.render('Header'),
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell', customCellProps)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination paginationProps={paginationProps} />
        </>
      ) : (
        <div className="text-center mt-4">No data to display!</div>
      )}
    </>
  );
};

TableSZ.propTypes = {
  columns: array,
  data: array,
  customCellProps: object,
};
