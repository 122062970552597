import React from 'react';
import { SWRConfig } from 'swr';

import { fetchApi } from '../../utils';
import Tag from './Tag';
import { tagFetchedDataType } from '../../API/hooks/useFetchTag/types';
import { StorageFiltersProvider } from '../../contexts/StorageFiltersContext/StorageFiltersProvider/StorageFiltersProvider';

export const APILinkedTag = (props) => {
  return (
    <SWRConfig
      value={{
        fetcher: fetchApi,
      }}
    >
      <StorageFiltersProvider>
        <Tag {...props} />
      </StorageFiltersProvider>
    </SWRConfig>
  );
};

export default (props) => <APILinkedTag {...props} />;

APILinkedTag.propTypes = {
  initialData: tagFetchedDataType,
};
