import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { MultiSelect } from '../MultiSelect/MultiSelect';

import styles from './DateRange.module.scss';

const dateRanges = [
  { value: 0, label: 'Year to Date' },
  { value: 1, label: 'Month to Date' },
  { value: 2, label: 'Quarter to Date' },
  { value: 3, label: 'Last 7 Days' },
  { value: 4, label: 'Last 30 Days' },
];

export const DateRange = ({ dateRange, setDateRange, isLabel }) => {
  return (
    <MultiSelect
      currentValues={dateRange}
      initialValues={dateRange}
      options={dateRanges}
      configOptions={{
        isMulti: false,
        closeMenuOnSelect: true,
        isSearchable: true,
      }}
      placeholder="Date Range"
      label={isLabel ? 'Date Range' : ''}
      onChange={(selectedValue) => setDateRange(selectedValue)}
      wrapperClassName={styles.dateRange}
      isDark
    />
  );
};

DateRange.propTypes = {
  setDateRange: func.isRequired,
  dateRange: shape({
    value: number,
    label: string,
  }),
  isLabel: bool,
};
