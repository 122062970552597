import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './selector.module.scss';

const Selector = ({ items, onSelect }) => {
  const [dataList, setDataList] = useState(new Map());

  const handleChecked = event => {
    dataList.set(event.target.id, event.target.checked);
    setDataList(new Map(dataList));
  };

  useEffect(() => {
    return () => {
      onSelect(dataList);
    };
  }, [dataList]);

  return (
    <div className={styles.selector}>
      {items.map(item => {
        return (
          <label key={item}>
            <input
              id={item}
              type="checkbox"
              checked={dataList[item]}
              onChange={handleChecked}
            />
            <span>{item.charAt(0).toUpperCase() + item.slice(1)}</span>
          </label>
        );
      })}
    </div>
  );
};

Selector.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
};

export default Selector;
