import { node, oneOf, string } from 'prop-types';
import React from 'react';

import styles from './Icon.module.scss';

export const Icon = ({ size = 'medium', className, children }) => {
  return <div className={`${styles[size]} ${styles.wrapper} ${className || ''}`}>{children}</div>;
};

Icon.propTypes = {
  children: node.isRequired,
  size: oneOf(['small', 'medium', 'large', 'extraLarge']),
  className: string,
};
