import React from 'react';
import { string, node } from 'prop-types';

import styles from './DataInfo.module.scss';

export const DataInfo = ({ label, children }) => {
  return (
    <div className={styles.wrapper}>
      <strong>{label}: </strong>
      {children}
    </div>
  );
};

DataInfo.propTypes = {
  label: string.isRequired,
  children: node.isRequired,
};
