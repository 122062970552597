import React from 'react';

import { LoadingSpinner } from 'atoms/index';
import { videoType } from 'API/hooks/useFetchVideos/types';

import styles from './VideoProcessing.module.scss';

export const VideoProcessing = ({ video }) => {
  return (
    <div className="d-flex align-items-center">
      <p className="m-0">
        Status: <strong className={styles.status}>{video.state}</strong>
      </p>
      <div className="ml-2">
        <LoadingSpinner size={{ width: 16, height: 16 }} />
      </div>
    </div>
  );
};

VideoProcessing.propTypes = {
  video: videoType,
};
