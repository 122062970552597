import { useState, useEffect } from 'react';
import { getGeoJsonFromShape } from 'utils';
import { DRAWING_MODES } from 'components/Worksites/types';
import { debounce } from 'debounce';
import { PathRenderer } from '../PathRenderer';

export const useMap = ({ children, connectPoints, drawingMode, onDrawingComplete, onChange }) => {
  const [googleMapInstance, setGoogleMapInstance] = useState();
  const [pathsRenderer, setPathsRenderer] = useState();
  const [drawingManagerInstance, setDrawingManager] = useState(null);

  const onGoogleApiLoaded = (google) => {
    setGoogleMapInstance(google);
    if (connectPoints) {
      setPathsRenderer(new PathRenderer(google));
    }
  };

  useEffect(() => {
    if (connectPoints && pathsRenderer) {
      const points = children.filter(
        (child) =>
          child.type.name === 'InsightsMapMarker' || child.type.name === 'DevicesMapMarker',
      );
      pathsRenderer.renderNewLines(points);
    }
  }, [pathsRenderer, children]);

  useEffect(() => {
    const newDrawingManagerInstanceNeeded =
      drawingMode !== DRAWING_MODES.NONE && googleMapInstance && !drawingManagerInstance;

    if (newDrawingManagerInstanceNeeded) {
      const drawingManager = new googleMapInstance.maps.drawing.DrawingManager({
        drawingMode: googleMapInstance.maps.drawing.OverlayType[drawingMode],
        drawingControl: false,
        drawingControlOptions: {
          position: googleMapInstance.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        polygonOptions: {
          editable: true,
        },
      });

      drawingManager.setMap(googleMapInstance.map);
      googleMapInstance.maps.event.addListener(
        drawingManager,
        'overlaycomplete',
        function finishDrawing(event) {
          const drawnShape = event.overlay;
          drawnShape.setEditable(true);
          drawnShape.setDraggable(true);

          onDrawingComplete({
            geometry: getGeoJsonFromShape(drawnShape),
            shapeInstance: drawnShape,
          });

          const ON_CHANGE_DELAY = 100;
          const onShapeChange = () => {
            onChange(getGeoJsonFromShape(drawnShape));
          };
          const debouncedOnChange = debounce(onShapeChange, ON_CHANGE_DELAY);

          const polygonEvents = ['insert_at', 'remove_at', 'set_at'];
          const circleEvents = ['center_changed', 'radius_changed'];

          const shape = event.type === 'polygon' ? drawnShape.getPath() : drawnShape;
          const shapeEvents = event.type === 'polygon' ? polygonEvents : circleEvents;

          shapeEvents.forEach((shapeEvent) => {
            googleMapInstance.maps.event.addListener(shape, shapeEvent, debouncedOnChange);
          });
        },
      );
      setDrawingManager(drawingManager);
    } else if (drawingMode && drawingMode !== DRAWING_MODES.NONE) {
      setDrawingManagerMode();
    } else {
      clearDrawingManager();
    }

    function setDrawingManagerMode() {
      drawingManagerInstance.setDrawingMode(
        googleMapInstance.maps.drawing.OverlayType[drawingMode],
      );
    }

    function clearDrawingManager() {
      if (!drawingManagerInstance) {
        return;
      }

      drawingManagerInstance.setMap(null);
      setDrawingManager(null);
    }
  }, [googleMapInstance, drawingMode]);

  return { onGoogleApiLoaded, googleMapInstance };
};
