import React from 'react';
import PropTypes from 'prop-types';

import styles from './LoadingSpinner.module.scss';

export const LoadingSpinner = ({ size = { width: 30, height: 30 }, className }) => {
  return (
    <div
      style={{ height: `${size.height}px`, width: `${size.width}px` }}
      className={`${styles.spinner} ${className || ''}`}
    />
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
