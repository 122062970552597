import React from 'react';

import { Filters, IncursionsChart, NavSpinner } from '../../atoms';
import { insightsFetchedDataType } from '../../API/hooks/useFetchInsights/types';

import { DeploymentInfoCard } from './DeploymentInfoCard/DeploymentInfoCard';
import { IncursionsInfoCards } from './IncursionsInfoCards/IncursionsInfoCards';
import { ProjectsAndSitesChart } from './ProjectsAndSitesChart/ProjectsAndSitesChart';
import { useInsights } from './useInsights';
import styles from './Insights.module.scss';

const Insights = ({ initialData }) => {
  const {
    incursionsData,
    chartData: {
      acknowledgedIncursions,
      unacknowledgedIncursions,
      unacknowledgedPercentagePerProject,
      unacknowledgedPercentagePerSite,
    },
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    currentEntities,
    setCurrentEntities,
    period,
    setPeriod,
    dateFormat,
    projectOptions,
    siteOptions,
    entityOptions,
    projectsFromApi,
    sitesFromApi,
    entitiesFromApi,
    isLoading,
  } = useInsights(initialData);

  return (
    <div className={styles.insightPage}>
      {isLoading && <NavSpinner />}
      <Filters
        source="insights"
        period={period}
        setPeriod={setPeriod}
        dateFormat={dateFormat}
        projectOptions={projectOptions}
        siteOptions={siteOptions}
        entityOptions={entityOptions}
        currentProjects={currentProjects}
        setCurrentProjects={setCurrentProjects}
        currentSites={currentSites}
        setCurrentSites={setCurrentSites}
        currentEntities={currentEntities}
        setCurrentEntities={setCurrentEntities}
      />
      <div className="d-flex flex-wrap">
        <IncursionsChart
          acknowledgedIncursions={acknowledgedIncursions}
          unacknowledgedIncursions={unacknowledgedIncursions}
          period={period}
          dateFormat={dateFormat}
          wrapperClassName={`mb-3 ${styles.incursionChart}`}
        />
        <DeploymentInfoCard
          incursionsData={incursionsData}
          activeSitesCount={currentSites ? currentSites.length : siteOptions.length}
        />
      </div>
      <div className="d-flex flex-wrap">
        <IncursionsInfoCards incursionsData={incursionsData} />
        <ProjectsAndSitesChart
          projects={currentProjects || projectsFromApi}
          sites={currentSites || sitesFromApi}
          entities={currentEntities || entitiesFromApi}
          unacknowledgedPercentagePerProject={unacknowledgedPercentagePerProject}
          unacknowledgedPercentagePerSite={unacknowledgedPercentagePerSite}
        />
      </div>
    </div>
  );
};

export default Insights;

Insights.propTypes = {
  initialData: insightsFetchedDataType,
};
