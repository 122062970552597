import React from 'react';
import { string, arrayOf, number } from 'prop-types';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import { singleChartDataType } from '../../../API/hooks/useFetchInsights/types';
import { ChartWrapper } from '../ChartWrapper';
import colors from '../../../../assets/stylesheets/_colors.scss';
import { dateFormatType } from '../../../API/types';

export const MixedChart = ({
  periodName,
  dateFormat,
  stackedBarDataset1,
  stackedBarDataset2,
  lineData5,
  dataset1Label,
  dataset2Label,
  lineValuesLabel,
  chartTitle,
  wrapperClassName = '',
}) => {
  const getValues = (data) => {
    return data.map((v) => v.value);
  };
  const getTimestamps = (data) => {
    return data.map((v) => v.timestamp);
  };

  const barLabels = Array(stackedBarDataset1.length).fill('');
  const barTimestamps = getTimestamps(stackedBarDataset1);
  const stacked1Values = getValues(stackedBarDataset1);
  const stacked2Values = getValues(stackedBarDataset2);

  const mixedBarLineData = {
    labels: barLabels,
    datasets: [
      {
        type: 'line',
        data: lineData5,
        label: lineValuesLabel,
        lineTension: 0.2,
        pointRadius: 0,
        pointHitRadius: 10,
        backgroundColor: colors.black,
        borderColor: colors.black,
        fill: false,
      },
      {
        type: 'bar',
        label: dataset1Label,
        backgroundColor: colors.greenSuccess,
        hoverBackgroundColor: colors.greenSuccess,
        borderColor: colors.greenSuccess,
        borderWidth: 1,
        data: stacked1Values,
      },
      {
        type: 'bar',
        label: dataset2Label,
        backgroundColor: colors.gray2,
        hoverBackgroundColor: colors.gray2,
        borderColor: colors.gray2,
        borderWidth: 1,
        data: stacked2Values,
      },
    ],
  };

  const chartOptions = {
    title: {
      display: true,
      position: 'top',
      padding: 10,
      text: chartTitle,
      fontSize: 16,
      fontColor: '#000',
      fontStyle: 'normal',
    },
    legend: {
      display: true,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          position: 'bottom',
          scaleLabel: {
            labelString: `Time Period: ${periodName}`,
            display: true,
            padding: 0,
          },
          stacked: true,
        },
      ],
    },
    tooltips: {
      callbacks: {
        afterLabel: (tooltipItem) => {
          return `${moment(barTimestamps[tooltipItem.index]).format(dateFormat)}`;
        },
      },
    },
  };

  return (
    <ChartWrapper wrapperClassName={wrapperClassName}>
      <Bar
        data={mixedBarLineData}
        options={chartOptions}
        // height={250}
      />
    </ChartWrapper>
  );
};

MixedChart.propTypes = {
  periodName: string.isRequired,
  stackedBarDataset1: singleChartDataType.isRequired,
  stackedBarDataset2: singleChartDataType.isRequired,
  dateFormat: dateFormatType,
  lineData5: arrayOf(number).isRequired,
  dataset1Label: string.isRequired,
  dataset2Label: string.isRequired,
  lineValuesLabel: string.isRequired,
  chartTitle: string.isRequired,
  wrapperClassName: string,
};
