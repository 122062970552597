export const events = [
  'Zero',
  'Test password entered',
  'Dig Road mode ON',
  'Dig Rail mode ON',
  'Dig mode OFF',
  'Tandem mode ON',
  'Tandem mode OFF',
  'Soft Override ON',
  'Safety Override On',
  'Soft Override OFF',
  'Safety Override deactivated',
  'Lift point change confirmed',
  'LOH prevented lift point change',
  'Driver cancelled all limits with supervisors key on',
  'Slew limits cancelled',
  'Wall limits cancelled',
  'Height limit cancelled',
  'Height limit',
  'Slew limits',
  'Wall limits',
  'Turret in Stow Zone',
  'Turret out of Stow Zone',
  'Turret in Warning Zone',
  'Turret out of Warning Zone',
  'Rail Bogies Down Road Axle Now Locked',
  'PWS Drivers tag not acknowledged',
  'PWS Active',
  'PWS Drivers tag not detected',
  'PWS too many tags in zone at startup',
  'Change to Road Duty',
  'Change to Rail Duty',
  'All legs raised',
  'Not adjacent screen',
  'One leg screen',
  'Stabiliser footing lost screen',
  'All Stabiliser Legs Up',
  'Safety System Check Failed at Powerup',
  'Safety System Check Passed OK at Powerup',
  'Beyond set limits',
  'Within set limits',
  'Machine forced out of dig mode because duty changed',
  'Hook point changed to ALP1 by input',
  'Hook point changed to BP by input',
  'Axle Unlocking not protected',
  'Axle Unlocking is now protected',
  'Alarm audible',
  'Alarm muted',
  'Limit Audible muted',
  'Limit audible',
  'User closed system',
  'Not Used Advanced password entered',
  'Not Used System configured',
  'Overload',
  'Backwardly Unstable',
  'Checksum has failed',
  'Axle changed',
  'Service entered',
  'Slew Verification started',
  'Slew Limits Exceeded Reposition required',
  'Slew Verification failed',
  'Slew Verification failed Driver chose to repeat',
  'Slew Verification failed on repeat',
  'Slew Verification failed Driver chose NOT to repeat',
  'Slew Verification failed Driver has chosen NOT to confirm pass',
  'Slew Verification passed',
  'Slew Verification passed Driver confirmed pass',
  'Slew Verification passed Driver REJECTED pass',
  'Slew Verification Driver Deferred Verification',
  'Slew Verification Driver Cannot Defer Verification',
  'Stabilisers legs accepted',
  'Stabilisers legs rejected',
  'Safety Relay on at startup',
  'The version of Module 2 must be greater than 5 3',
  'Analog Inputs have been reset due to use of SigQuest sensors',
  'Normal dipper detected',
  'Alt dipper detected',
  'Jib attached',
  'Jib removed',
  'Safety Relay closed should be open',
  'Safety Relay open should be closed',
  'Slew Overspeed detected slew motions cut',
  'Limits disabled',
  'Not overloaded',
  'Machine Failure during Updates',
  'Remote Kill Switch Activated',
  'Tracks In Mismatch warning',
  'Counterweight warning',
  'Error with limit set screen shown',
  'Safety mismatch screen shown',
  'In gauge max speed exceeded',
  'Out of gauge travel speed exceeded',
  'Slew limit hit',
  'Height limit hit',
  'Wall limit hit',
  'Invalid lift point BP selected',
  'Track twist max CANT exceeded',
  'Track twist max Grad exceeded',
  'Max track twist exceeded',
];

export const errors = [
  'E0 Zero',
  'E1 Stabiliser footing lost Redeploy!',
  'E2 Offset boom not straight!',
  'E3 Slew not straight!',
  'E4 Axles not locked!',
  'E5 Counterweight mismatch!',
  'E6 Backwards stability!',
  'E7 Not used',
  'E8 Dipper mismatch!',
  'E9 Foreboom mismatch!',
  'E10 Preboom mismatch!',
  'E11 Height mismatch!',
  'E12 Radius mismatch!',
  'E13 Slew mismatch!',
  'E14 Safety mismatch!',
  'E15 Mismatched safety!',
  'E16 Safety Board lost!',
  'E17 Not used',
  'E18 Bad zigbee data!',
  'E19 Not used',
  'E20 Slew left into limit!',
  'E21 Slew right into limit!',
  'E22 Offset mismatch!',
  'E23 Not used',
  'E24 Not used',
  'E25 Not used',
  'E26 Not used',
  'E27 RS dual over pressure!',
  'E28 RS dual pressure mismatch!',
  'E29 RS dual pressure over 20mA!',
  'E30 RS dual press sensor below 4mA!',
  'E31 RS dual press sensor 0!',
  'E32 RS dual press sensor 1023!',
  'E33 RS over pressure!',
  'E34 RS 20mA press sensor over 20mA!',
  'E35 RS press sensor below 4mA!',
  'E36 RS press sensor 0!',
  'E37 RS dual press sensor 1023!',
  'E38 FS dual pressure over 20mA!',
  'E39 FS dual press sensor below 4mA!',
  'E40 FS dual press sensor 1023!',
  'E41 FS dual press sensor 0!',
  'E42 FS dual pressure mismatch!',
  'E43 FS dual low pressure!',
  'E44 FS dual over pressure!',
  'E45 FS 20mA press sensor over 20mA!',
  'E46 FS press sensor below 4mA!',
  'E47 FS press sensor 1023!',
  'E48 FS press sensor 0!',
  'E49 FS low pressure!',
  'E50 FS over pressure!',
  'E51 Not used',
  'E52 1st cwt reeler out of range!',
  'E53 Not used',
  'E54 2nd cwt reeler out of range!',
  'E55 Not used',
  'E56 Extn reeler out of range!',
  'E57 Boom Ang out of range!',
  'E58 Not used',
  'E59 Not used',
  'E60 MOD3 Op 9 to 16 fault!',
  'E61 MOD3 Op 1 to 8 fault!',
  'E62 MOD3 Op 9 to 16 connection!',
  'E63 MOD3 Op 1 to 8 connection!',
  'E64 Not used',
  'E65 MOD1 Op 9 to 16 fault!',
  'E66 MOD1 Op 1 to 8 fault!',
  'E67 MOD1 Op 9 to 16 no power!',
  'E68 MOD1 Op 9 to 16 connection!',
  'E69 MOD1 Op 1 to 8 no power!',
  'E70 MOD1 Op 1 to 8 connection!',
  'E71 MOD1 Comms!',
  'E72 MOD2 Comms!',
  'E73 MOD3 Comms!',
  'E74 Not used',
  'E75 MOD5 Comms!',
  'E76 MOD6 Comms!',
  'E77 MOD9 Comms!',
  'E78 MOD11 Comms!',
  'E79 MOD13 Comms!',
  'E80 alt jib 4 Comms!',
  'E81 alt jib 4 Errors!',
  'E82 Safety alt jib 8 Errors!',
  'E83 Safety dipper 7 Errors!',
  'E84 Safety fore 6 Errors!',
  'E85 Safety stub 5 Errors!',
  'E86 Offset Errors!',
  'E87 Dipper 3 Errors!',
  'E88 Fore 2 Errors!',
  'E89 Stub 1 Errors!',
  'E90 Encoder Sensor Comms!',
  'E91 RS Press Sensor Comms!',
  'E92 Safety offset Comms!',
  'E93 Safety dipper 7 Comms!',
  'E94 Safety fore 6 Comms!',
  'E95 Safety stub 5 Comms!',
  'E96 Offset Comms!',
  'E97 Dipper 3 Comms!',
  'E98 Fore 2 Comms!',
  'E99 Stub 1 Comms!',
  'E100 Stub 10 Comms!',
  'E101 Fore Sensor Comms!',
  'E102 Stub Sensor Comms!',
  'E103 Counterweight Sensor Comms!',
  'E104 alt jib Sensor Comms!',
  'E105 Unlock feedback mismatch!',
  'E106 PLC Comms lost x5!',
  'E107 Under Carriage Comms!',
  'E108 Display 68 needed!',
  'E109 UC dual CG mismatch!',
  'E110 UC dual CG safety comms!',
  'E111 No serial CAN comms!',
  'E112 No tandem comms!',
  'E113 No zigbee comms!',
  'E114 No safety comms!',
  'E115 CANT not rated!',
  'E116 Not used',
  'E117 MOD2 Op 9 to 16 fault!',
  'E118 MOD2 Op 1 to 8 fault!',
  'E119 MOD2 Op 9 to 16 no power!',
  'E120 MOD2 Op 9 to 16 connection!',
  'E121 MOD2 Op 1 to 8 no power!',
  'E122 MOD2 Op 1 to 8 connection!',
  'E123 Date too early!',
  'E124 Dipper Reeler Comms!',
  'E125 Jib Reeler Comms!',
  'E126 Extn switch out of range!',
  'E127 Dipper reeler out of range!',
  'E128 Dual swivel fault!',
  'E129 Attachment press sensor!',
  'E130 Attachment press sensor 0!',
  'E131 Attachment low pressure!',
  'E132 ZBoom Invalid inputs!',
  'E133 Jib reeler out of range!',
  'E134 System Failure during Updates!',
  'E135 Front Track Twist Cant comms error!',
  'E136 Rear Track Twist Cant comms error!',
  'E137 Not used',
  'E138 Front Track Twist Mismatch!',
  'E139 Rear Track Twist Mismatch!',
  'E140 Cant out of range!',
  'E141 Grad out of range!',
  'E142 Front Track Twist Cant range!',
  'E143 Rear Track Twist Cant range!',
  'E144 Speed Control Module Fault!',
  'E145 Slew Teeth Error!',
  'E146 Slew Teeth comms!',
  'E147 Encoder Sensor Error!',
  'E148 Safety Slew Teeth Error!',
  'E149 Safety Slew Teeth comms!',
  'E150 Front Track Twist Cant Error!',
  'E151 Rear Track Twist Cant Error!',
  'E152 Cant Grad Error!',
  'E153 Safety Cant Grad Error!',
  'E154 Cant Grad comms!',
  'E155 Dual Pressure comms!',
  'E156 Incorrect Tipping files!',
  'E157 Slew Verification must be run successfully!',
  'E158 Safety System not checked!',
  'E159 Remote Kill Switch Fault!',
  'E160 EH Controller Comms!',
  'E161 EH Controller Mismatch!',
  'E162 EH Controller Keyswitch!',
  'E163 EH Controller Tab Gains!',
  'E164 EH Controller Joystick Left!',
  'E165 EH Controller Joystick Right!',
  'E166 Joystick slew mismatch!',
  'E167 Driver tag not received!',
  'E168 Too many tags at startup!',
  'E169 Driver tag not acknowledged!',
  'E170 PWS Be Station timeout!',
  'E171 Bosch Comms!',
  'E172 Missing Dongle!',
  'E173 Alarm Unit Comms!',
  'E174 Beacon Unit Comms!',
];
