import React from 'react';
import { number, oneOfType, string } from 'prop-types';

import { EntityNotes } from 'atoms/index';
import { singleChartDataType } from 'API/hooks/useFetchAnalytics/types';
import { periodType } from 'atoms/DatePicker/types';
import { dateFormatType, notesType } from 'API/types';
import { incursionsDataType } from 'API/hooks/useFetchBaseStation/types';

import { SafetyInsightsCards } from './SafetyInsightsCards/SafetyInsightsCards';
import { MachineInfo } from './MachineInfo/MachineInfo';

export const ProximityWarnings = ({
  incursionsData,
  acknowledgedIncursions,
  unacknowledgedIncursions,
  period,
  dateFormat,
  baseStationId,
  initialNotes,
  userName,
}) => {
  return (
    <>
      <SafetyInsightsCards incursionsData={incursionsData} />
      <MachineInfo
        acknowledgedIncursions={acknowledgedIncursions}
        unacknowledgedIncursions={unacknowledgedIncursions}
        period={period}
        dateFormat={dateFormat}
        baseStationId={baseStationId}
      />
      <EntityNotes entityId={baseStationId} initialNotes={initialNotes} userName={userName} />
    </>
  );
};

ProximityWarnings.propTypes = {
  incursionsData: incursionsDataType,
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
  period: periodType,
  dateFormat: dateFormatType,
  baseStationId: oneOfType([string, number]),
  initialNotes: notesType,
  userName: string,
};
