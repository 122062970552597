import React from 'react';
import PropTypes from 'prop-types';
import { InfoCardBox } from '../InfoCardBox';

import styles from './Long.module.scss';

export const LongInfoCard = ({
  firstTitle,
  secondTitle,
  firstParagraph,
  secondParagraph,
  boxClassName = '',
  wrapperClassName = '',
}) => {
  return (
    <div className={wrapperClassName}>
      <InfoCardBox boxClassName={`${styles.box} ${boxClassName}`}>
        <div
          className={`d-flex flex-column ${
            secondTitle && secondParagraph ? 'mb-3' : ''
          }`}
        >
          <h3 className={styles.title}>{firstTitle}</h3>
          {firstParagraph.map(item => (
            <span
              key={`${item.activeItemsNr}-${item.text}`}
              className={styles.text}
            >
              <strong>{item.activeItemsNr} </strong>
              {item.text}
            </span>
          ))}
        </div>
        {secondTitle && secondParagraph && (
          <div className="d-flex flex-column">
            <h3 className={styles.title}>{secondTitle}</h3>
            {secondParagraph.map(item => (
              <div
                key={`${item.activeItemsNr}-${item.text}`}
                className="d-flex flex-column"
              >
                <span className={styles.text}>
                  <strong>{item.activeItemsNr} </strong>
                  {item.text}
                </span>
                <span>{`(${item.percentage}% of active fleet)`}</span>
              </div>
            ))}
          </div>
        )}
      </InfoCardBox>
    </div>
  );
};

LongInfoCard.propTypes = {
  firstTitle: PropTypes.string.isRequired,
  secondTitle: PropTypes.string,
  firstParagraph: PropTypes.arrayOf(
    PropTypes.shape({
      activeItemsNr: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
  secondParagraph: PropTypes.arrayOf(
    PropTypes.shape({
      activeItemsNr: PropTypes.number,
      text: PropTypes.string,
      percentage: PropTypes.number,
    }),
  ),
  boxClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};
