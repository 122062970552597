import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TurfBbox from '@turf/bbox';
import { point, featureCollection } from '@turf/helpers';
import { fitBounds } from 'google-map-react/utils';
import { isGeoJsonCircle } from 'utils';

export const DEFAULT_MAP_POSITION = {
  center: { lat: 47.422958, lng: -40.985718 },
  zoom: 2,
};

export const useCalculateCenterAndZoom = ({ mapChildren, mapRef }) => {
  const [output, setOutput] = useState(DEFAULT_MAP_POSITION);

  useEffect(() => {
    if (!mapChildren || mapChildren.length === 0) {
      return;
    }

    let dataArray = Array.isArray(mapChildren) ? mapChildren : [mapChildren];
    dataArray = dataArray.reduce((accumulator, current) => {
      let value =
        current.type.name === 'MapGeometry'
          ? getPointsPropsFromGeometry(current.props.geometry)
          : [current];
      if (current.props.editable) {
        value = value.map((value) => {
          return { props: { ...value.props, active: true } };
        });
      }

      return [...accumulator, ...value];
    }, []);

    function getPointsPropsFromGeometry(geometry) {
      if (isGeoJsonCircle(geometry)) {
        const [lng, lat] = geometry.geometry.coordinates;
        return [{ props: { lat, lng } }];
      }

      if (geometry.coordinates.every((c) => typeof c === 'number')) {
        const [lng, lat] = geometry.coordinates;
        return [{ props: { lat, lng } }];
      }

      return geometry.coordinates[0].map((coord) => {
        const [lng, lat] = coord;
        return { props: { lat, lng } };
      });
    }

    const activeMarkers = dataArray.filter((marker) => !!marker.props.active);
    const markersToZoom = activeMarkers.length ? activeMarkers : dataArray;
    const allMarkersHaveSameCoordinates = () => {
      const latSet = new Set();
      const lngSet = new Set();
      markersToZoom.forEach((marker) => {
        latSet.add(marker.props.lat);
        lngSet.add(marker.props.lng);
      });

      return latSet.size === 1 || lngSet.size === 1;
    };

    if (markersToZoom.length === 1 || allMarkersHaveSameCoordinates()) {
      setOutput({
        zoom: 13,
        center: {
          lat: markersToZoom[0].props.lat,
          lng: markersToZoom[0].props.lng,
        },
      });
      return;
    }

    const points = markersToZoom.map(({ props: { lat, lng } }) => point([lng, lat]));
    const bbox = points |> featureCollection |> TurfBbox;

    const bounds = {
      sw: {
        lng: bbox[0],
        lat: bbox[1],
      },
      ne: {
        lng: bbox[2],
        lat: bbox[3],
      },
    };

    const size = {
      width: mapRef.current.offsetWidth,
      height: mapRef.current.offsetHeight,
    };

    setOutput(fitBounds(bounds, size));
  }, [mapChildren, mapRef]);

  return output;
};

useCalculateCenterAndZoom.propTypes = {
  mapChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  mapRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
