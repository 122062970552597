import React from 'react';
import { node } from 'prop-types';

import styles from './Overlay.module.scss';

export const Overlay = ({ children }) => {
  return (
    <div id="overlay" className={styles.overlay}>
      {children}
    </div>
  );
};

Overlay.propTypes = {
  children: node,
};
