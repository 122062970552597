import axios from 'axios';

export const createNote = (entityId, { author, text, date }) =>
  axios.post(`${process.env.API_URL}/entities/${entityId}/notes`, {
    author,
    text,
    date,
  });

export const updateNote = (entityId, { noteId, author, text }) =>
  axios.put(`${process.env.API_URL}/entities/${entityId}/notes/${noteId}`, {
    id: noteId,
    author,
    text,
  });

export const deleteNote = (entityId, noteId) =>
  axios.delete(`${process.env.API_URL}/entities/${entityId}/notes/${noteId}`);
