import React, { useCallback, useMemo } from 'react';
import { BasicInfoCard, ListInfoCardWrapper } from 'atoms';
import { incursionsType } from '../../../API/hooks/useFetchInsights/types';
import { TopEntitiesList } from './TopEntitiesList';

import styles from './IncursionsInfoCards.module.scss';

export const IncursionsInfoCards = ({ incursionsData }) => {
  const {
    totalIncursionsCount,
    acknowledgedIncursionsCount,
    unacknowledgedIncursionsCount,
    topVehiclesIds,
    topTagsIds,
  } = incursionsData;

  const getSorted = useCallback(array => {
    return array.sort((a, b) => b.incursion - a.incursion);
  }, []);

  const sortedVehicles = useMemo(() => getSorted(topVehiclesIds), [
    getSorted,
    topVehiclesIds,
  ]);

  const sortedTags = useMemo(() => getSorted(topTagsIds), [
    getSorted,
    topTagsIds,
  ]);

  return (
    <div className={`mb-3 ${styles.cardsInfoWrapper}`}>
      <BasicInfoCard
        title="Total incursions"
        count={totalIncursionsCount}
        percentageChange={
          incursionsData.totalIncursionsPercentageChange || null
        }
        hintText="For the given time period: The total number of times a person was detected in a hazard zone."
      />
      <BasicInfoCard
        title="Acknowledged incursions"
        count={acknowledgedIncursionsCount}
        percentageChange={
          incursionsData.acknowledgedIncursionsPercentageChange || null
        }
        reverseColors
        hintText="For the time period, the total number of times an operator acknowledged a person entering the hazard zone within 60 seconds."
      />
      <BasicInfoCard
        title="Unacknowledged incursions"
        count={unacknowledgedIncursionsCount}
        percentageChange={
          incursionsData.unacknowledgedIncursionsPercentageChange || null
        }
        hintText="For the time period, the total number of times an operator did not acknowledged a person entering a hazard zone within 60 seconds. Incursions of 3 seconds or less are not counted."
      />
      <BasicInfoCard
        title="Percentage of incursions acknowledged within 10 seconds"
        count={incursionsData.acknowledgedPercentage || 0}
        countUnit="%"
        percentageChange={incursionsData.acknowledgedPercentageChange || null}
        reverseColors
      />
      <ListInfoCardWrapper title="Top 5 Vehicles" subtitle="Most Incursions">
        <TopEntitiesList list={sortedVehicles} kind="machines" />
      </ListInfoCardWrapper>
      <ListInfoCardWrapper title="Top 5 Tags" subtitle="Most Incursions">
        <TopEntitiesList list={sortedTags} kind="tags" />
      </ListInfoCardWrapper>
    </div>
  );
};

IncursionsInfoCards.propTypes = {
  incursionsData: incursionsType,
};
