import React from 'react';
import PropTypes from 'prop-types';

import useUpload from './useUpload';
import styles from './Uploader.module.scss';

const DEFAULT_RESTRICTIONS = {
  maxFileSize: 2097152,
  allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.pdf'],
};

export const Uploader = ({
  className,
  showIcon = true,
  text = 'Upload',
  uploader,
  restrictions,
  handleSave,
  onError,
  onFinish,
}) => {
  const { progress, handleInputChange } = useUpload({
    uploader,
    restrictions: restrictions ? restrictions : DEFAULT_RESTRICTIONS,
    handleSave,
    onError,
    onFinish,
  });

  const renderButtonText = () => {
    if (progress == 0) {
      return text;
    } else if (progress < 100) {
      return `${Math.round(progress)}%`;
    } else {
      return 'Done!';
    }
  };

  return (
    <label
      className={`${styles.uploaderLabel} ${className}`}
      htmlFor={progress > 0 ? '' : uploader.inputId}
    >
      <input
        id={uploader.inputId}
        type="file"
        hidden
        name={`${uploader.objectName}[${uploader.attributeName}]`}
        onChange={handleInputChange}
      />
      {showIcon && <i className="fas fa-upload" />}
      {renderButtonText()}
    </label>
  );
};

export const uploaderType = PropTypes.shape({
  inputId: PropTypes.string.isRequired,
  objectName: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  directUploadPath: PropTypes.string.isRequired,
  saveUploadPath: PropTypes.string,
});

Uploader.propTypes = {
  className: PropTypes.string,
  uploader: uploaderType,
  showIcon: PropTypes.bool,
  text: PropTypes.string,
  restrictions: PropTypes.object,
  handleSave: PropTypes.func,
  onError: PropTypes.func,
  onFinish: PropTypes.func,
};

Uploader.defaultProps = {
  className: 'btn btn-outline-secondary px-4',
};

export default Uploader;
