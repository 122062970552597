import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { string, func, shape, oneOfType } from 'prop-types';

import { tagSchema, machineSchema } from '../../../../../API/hooks/useFetchEntities/types';
import { LoadingSpinner } from '../../../../../atoms';
import styles from '../TableSection.module.scss';

export const ArchiveOrUnarchiveEntityCell = ({
  value: apiPath,
  revalidateEntities,
  row: { original: entity },
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const CONFIRMATION_MESSAGE = `The machine will be ${
    entity.archived ? 'unarchived' : 'archived'
  }. Are you sure?`;

  const handleClick = useCallback(async () => {
    if (window.confirm(CONFIRMATION_MESSAGE) && apiPath) {
      setIsLoading(true);
      try {
        await axios.put(apiPath);
        await revalidateEntities();
      } catch (error) {
        console.error(error);
        alert('Error when saving data!');
      } finally {
        setIsLoading(false);
      }
    }
  }, [CONFIRMATION_MESSAGE, apiPath]);

  return (
    <>
      {apiPath && (
        <div className={styles.iconCell} style={{ marginRight: '10px' }}>
          {isLoading ? (
            <LoadingSpinner size={{ height: 20, width: 20 }} />
          ) : (
            <button
              type="button"
              className={`d-flex justify-content-center align-items-center btn btn-dark ${styles.iconWrapper}`}
              onClick={handleClick}
            >
              {entity.archived ? (
                <i className={`fa-solid fa-box-open ${styles.icon}`} />
              ) : (
                <i className={`fa-light fa-box-archive ${styles.icon}`} />
              )}
            </button>
          )}
        </div>
      )}
    </>
  );
};

ArchiveOrUnarchiveEntityCell.propTypes = {
  value: string,
  revalidateEntities: func,
  row: shape({
    original: oneOfType([tagSchema, machineSchema]),
  }),
};
