import { node, string } from 'prop-types';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({ idName, children }) => {
  const portal = useRef(document.createElement('div'));

  useEffect(() => {
    if (portal.current) {
      portal.current.id = idName;
      document.body.appendChild(portal.current);
    }

    return () => document.body.removeChild(portal.current);
  }, [idName]);

  return createPortal(children, portal.current);
};

Portal.propTypes = {
  idName: string,
  children: node,
};
