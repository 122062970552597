import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Table, Empty } from 'atoms';
import { ColorCell } from './ColorCell';
import { ProjectCell } from './ProjectCell';
import { TypeCell } from './TypeCell';
import styles from './worksitesTable.module.scss';

const EditButton = ({ zoneId, ...rest }) => {
  return (
    <div role="button" className={styles.centeredCell} {...rest}>
      <i className="fas fa-pencil-alt"></i>
    </div>
  );
};
EditButton.propTypes = {
  zoneId: PropTypes.number,
};

const WorksitesTable = ({ data, onEdit }) => {
  const EditButtonCell = props => (
    <EditButton
      zoneId={props.row.values.id}
      onClick={() => onEdit(props.row.original.id)}
    />
  );
  EditButtonCell.propTypes = ColorCell.propTypes;

  const columns = useMemo(
    () => [
      {
        Header: 'Colour Tag',
        accessor: 'color',
        Cell: ColorCell,
      },
      {
        Header: 'Site Name',
        accessor: 'name',
      },
      {
        Header: 'Project',
        accessor: 'projects',
        Cell: ProjectCell,
      },
      {
        Header: 'Type',
        accessor: 'siteType',
        Cell: TypeCell,
      },
      {
        Header: () => null,
        id: 'edit',
        Cell: EditButtonCell,
      },
    ],
    [],
  );

  // @TODO use TableSZ instead and remove old Table component
  return (
    <Table
      {...{
        columns,
        data,
        className: styles.worksitesTable,
        emptyComponent: <Empty> No Sites added yet </Empty>,
      }}
    />
  );
};

WorksitesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
};

export default WorksitesTable;
