import React from 'react';

import { videoType } from 'API/hooks/useFetchVideos/types';

import styles from './VideoError.module.scss';

export const VideoError = ({ video }) => {
  return (
    <div className="d-flex flex-column">
      <p className="m-0">
        Status: <strong className={styles.status}>{video.state}</strong>
      </p>
      <p className="m-0">
        Message: {video.statusMessage === 'dvr_not_online' ? 'DVR offline' : 'Request error'}
      </p>
    </div>
  );
};

VideoError.propTypes = {
  video: videoType,
};
