import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { InfoCardBox } from '../InfoCardBox';
import { DefaultView } from './DefaultView';
import { HintView } from './HintView';
import styles from './Basic.module.scss';

export const BasicInfoCard = ({
  title,
  count,
  percentageChange,
  countUnit = '',
  boxClassName = '',
  reverseColors = false,
  hintText,
}) => {
  const [hintVisible, setHintVisible] = useState(false);

  const handleIconInfoClick = useCallback(() => {
    setHintVisible(true);
  }, []);

  const handleIconXClick = useCallback(() => {
    setHintVisible(false);
  }, []);

  return (
    <InfoCardBox
      boxClassName={`${boxClassName} ${
        hintVisible ? styles.hintBox : `text-center ${styles.defaultBox}`
      }`}
    >
      {hintVisible ? (
        <HintView
          title={title}
          hintText={hintText}
          handleIconXClick={handleIconXClick}
        />
      ) : (
        <DefaultView
          title={title}
          count={count}
          percentageChange={percentageChange}
          countUnit={countUnit}
          reverseColors={reverseColors}
          hintText={hintText}
          handleIconInfoClick={handleIconInfoClick}
        />
      )}
    </InfoCardBox>
  );
};

BasicInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentageChange: PropTypes.number,
  countUnit: PropTypes.string,
  boxClassName: PropTypes.string,
  reverseColors: PropTypes.bool,
  hintText: PropTypes.string,
};
