import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'atoms';

const ConfirmationModal = ({ text, onCancel, onConfirm, ...rest }) => {
  const footer = (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-dismiss="modal"
        onClick={onCancel}
      >
        Cancel
      </button>
      <button type="button" className="btn btn-danger" onClick={onConfirm}>
        Delete
      </button>
    </>
  );

  return (
    <Modal {...rest} footer={footer} title="Confirmation">
      <h6 style={{ width: 300 }}>{text}</h6>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  text: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmationModal;
