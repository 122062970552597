import { useRef, useEffect, useState, useMemo } from 'react';

export const useMapWrapper = () => {
  const [mapPosition, setMapPosition] = useState({ top: 0 });

  const wrapperRef = useRef(null);

  const mainNavHeight = useMemo(() => {
    const mainNav = document.getElementById('mainNav');
    if (mainNav) {
      const { height } = mainNav.getBoundingClientRect();
      return height;
    }
    // hardcoded value
    return 69;
  }, []);

  useEffect(() => {
    const getTop = () => {
      if (wrapperRef && wrapperRef.current) {
        const { top } = wrapperRef.current.getBoundingClientRect();
        setMapPosition({ top });
      }
    };
    getTop();

    window.addEventListener('resize', getTop);
    return () => window.removeEventListener('resize', getTop);
  }, [wrapperRef]);

  return { wrapperRef, mapPosition, mainNavHeight };
};
