import { useState } from 'react';

import moment from 'moment';
import { useGetMultiselectOptions, useDebounce } from '../../hooks';
import { useFetchAnalytics } from '../../API/hooks';
import { initialAnalyticsOptions } from './Filters';

export const useAnalytics = ({ analytics: initialDataFromProps }) => {
  const [period, setPeriod] = useState({
    startDate: moment(initialDataFromProps.period[0]),
    endDate: moment(initialDataFromProps.period[1]),
  });
  const [currentUsers, setCurrentUsers] = useState(null);
  const [currentBrands, setCurrentBrands] = useState(null);
  const [currentAnalytics, setCurrentAnalytics] = useState(initialAnalyticsOptions[0]);

  const debouncedUsers = useDebounce(currentUsers, 750);
  const debouncedBrands = useDebounce(currentBrands, 750);

  const {
    data: { users: usersFromApi, brands: brandsFromApi, chartData, dateFormat },
    msgToDisplay,
  } = useFetchAnalytics({
    initialData: initialDataFromProps,
    shouldFetch: true,
    currentUsers: debouncedUsers,
    currentBrands: debouncedBrands,
    period,
  });

  const userOptions = useGetMultiselectOptions(usersFromApi);
  const brandOptions = useGetMultiselectOptions(brandsFromApi);

  return {
    chartData,
    period,
    setPeriod,
    currentAnalytics,
    setCurrentAnalytics,
    currentUsers,
    setCurrentUsers,
    userOptions,
    currentBrands,
    setCurrentBrands,
    brandOptions,
    dateFormat,
    msgToDisplay,
    usersFromApi,
    brandsFromApi,
  };
};
