import React, { useRef } from 'react';
import PropTypes, { arrayOf, shape, number, oneOf, bool } from 'prop-types';
import GoogleMapReact from 'google-map-react';

import { DRAWING_MODES, drawingModeType } from '../../components/Worksites/types';
import { useCalculateCenterAndZoom, DEFAULT_MAP_POSITION } from './hooks/useCalculateCenterAndZoom';
import { useMap } from './hooks/useMap';
import { MapGeometry, mapGeometryFactory } from './MapGeometry/MapGeometry';
import { InsightsMapMarker } from './MapMarkers/InsightsMapMarker';
import { DevicesMapMarker } from './MapMarkers/DevicesMapMarker';
import { ContextualMapMarker } from './MapMarkers/ContextualMapMarker';
import Selector from './Selector';
import silverStyle from './data/styles.json';
import { SearchBox } from './SearchBox/SearchBox';

import styles from './Map.module.scss';

export const Map = ({
  children,
  connectPoints,
  drawingMode = DRAWING_MODES.NONE,
  onDrawingComplete = () => {},
  onChange,
  heatmapData,
  mapStyle = '',
  searchBoxEnabled = false,
}) => {
  const mapRef = useRef();
  const { center, zoom } = useCalculateCenterAndZoom({
    mapChildren: children,
    mapRef,
  });
  const { onGoogleApiLoaded, googleMapInstance } = useMap({
    children,
    connectPoints,
    drawingMode,
    onDrawingComplete,
    onChange,
  });

  const createMapOptions = () => ({
    fullscreenControl: false,
    streetViewControl: true,
    mapTypeControl: true,
    // Default satellite view
    // mapTypeId: 'satellite',
    styles: mapStyle === 'silver' ? silverStyle : [],
  });

  return (
    <div ref={mapRef} className={styles.map}>
      <GoogleMapReact
        options={createMapOptions}
        defaultCenter={DEFAULT_MAP_POSITION.center}
        defaultZoom={DEFAULT_MAP_POSITION.zoom}
        {...{ center, zoom }}
        bootstrapURLKeys={{
          key: process.env.GOOGLE_MAPS_KEY,
          libraries: ['drawing', 'visualization', 'places'],
        }}
        drawingLibrary
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        heatmap={heatmapData}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { googleMapInstance }, null);
        })}
      </GoogleMapReact>
      {searchBoxEnabled && googleMapInstance && (
        <div className={styles.searchBoxWrapper}>
          <SearchBox
            map={googleMapInstance.map}
            mapsapi={googleMapInstance.maps}
            placeholder="Search..."
          />
        </div>
      )}
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.array,
  connectPoints: PropTypes.bool,
  drawingMode: drawingModeType,
  onDrawingComplete: PropTypes.func,
  onChange: PropTypes.func,
  heatmapData: shape({
    positions: arrayOf(
      shape({
        lat: number,
        lng: number,
      }),
    ),
    options: shape({
      radius: number,
      maxIntensity: number,
    }),
  }),
  mapStyle: oneOf(['silver', '']),
  searchBoxEnabled: bool,
};

Map.InsightsMapMarker = InsightsMapMarker;
Map.DevicesMapMarker = DevicesMapMarker;
Map.ContextualMapMarker = ContextualMapMarker;

Map.Geometry = MapGeometry;
Map.geometryFactory = mapGeometryFactory;

Map.Selector = Selector;
