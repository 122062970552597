import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { func } from 'prop-types';

import { periodType } from '../atoms/DatePicker/types';

export const useDateRange = ({ period, setPeriod }) => {
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    if (dateRange) {
      let periodFrom = period.startDate;

      switch (dateRange.label) {
        case 'Year to Date':
          periodFrom = moment().startOf('year');
          break;
        case 'Month to Date':
          periodFrom = moment().startOf('month');
          break;
        case 'Quarter to Date':
          periodFrom = moment().startOf('quarter');
          break;
        case 'Last 30 Days':
          periodFrom = moment().subtract(30, 'days').startOf('day');
          break;
        case 'Last 7 Days':
        default:
          periodFrom = moment().subtract(7, 'days').startOf('day');
      }

      const periodTo = moment();

      setPeriod({ startDate: periodFrom, endDate: periodTo });
    }
  }, [dateRange]);

  const resetDateRange = useCallback(() => {
    setDateRange(null);
  }, []);

  return { dateRange, setDateRange, resetDateRange };
};

useDateRange.propTypes = {
  period: periodType,
  setPeriod: func,
};
