import React from 'react';
import { func } from 'prop-types';
import moment from 'moment';

import { periodType } from '../../types';
import { useDateRange } from '../../../../hooks';
import { DateRange } from '../../../DateRange';
import { dateFormatType } from '../../../../API/types';
import { RangeDatePicker } from '../../DatePicker/RangeDatePicker/RangeDatePicker';
import { RangeTimePicker } from '../../TimePicker/RangeTimePicker/RangeTimePicker';
import styles from '../DateAndTimePicker.module.scss';

export const RangeDateAndTimePicker = ({ period, setPeriod, dateFormat }) => {
  const { dateRange, setDateRange, resetDateRange } = useDateRange({
    period,
    setPeriod,
  });

  return (
    <div className={styles.wrapper}>
      <DateRange dateRange={dateRange} setDateRange={setDateRange} />
      <RangeDatePicker period={period} setPeriod={setPeriod} resetDateRange={resetDateRange} />
      <div className={styles.timePickerWrapper}>
        <RangeTimePicker
          period={period}
          setPeriod={setPeriod}
          periodOption="startDate"
          label="Start time"
          resetDateRange={resetDateRange}
        />
        <RangeTimePicker
          period={period}
          setPeriod={setPeriod}
          periodOption="endDate"
          label="End time"
          resetDateRange={resetDateRange}
        />
      </div>
      <div>
        <h2 className={styles.title}>Selected period</h2>
        <div className={styles.selectedPeriodWrapper}>
          <strong>Starts</strong>
          <p className="mb-0">{moment(period.startDate).format(`${dateFormat} hh:mmA`)}</p>
        </div>
        <div className={styles.selectedPeriodWrapper}>
          <strong>Ends</strong>
          <p className="mb-0">{moment(period.endDate).format(`${dateFormat} hh:mmA`)}</p>
        </div>
      </div>
      <button
        type="button"
        className={`btn ${styles.button}`}
        onClick={() => setDateRange({ value: 3, label: 'Last 7 Days' })}
      >
        Reset period to default
      </button>
    </div>
  );
};

RangeDateAndTimePicker.propTypes = {
  period: periodType.isRequired,
  setPeriod: func.isRequired,
  dateFormat: dateFormatType.isRequired,
};
