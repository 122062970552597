import React, { useCallback } from 'react';
import { bool } from 'prop-types';

import styles from '../Videos.module.scss';
import { videosType } from 'API/hooks/useFetchVideos/types';
import { LoadingSpinner } from 'atoms/index';
import { dateFormatType } from 'API/types';

import { VideoClip } from './VideoClip/VideoClip';
import localStyles from './VideosHistory.module.scss';

export const VideosHistory = ({ videos, isHistoricVideosLoading, dateFormat }) => {
  const renderData = useCallback(() => {
    if (isHistoricVideosLoading) return <LoadingSpinner size={{ height: 24, width: 24 }} />;

    if (videos && videos.length > 0) {
      return (
        <ul className={`${localStyles.list} ${localStyles.listWithElements}`}>
          {videos.map((video) => (
            <li key={video.id}>
              <VideoClip video={video} dateFormat={dateFormat} />
            </li>
          ))}
        </ul>
      );
    }

    return <div className={localStyles.noData}>Clips will appear here once requested...</div>;
  }, [isHistoricVideosLoading, videos]);

  return (
    <div className={`card ${styles.wrapper}`}>
      <h4 className={`mb-3 ${localStyles.title}`}>Historic Clips</h4>
      {renderData()}
    </div>
  );
};

VideosHistory.propTypes = {
  videos: videosType,
  isHistoricVideosLoading: bool,
  dateFormat: dateFormatType,
};
