import { useState, useEffect } from 'react';
import { useDebounce, useControlledInput } from '../../hooks';

export function useWorksites({ worksites }) {
  const [tableData, setTableData] = useState(worksites);
  const [mapData, setMapData] = useState(worksites);

  const { inputValue: searchFilter, changeHandler: filterWorksites } = useControlledInput('');

  const INPUT_FILTER_DELAY_IN_MS = 500;
  const debouncedSearchTerm = useDebounce(searchFilter, INPUT_FILTER_DELAY_IN_MS);

  function isNameMatched(worksite) {
    if (!worksite.name) {
      return false;
    }
    return worksite.name.toUpperCase().includes(debouncedSearchTerm.toUpperCase());
  }

  useEffect(() => {
    const filteredData = searchFilter
      ? worksites.filter((worksite) => isNameMatched(worksite))
      : worksites;
    setTableData(filteredData);
    setMapData(filteredData);
  }, [debouncedSearchTerm, worksites]);

  return {
    tableData,
    mapData,
    searchFilter,
    filterWorksites,
  };
}
