import { useMemo } from 'react';
import useSWR from 'swr';

import { errorMessage } from '../../utils';
import { getPath } from './getPath';
import { useShowMessage } from '../../../hooks';

export const useFetchInsights = ({
  initialData,
  shouldFetch,
  currentProjects,
  currentSites,
  currentEntities,
  period,
}) => {
  const { msgToDisplay, setMsgToDisplay } = useShowMessage();

  const path = useMemo(
    () =>
      getPath({
        target: 'insights',
        period,
        currentProjects,
        currentSites,
        currentEntities,
      }),
    [period, currentProjects, currentSites, currentEntities],
  );

  const { data, isLoading } = useSWR(shouldFetch ? `${process.env.API_URL}${path}` : null, {
    fallbackData: initialData,
    errorRetryCount: 3,
    onError: (err) => {
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      });
    },
    onErrorRetry: (err) =>
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      }),
    onSuccess: () => {
      setMsgToDisplay({ msg: '', className: '' });
    },
    revalidateOnMount: true,
    keepPreviousData: true,
  });

  return { data, msgToDisplay, isLoading };
};
