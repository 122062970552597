import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import { Uploader } from 'atoms';

export default class UploaderFormWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filename: this.props.filename,
      display: 'none',
      signedId: this.props.signedId || '',
      markedForDestruction: 0,
      attachmentId: this.props.attachmentId,
      error: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.onError = this.onError.bind(this);
    this.markForDestruction = this.markForDestruction.bind(this);
  }

  componentDidMount() {
    if (this.props.filename !== '') {
      this.setState({ display: 'inline-block' });
    }
  }

  markForDestruction() {
    this.setState({
      markedForDestruction: 1,
      display: 'none',
      filename: '',
      signedId: '',
      error: false,
    });
  }

  handleSave = (file, resp) => {
    return new Promise((resolve, reject) => {
      this.setState({
        filename: resp.filename,
        signedId: resp.signed_id,
        markedForDestruction: 0,
        display: 'inline-block',
      });
      resolve(resp);
    });
  };

  onError = resp => {
    this.setState({ error: resp });
  };

  renderForm() {
    var isMarked = this.state.markedForDestruction;
    var isSigned = this.state.signedId;

    if (isSigned === '' && isMarked === 0) {
      return <div />;
    } else if (isMarked === 1) {
      return (
        <div>
          <input
            value={this.state.markedForDestruction}
            className="hidden"
            name={`${this.props.uploader.objectName}[${this.props.uploader.attributeName}_attachment_attributes][_destroy]`}
            id={`${this.props.uploader.objectName}_${this.props.uploader.attributeName}_attachment_attributes___destroy`}
            readOnly
          />
          <input
            value={this.state.attachmentId}
            className="hidden"
            name={`${this.props.uploader.objectName}[${this.props.uploader.attributeName}_attachment_attributes][id]`}
            id={`${this.props.uploader.objectName}_${this.props.uploader.attributeName}_attachment_attributes_id`}
            readOnly
          />
        </div>
      );
    } else {
      return (
        <input
          value={this.state.signedId}
          className="hidden"
          type="hidden"
          name={`${this.props.uploader.objectName}[${this.props.uploader.attributeName}]`}
          id={`${this.props.uploader.objectName}_${this.props.uploader.attributeName}`}
        />
      );
    }
  }

  truncateFilename = n => {
    var ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
    var filename = n.replace('.' + ext, '');
    if (filename.length <= 14) {
      return n;
    }
    filename = filename.substr(0, 14) + (n.length > 14 ? '[...]' : '');
    return filename + '.' + ext;
  };

  render() {
    var restrictions = {
      maxFileSize: 1000000,
      allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
    };
    return (
      <div className="form-control form-control-upload">
        <div className="row">
          <div className="col-md-5">
            <Uploader
              uploader={this.props.uploader}
              restrictions={restrictions}
              attributeName="attribute"
              handleSave={this.handleSave}
              onError={this.onError}
            />
          </div>
          <div className="col-md-7 align-self-center">
            <div
              className="form-control-upload__filename float-left"
              style={{ display: this.state.display }}
            >
              {this.truncateFilename(this.state.filename)}
              <i
                className="fa fa-times ml-3"
                onClick={this.markForDestruction}
              ></i>
            </div>
            {this.renderForm()}
          </div>
        </div>
        <div
          className={classnames('alert alert-danger small d-none mt-4', {
            'd-block': this.state.error != false,
          })}
        >
          {this.state.error}
        </div>
      </div>
    );
  }
}
