import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import { entitiesListType } from '../../../../../API/hooks/useFetchEntities/types';
import styles from './IdCell.module.scss';
import cellStyles from '../TableSection.module.scss';

export const IdCell = ({ value, data }) => {
  const currentRow = data.find((row) => row.id === String(value));
  if (currentRow) {
    if (currentRow.type === 'Tag') {
      return (
        <div className={cellStyles.cell}>
          <i className="fas fa-user-hard-hat mr-3" />
          {String(value)}
        </div>
      );
    }
    if (currentRow.type === 'Machine') {
      return (
        <>
          <i className="fas fa-steering-wheel mr-3" />
          {String(value)}
        </>
      );
    }
    return (
      <>
        <i className={`fa-solid fa-shield-check ${styles.shieldIcon}`} />
        {String(value)}
      </>
    );
  }
  return <>{String(value)}</>;
};

IdCell.propTypes = {
  value: oneOfType([string, number]),
  data: entitiesListType,
};
