import { shape, number, string, bool, arrayOf, instanceOf, oneOfType } from 'prop-types';
import { projectType, worksiteType, fetchedPeriodType, dateFormatType } from '../../types';

export const locationType = shape({
  lat: number,
  lng: number,
});

const incursionsType = arrayOf(
  shape({
    id: oneOfType([string, number]),
    timestamp: oneOfType([string, instanceOf(Date)]),
    ackTimestamp: oneOfType([string, instanceOf(Date)]),
    leavingTimestamp: oneOfType([string, instanceOf(Date)]),
    location: locationType,
    tagId: oneOfType([string, number]),
    baseStationId: oneOfType([string, number]),
    brandId: oneOfType([string, number]),
    worksiteId: oneOfType([string, number]),
    testing: bool,
    driver: bool,
  }),
);

export const heatmapFetchedDataType = shape({
  projects: arrayOf(projectType),
  sites: arrayOf(worksiteType),
  period: fetchedPeriodType,
  incursions: incursionsType,
  dateFormat: dateFormatType,
});
