import React from 'react';

import { MapWithLayers, NavSpinner } from 'atoms';
import { Header } from 'atoms/TagAndBaseStation';
import { baseStationFetchedDataType } from 'API/hooks/useFetchBaseStation/types';

import { useBaseStation } from './useBaseStation';
import styles from './BaseStation.module.scss';
import { MachineDetails } from './MachineDetails/MachineDetails';

const BaseStation = ({ initialData }) => {
  const {
    baseStationInfo,
    heatmapData,
    worksitesData,
    incursionsData,
    chartData: { acknowledgedIncursions, unacknowledgedIncursions },
    locationsData,
    notes,
    baseStationData,
    dateFormat,
    isLoading,
    period,
    setPeriod,
  } = useBaseStation(initialData);

  return (
    <div className={styles.page}>
      {isLoading && <NavSpinner />}
      <Header
        data={baseStationInfo}
        dateFormat={dateFormat}
        period={period}
        setPeriod={setPeriod}
      />
      <MapWithLayers
        heatmapData={heatmapData}
        worksitesData={worksitesData}
        locationsData={locationsData}
        switchers={{ sites: true, heatmap: true, locationHistory: true }}
        defaultLayers={{ heatmap: false }}
        wrapperClassName={styles.map}
        dateFormat={dateFormat}
      />
      <section className={styles.infoSection}>
        <MachineDetails
          incursionsData={incursionsData}
          acknowledgedIncursions={acknowledgedIncursions}
          unacknowledgedIncursions={unacknowledgedIncursions}
          period={period}
          dateFormat={dateFormat}
          baseStationId={baseStationData.id}
          dvrId={baseStationData.dvrId}
          online={baseStationData.online}
          initialNotes={notes}
          userName={initialData.userName}
        />
      </section>
    </div>
  );
};

export default BaseStation;

BaseStation.propTypes = {
  initialData: baseStationFetchedDataType,
};
