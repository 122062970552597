import { useState } from 'react';
import { deleteProject, addProject } from '../../../API/mutations';
import { useFetchProjects } from '../../../API/hooks';
import { useGetMultiselectOptions } from '../../../hooks';

const CONFIRMATION_MSG = 'Are you sure you want to permanently delete this project?';

export const useWorksiteEditor = (initialAllProjects) => {
  const {
    data: projectsFromApi,
    mutate,
    msgToDisplay: fetchProjectsErrorMsg,
  } = useFetchProjects({
    initialData: initialAllProjects,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [mutationError, setMutationError] = useState('');

  const addProjectOption = async (inputValue) => {
    try {
      setMutationError('');
      setIsLoading(true);
      await addProject(inputValue);
      await mutate();
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setMutationError(`Failed to create project. Error: ${error.message}`);
      setIsLoading(false);
    }
  };

  const deleteProjectOption = async (id) => {
    if (window.confirm(CONFIRMATION_MSG)) {
      try {
        setMutationError('');
        setIsLoading(true);
        await deleteProject(id);
        await mutate();
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
        setMutationError(`Failed to delete project. Error: ${error.message}`);
        setIsLoading(false);
      }
    }
  };

  const projectOptions = useGetMultiselectOptions(projectsFromApi);

  return {
    projectOptions,
    isLoading,
    deleteProjectOption,
    addProjectOption,
    fetchProjectsErrorMsg,
    mutationError,
  };
};
