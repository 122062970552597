import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'length',
    'lowerUpper',
    'numberSymbol',
    'nameEmail',
    'firstName',
    'lastName',
    'email',
    'icon',
  ];

  handleChange(e) {
    const value = e.target.value;

    if (value.length >= 8) {
      this.lengthTarget.classList.add('valid');
      this.lengthTarget.classList.remove('invalid');
    } else {
      this.lengthTarget.classList.add('invalid');
      this.lengthTarget.classList.remove('valid');
    }

    // at least 1 uppercase and 1 lowercase
    if (/[a-z].*[A-Z]|[A-Z].*[a-z]/.test(value)) {
      this.lowerUpperTarget.classList.add('valid');
      this.lowerUpperTarget.classList.remove('invalid');
    } else {
      this.lowerUpperTarget.classList.add('invalid');
      this.lowerUpperTarget.classList.remove('valid');
    }

    // at least 1 digit and 1 symbol of -#?!@$%^&*
    if (/[-#?!@$%^&*].*\d|\d.*[-#?!@$%^&*]/.test(value)) {
      this.numberSymbolTarget.classList.add('valid');
      this.numberSymbolTarget.classList.remove('invalid');
    } else {
      this.numberSymbolTarget.classList.add('invalid');
      this.numberSymbolTarget.classList.remove('valid');
    }

    // do not include first name, last name or email
    if (
      this.hasFirstNameTarget &&
      this.hasLastNameTarget &&
      this.hasEmailTarget &&
      this.firstNameTarget.value &&
      this.lastNameTarget.value &&
      this.emailTarget.value
    ) {
      if (
        value.includes(this.firstNameTarget.value) ||
        value.includes(this.lastNameTarget.value) ||
        value.includes(
          this.emailTarget.value.slice(0, this.emailTarget.value.indexOf('@')),
        )
      ) {
        this.nameEmailTarget.classList.add('invalid');
        this.nameEmailTarget.classList.remove('valid');
      } else {
        this.nameEmailTarget.classList.add('valid');
        this.nameEmailTarget.classList.remove('invalid');
      }
    } else {
      this.nameEmailTarget.classList.add('valid');
      this.nameEmailTarget.classList.remove('invalid');
      1;
    }

    this.changeIcon();
  }

  changeIcon() {
    this.iconTargets.forEach(icon => {
      if (icon.parentNode.classList.contains('invalid')) {
        icon.className = 'far fa-times fa-fw';
      } else if (icon.parentNode.classList.contains('valid')) {
        icon.className = 'far fa-check fa-fw';
      } else {
        icon.className = 'fal fa-circle fa-fw';
      }
    });
  }
}
