export const getDefaultPath = (itemId, target, period) => {
  let path = '';

  if (itemId) {
    path = `/${target}/${itemId}?dataFrom=${encodeURIComponent(
      period.startDate,
    )}&dataTo=${encodeURIComponent(period.endDate)}`;
  } else {
    path = `/${target}?dataFrom=${encodeURIComponent(period.startDate)}&dataTo=${encodeURIComponent(
      period.endDate,
    )}`;
  }

  return path;
};

export const getMultiQueryPathWithId = (array, query) => {
  let path = '';

  if (array && array.length > 0) {
    array.forEach((item) => {
      path += `&${query}[]=${item.id}`;
    });
  }

  return path;
};
