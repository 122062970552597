import React from 'react';

import { BoltIcon, ForkLiftIcon, ShieldCheckIcon, TrailerIcon } from './Icons';

// @TODO add new icons when ready
export const machineIcons = {
  generator: <BoltIcon />,
  'fork lift': <ForkLiftIcon />,
  trailer: <TrailerIcon />,
  tester: <ShieldCheckIcon />,
};
