import { useMemo, useCallback } from 'react';

export const useGetWorksitesData = (sitesFromApi, currentSites) => {
  const getWorksitesData = useCallback(sites => {
    const worksites = sites.map(site => ({
      id: site.id,
      geometry: site.geometry,
      color: site.color,
      label: site.name,
    }));

    const validWorksites = worksites.filter(
      site => site.geometry && Object.keys(site.geometry).length > 0,
    );

    return validWorksites;
  }, []);

  const worksitesData = useMemo(() => {
    if (currentSites && currentSites.length > 0) {
      return getWorksitesData(currentSites);
    } else {
      return getWorksitesData(sitesFromApi);
    }
  }, [sitesFromApi, currentSites, getWorksitesData]);

  return worksitesData;
};
