import React, { useState } from 'react';
import { number, oneOfType, string } from 'prop-types';

import { MultiSelect, IncursionsChart } from 'atoms';
import { singleChartDataType } from 'API/hooks/useFetchInsights/types';
import { periodType } from 'atoms/DatePicker/types';
import { dateFormatType } from 'API/types';

import { MachineOperatorsTable } from './MachineOperatorsTable/MachineOperatorsTable';
import styles from './MachineInfo.module.scss';

const options = [
  { value: 'incursionHistory', label: 'Incursion History' },
  { value: 'machineOperators', label: 'Machine Operators' },
];

export const MachineInfo = ({
  acknowledgedIncursions,
  unacknowledgedIncursions,
  period,
  dateFormat,
  baseStationId,
}) => {
  const [dropdownOption, setDropdownOption] = useState(options[0]);

  return (
    <div className="pb-4">
      <div className="d-flex justify-content-end align-items-center mb-3">
        <MultiSelect
          currentValues={dropdownOption}
          initialValues={dropdownOption}
          options={options}
          onChange={(selectedValues) => setDropdownOption(selectedValues)}
          configOptions={{
            isMulti: false,
            closeMenuOnSelect: true,
            isSearchable: true,
          }}
          className={styles.multiSelect}
        />
      </div>
      {dropdownOption.value === 'incursionHistory' && (
        <IncursionsChart
          acknowledgedIncursions={acknowledgedIncursions}
          unacknowledgedIncursions={unacknowledgedIncursions}
          period={period}
          dateFormat={dateFormat}
          wrapperClassName={styles.chart}
        />
      )}
      {dropdownOption.value === 'machineOperators' && (
        <MachineOperatorsTable period={period} baseStationId={baseStationId} />
      )}
    </div>
  );
};

MachineInfo.propTypes = {
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
  period: periodType,
  dateFormat: dateFormatType,
  baseStationId: oneOfType([string, number]),
};
