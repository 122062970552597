import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'accessLevelSelector',
    'brandsWrapper',
    'fullAccessSelector',
    'adminSelector',
    'adminWrapper',
    'projectsSitesWrapper',
    'worksitesSelector',
    'projectsSelector',
    'statusText',
  ];

  connect() {
    this.update();
  }

  update() {
    let projectsText;
    let worksitesText;

    if (this.hasAccessLevelSelectorTarget) {
      switch (this.accessLevelSelectorTarget.value) {
        case 'full':
          this.brandsWrapperTarget.style.display = 'none';
          break;
        case 'brand':
          this.brandsWrapperTarget.style.display = 'block';
          break;
      }
    }
    if (this.hasFullAccessSelectorTarget) {
      switch (this.fullAccessSelectorTarget.checked) {
        case true:
          this.projectsSitesWrapperTarget.style.display = 'none';
          break;
        case false:
          this.projectsSitesWrapperTarget.style.display = 'block';
          break;
      }
    }
    if (this.hasAdminSelectorTarget) {
      switch (this.adminSelectorTarget.checked) {
        case true:
          this.adminWrapperTarget.style.display = 'none';
          break;
        case false:
          this.adminWrapperTarget.style.display = 'block';
          break;
      }
    }

    if(this.projectsSelectorTarget.selectedOptions.length > 0) {
      projectsText = Array.from(this.projectsSelectorTarget.selectedOptions).map(e => `<strong>${e.text}</strong>`).join(', ');
    } else {
      projectsText = `<strong>all</strong>`;
    }

    if(this.worksitesSelectorTarget.selectedOptions.length > 0) {
      worksitesText = Array.from(this.worksitesSelectorTarget.selectedOptions).map(e => `<strong>${e.text}</strong>`).join(', ');
    } else {
      worksitesText = `<strong>all</strong>`;
    }

    this.statusTextTarget.innerHTML = `User will have access to ${projectsText} projects and ${worksitesText} worksites.`;
  }
}
