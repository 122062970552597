import React from 'react';
import { bool, string } from 'prop-types';

import { machineIcons } from '../../Icon/utils';
import { Icon } from '../../Icon/Icon';
import colors from '../../../../assets/stylesheets/_colors.scss';
import styles from './MachinesMapMarker.module.scss';
import { DefaultMachineIcon } from '../../Icon/Icons';

export const MachinesMapMarker = ({ path, machineKind, internalId, id, active = false }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.mapMarkerIcon}>
        <i
          className="fa-solid fa-location-dot"
          style={{ fontSize: '35px', color: colors.lightGreen }}
        />
      </div>
      <div className={styles.box}>
        <a href={path} className={styles.boxIcon}>
          <Icon size="large" className="d-flex">
            {machineIcons[machineKind.toLowerCase()] || <DefaultMachineIcon />}
          </Icon>
        </a>
        <div className={styles.boxText}>
          <span className={styles.text}>{internalId}</span>
          <span className={styles.text}>{id}</span>
        </div>
      </div>
    </div>
  );
};

MachinesMapMarker.propTypes = {
  path: string,
  machineKind: string,
  internalId: string,
  id: string,
  active: bool,
};
