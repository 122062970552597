import { shape, arrayOf } from 'prop-types';

import { projectType, fetchedPeriodType, dateFormatType } from '../../API/types';
import { reportsType } from '../../API/hooks/useFetchReports/types';

export const initialReportsDataType = shape({
  initialData: shape({
    availableProjects: arrayOf(projectType),
    availableSites: arrayOf(projectType),
    period: fetchedPeriodType,
    reports: reportsType,
    dateFormat: dateFormatType,
  }),
});
