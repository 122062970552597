import React from 'react';
import PropTypes from 'prop-types';

const NoteButton = ({ icon, color, ...rest }) => (
  <button className={`btn text-${color}`} {...rest}>
    <i className={`${icon}`}></i>
  </button>
);
NoteButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'muted',
    'white',
  ]),
};

NoteButton.defaultProps = {
  onClick: () => {},
  color: 'primary',
};

export default NoteButton;
