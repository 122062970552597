import { arrayOf, instanceOf, number, oneOf, oneOfType, shape, string } from 'prop-types';

export const videoStateType = ['requested', 'processing', 'ready', 'error'];

export const videoType = shape({
  id: number,
  timestamp: oneOfType([string, instanceOf(Date)]),
  state: oneOf(videoStateType),
  duration: number,
  description: string,
  cameras: number,
  statusMessage: oneOf(['dvr_not_online', 'request_error']),
  storeItems: arrayOf(
    shape({
      filename: string,
      cameraId: string,
      url: string,
    }),
  ),
});

export const videosType = arrayOf(videoType);
