/* eslint-disable no-use-before-define */

import { getDefaultPath } from '../../utils';

export const getPath = ({ target, period, tagId, baseStationsIds }) => {
  let path = '';

  path += getDefaultPath(tagId, target, period);
  path += getPathWithBaseStationsIds(baseStationsIds);

  return path;
};

function getPathWithBaseStationsIds(baseStationsIds) {
  let path = '';

  if (baseStationsIds && baseStationsIds.length > 0) {
    baseStationsIds.forEach((baseStationId) => {
      path += `&baseStationIds[]=${baseStationId}`;
    });
  }

  return path;
}
