import React from 'react';
import { string, oneOf, func, bool, node } from 'prop-types';

import styles from './Button.module.scss';

export const Button = ({ children, kind, onClick, disabled, className }) => {
  const getBgColor = () => styles[kind];

  return (
    <button
      type="button"
      disabled={disabled}
      className={`btn ${getBgColor()} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: node.isRequired,
  kind: oneOf(['success', 'dark-1']),
  onClick: func.isRequired,
  disabled: bool,
  className: string,
};
