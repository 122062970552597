import React from 'react';

import { PageHeader, AnalyticsChart } from '../../atoms';
import { Filters } from './Filters';
import { analyticsFetchedDataType } from '../../API/hooks/useFetchAnalytics/types';
import { useAnalytics } from './useAnalytics';
import styles from './Analytics.module.scss';

const Analytics = ({ initialData }) => {
  const {
    chartData: { pageviewsCount, sessionsCount, sessionsTime, avgSessionTime },
    period,
    setPeriod,
    currentAnalytics,
    setCurrentAnalytics,
    dateFormat,
    userOptions,
    currentUsers,
    setCurrentUsers,
    brandOptions,
    currentBrands,
    setCurrentBrands,
    msgToDisplay,
  } = useAnalytics(initialData);

  const pickData = (selectedAnalytic) => {
    switch (selectedAnalytic.value) {
      case 1:
        return pageviewsCount;
      case 2:
        return sessionsCount;
      case 3:
        return sessionsTime;
      case 4:
        return avgSessionTime;
      case 5:
        return sessionsCount; // loginsCount is the same as sessionsCount for now
      default:
        return pageviewsCount;
    }
  };

  return (
    <div className={styles.page}>
      <PageHeader title="Analytics" msgToDisplay={msgToDisplay} />
      <Filters
        period={period}
        setPeriod={setPeriod}
        dateFormat={dateFormat}
        userOptions={userOptions}
        brandOptions={brandOptions}
        currentUsers={currentUsers}
        setCurrentUsers={setCurrentUsers}
        currentBrands={currentBrands}
        setCurrentBrands={setCurrentBrands}
        currentAnalytics={currentAnalytics}
        setCurrentAnalytics={setCurrentAnalytics}
      />
      <AnalyticsChart
        data={pickData(currentAnalytics)}
        period={period}
        dateFormat={dateFormat}
        chartTitle={currentAnalytics.label}
        wrapperClassName={`mb-3 ${styles.analyticsChart}`}
      />
    </div>
  );
};

export default Analytics;

Analytics.propTypes = {
  initialData: analyticsFetchedDataType,
};
