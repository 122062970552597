import { useEffect, useState } from 'react';
import moment from 'moment';

import { cookiePeriodFormat } from 'consts/dates';

export const usePeriodComparison = ({ period, initialPropsPeriod }) => {
  const [arePeriodsTheSame, setArePeriodsTheSame] = useState(false);

  const formattedPeriod = {
    startDate: period.startDate.format(cookiePeriodFormat),
    endDate: period.endDate.format(cookiePeriodFormat),
  };
  const formattedInitialPropsPeriod = {
    startDate: moment(new Date(initialPropsPeriod[0])).format(cookiePeriodFormat),
    endDate: moment(new Date(initialPropsPeriod[1])).format(cookiePeriodFormat),
  };

  useEffect(() => {
    if (
      formattedPeriod.startDate === formattedInitialPropsPeriod.startDate &&
      formattedPeriod.endDate === formattedInitialPropsPeriod.endDate
    ) {
      setArePeriodsTheSame(true);
    } else {
      setArePeriodsTheSame(false);
    }
  }, [formattedPeriod, formattedInitialPropsPeriod]);

  return { arePeriodsTheSame };
};
