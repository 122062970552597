import React from 'react';
import { func, string, arrayOf, shape, number } from 'prop-types';

import { MultiSelect } from '../../../atoms';
import { userType, brandType, dateFormatType } from '../../../API/types';
import { optionsType } from '../../../atoms/Filters/types';
import {
  changeCurrentItemsFormat,
  changeSelectedItemsFormat,
} from '../../../atoms/MultiSelect/utils';
import styles from './Filters.module.scss';
import { periodType } from '../../../atoms/DatePicker/types';
import { DrawerWithDatePicker } from '../../../atoms/Drawer/components/DrawerWithDatePicker/DrawerWithDatePicker';

export const initialAnalyticsOptions = [
  { value: 1, label: 'Pageviews Count' },
  { value: 2, label: 'Sessions Count' },
  { value: 3, label: 'Sessions Time [m]' },
  { value: 4, label: 'Average Session Time [m]' },
  { value: 5, label: 'Logins Count' },
];

export const Filters = ({
  period,
  setPeriod,
  dateFormat,
  userOptions,
  currentUsers,
  setCurrentUsers,
  brandOptions,
  currentBrands,
  setCurrentBrands,
  currentAnalytics,
  setCurrentAnalytics,
}) => {
  return (
    <div className={`${styles.wrapper} mb-3`}>
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentUsers)}
        initialValues={changeCurrentItemsFormat(currentUsers)}
        options={userOptions}
        placeholder="ALL"
        onChange={(selectedValues) => setCurrentUsers(changeSelectedItemsFormat(selectedValues))}
        label="Users"
      />
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentBrands)}
        initialValues={changeCurrentItemsFormat(currentBrands)}
        options={brandOptions}
        placeholder="ALL"
        onChange={(selectedValues) => setCurrentBrands(changeSelectedItemsFormat(selectedValues))}
        label="Brands"
      />
      <DrawerWithDatePicker period={period} setPeriod={setPeriod} dateFormat={dateFormat} />
      <MultiSelect
        currentValues={currentAnalytics}
        initialValues={currentAnalytics}
        options={initialAnalyticsOptions}
        configOptions={{
          isMulti: false,
          closeMenuOnSelect: true,
          isSearchable: true,
        }}
        placeholder="Analytics"
        label="Analytics"
        onChange={(selectedValue) => setCurrentAnalytics(selectedValue)}
      />
    </div>
  );
};

Filters.propTypes = {
  period: periodType,
  setPeriod: func,
  dateFormat: dateFormatType,
  userOptions: optionsType,
  currentUsers: arrayOf(userType),
  setCurrentUsers: func,
  brandOptions: optionsType,
  currentBrands: arrayOf(brandType),
  setCurrentBrands: func,
  currentAnalytics: shape({ value: number, label: string }),
  setCurrentAnalytics: func,
};
