import React, { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { func, string } from 'prop-types';
import moment from 'moment';

import { singlePeriodType } from '../../types';

const DATE_FORMAT = 'YYYY/MM/DD';

export const SingleDatePicker = ({ period, setPeriod, wrapperClassName = '', label }) => {
  const periodInputRef = useRef(null);

  useEffect(() => {
    let calendar;

    if (periodInputRef.current) {
      const parsedPeriod = period.date.format(DATE_FORMAT);

      calendar = flatpickr(periodInputRef.current, {
        mode: 'single',
        maxDate: new Date(),
        defaultDate: parsedPeriod,
        inline: true,
        onChange: ([selectedDate]) => {
          if (selectedDate) {
            return setPeriod({ date: moment(selectedDate) });
          }

          return period;
        },
      });
    }

    return () => {
      calendar?.destroy();
    };
  }, [period.date, periodInputRef]);

  return (
    <div className={`d-flex flex-column ${wrapperClassName}`}>
      {label && (
        <label htmlFor="datepicker-period-field" className="mb-0">
          {label}
        </label>
      )}
      <div className="align-self-center">
        <input id="datepicker-period-field" ref={periodInputRef} hidden />
      </div>
    </div>
  );
};

SingleDatePicker.propTypes = {
  period: singlePeriodType.isRequired,
  setPeriod: func.isRequired,
  wrapperClassName: string,
  label: string,
};
