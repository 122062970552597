import React from 'react';
import { shape, bool, func, arrayOf, number } from 'prop-types';

import { PaginationButton } from './PaginationButton';
import styles from './Pagination.module.scss';

export const Pagination = ({
  pageSizeOptions,
  paginationProps: {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  },
  totalCount,
  isDarkMode = false,
}) => {
  return (
    <div className={`pb-4 ${styles.wrapper}${isDarkMode ? ` ${styles.dark}` : ''}`}>
      <div
        className={`d-flex flex-column justify-content-center align-items-center text-center flex-sm-row ${styles.pagination}`}
      >
        <div className="mb-2 mb-sm-0 mr-sm-1">
          <PaginationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </PaginationButton>
          <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </PaginationButton>
          <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </PaginationButton>
          <PaginationButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </PaginationButton>
        </div>
        <div className={`mb-2 mb-sm-0 px-sm-2 ${styles.pageCount}`}>
          <div>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </div>
        </div>
        <div className={`mb-2 mb-sm-0 mr-sm-2 px-sm-2 ${styles.totalCount}`}>
          <div>
            Total count <strong>{totalCount}</strong>
          </div>
        </div>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className={styles.select}
        >
          {pageSizeOptions.map((pageSizeOption) => (
            <option key={pageSizeOption} value={pageSizeOption}>
              Show {pageSizeOption}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pageSizeOptions: arrayOf(number),
  paginationProps: shape({
    canPreviousPage: bool,
    canNextPage: bool,
    pageOptions: arrayOf(number),
    pageCount: number,
    gotoPage: func,
    nextPage: func,
    previousPage: func,
    setPageSize: func,
    state: shape({
      pageIndex: number,
      pageSize: number,
    }),
  }),
  totalCount: number,
  isDarkMode: bool,
};
