import React from 'react';
import PropTypes from 'prop-types';

import styles from './DefaultView.module.scss';

export const DefaultView = ({
  title,
  count,
  percentageChange,
  countUnit,
  reverseColors,
  hintText,
  handleIconInfoClick,
}) => {
  const renderPercentage = () => {
    if (percentageChange > 0) {
      return (
        <span
          className={`${styles.percentageChange} ${
            reverseColors ? styles.green : styles.red
          }`}
        >
          <i className={`far fa-long-arrow-alt-up ${styles.iconArrow}`}></i>
          {percentageChange}%
        </span>
      );
    } else if (percentageChange < 0) {
      return (
        <span
          className={`${styles.percentageChange} ${
            reverseColors ? styles.red : styles.green
          }`}
        >
          <i className={`far fa-long-arrow-alt-down ${styles.iconArrow}`}></i>
          {Math.abs(percentageChange)}%
        </span>
      );
    } else {
      return (
        <span className={styles.percentageChange}>{percentageChange}%</span>
      );
    }
  };

  return (
    <>
      <div className={`d-flex align-items-center ${styles.boxHeader}`}>
        <h3 className={styles.title}>{title}</h3>
        {!!hintText && (
          <button
            className={`d-flex p-0 ml-2 ${styles.button}`}
            onClick={handleIconInfoClick}
          >
            <i className={`fa-light fa-circle-info ${styles.iconInfo}`} />
          </button>
        )}
      </div>
      <span className={styles.text}>
        {count}
        {countUnit}
      </span>
      {percentageChange && renderPercentage()}
    </>
  );
};

DefaultView.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentageChange: PropTypes.number,
  countUnit: PropTypes.string,
  reverseColors: PropTypes.bool,
  hintText: PropTypes.string,
  handleIconInfoClick: PropTypes.func,
};
