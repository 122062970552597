import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['map'];

  get points() {
    return JSON.parse(this.data.get('points'));
  }

  connect() {
    this.initMap();
  }

  initMap() {
    const mapInitializer = () => {
      var map = new google.maps.Map(this.mapTarget);
      var bounds = new google.maps.LatLngBounds();
      var infoWindow = new google.maps.InfoWindow();

      for (let i = 0; i < this.points.length; i++) {
        var myLatLng = this.points[i];

        var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          label: { text: this.points[i]['id'] + '', color: 'white' },
        });

        bounds.extend(marker.position);
      }

      map.fitBounds(bounds);
    };
    window.maps.init(mapInitializer);
  }
}
