import React from 'react';
import { oneOf, func, string } from 'prop-types';

import { Button } from 'atoms/Button/Button';

import styles from './Tabs.module.scss';

const TABS_OPTIONS = [
  { text: 'Proximity Warning' },
  { text: 'SCS Insights' },
  { text: 'Vision 360' },
  { text: 'Machine Insights' },
];

const getVisibleTabs = (dvrId) => {
  if (!dvrId) {
    return TABS_OPTIONS.filter((tab) => tab.text !== 'Vision 360');
  }

  return TABS_OPTIONS;
};

export const Tabs = ({ activeTab, setActiveTab, dvrId }) => {
  const visibleTabs = getVisibleTabs(dvrId);

  return (
    <div className={styles.wrapper}>
      {visibleTabs.map((tab) => (
        <Button
          key={tab.text}
          kind="dark-1"
          onClick={() => setActiveTab(tab.text)}
          className={tab.text === activeTab ? styles.active : ''}
        >
          {tab.text}
        </Button>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  activeTab: oneOf(['Proximity Warning', 'Vision 360', 'SCS Insights', 'Machine Insights']),
  setActiveTab: func,
  dvrId: string,
};
