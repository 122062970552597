import { errors, events } from './getMessage.constants';

export const getMessage = (messageCode, status) => {
  switch (status) {
    case 'error':
      return errors[messageCode];
    case 'event':
      return events[messageCode];
    default:
      return messageCode;
  }
};
