import useLocalStorageState from 'use-local-storage-state';
import { useMemo, useState } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';

import { useDebounce } from '../../../hooks';
import { cookiePeriodFormat } from 'consts/dates';

export const useStorageFiltersProvider = () => {
  const startDate = useMemo(() => moment().subtract(7, 'days').startOf('day'));
  const endDate = useMemo(() => moment());

  const defaultPeriod = Cookies.get('period')
    ? JSON.parse(Cookies.get('period'))
    : { startDate, endDate };

  const [currentProjects, setCurrentProjects] = useLocalStorageState('currentProjects', {
    defaultValue: null,
  });
  const [currentSites, setCurrentSites] = useLocalStorageState('currentSites', {
    defaultValue: null,
  });
  const [period, setPeriod] = useState({
    startDate: moment(defaultPeriod.startDate),
    endDate: moment(defaultPeriod.endDate),
  });
  const [currentEntities, setCurrentEntities] = useLocalStorageState('currentEntities', {
    defaultValue: null,
  });
  const [currentMachineKinds, setCurrentMachineKinds] = useLocalStorageState(
    'currentMachineKinds',
    {
      defaultValue: null,
    },
  );

  const debouncedProjects = useDebounce(currentProjects, 750);
  const debouncedSites = useDebounce(currentSites, 750);
  const debouncedEntities = useDebounce(currentEntities, 750);
  const debouncedMachineKinds = useDebounce(currentMachineKinds, 750);

  Cookies.set(
    'period',
    JSON.stringify({
      startDate: period.startDate.format(cookiePeriodFormat),
      endDate: period.endDate.format(cookiePeriodFormat),
    }),
  );

  return {
    currentProjects,
    setCurrentProjects,
    debouncedProjects,
    currentSites,
    setCurrentSites,
    debouncedSites,
    period,
    setPeriod,
    currentEntities,
    setCurrentEntities,
    debouncedEntities,
    currentMachineKinds,
    setCurrentMachineKinds,
    debouncedMachineKinds,
  };
};
