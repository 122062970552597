import React from 'react';

import { SwitcherWithTooltip, SwitcherDefault, NavSpinner } from '../../../atoms';
import {
  machinesFetchedDataType,
  machineSchemaType,
} from '../../../API/hooks/useFetchEntities/types';
import { TableSection } from '../components/TableSection';
import { useEntities } from '../useEntities';
import { Filters } from './Filters';
import { MapWrapper } from '../../../atoms/MapWithLayers/MapWrapper/MapWrapper';
import { MapSection } from './MapSection/MapSection';
import styles from './Machines.module.scss';

const Machines = ({ initialData: { machines } }) => {
  const {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    period,
    setPeriod,
    dateFormat,
    searchText,
    setSearchText,
    projectOptions,
    siteOptions,
    entities,
    setShowInactive,
    showInactive,
    setShowArchived,
    showArchived,
    revalidateEntities,
    totalPages,
    totalCount,
    paginationParams,
    setPaginationParams,
    sortingParams,
    handleTableHeaderClick,
    fetchEntitiesIsLoading,
    currentMachineKinds,
    setCurrentMachineKinds,
    fetchedMachineKinds,
  } = useEntities(machines, machineSchemaType);

  return (
    <div className={styles.page}>
      {fetchEntitiesIsLoading && <NavSpinner />}
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <TableSection
            source="machines"
            entities={entities}
            revalidateEntities={revalidateEntities}
            paginationParams={paginationParams}
            setPaginationParams={setPaginationParams}
            totalPages={totalPages}
            totalCount={totalCount}
            sortingParams={sortingParams}
            handleTableHeaderClick={handleTableHeaderClick}
            tableHeaderClassName={styles.tableHeader}
          />
          <div className={entities.length === 0 ? 'py-2' : 'pb-2'}>
            <SwitcherWithTooltip
              onChange={setShowInactive}
              checked={showInactive}
              labelText="Display Inactive Machines"
              switcherId="inactive-machines"
              tooltipText="Display machines with total incursions equal 0"
              wrapperClassName="mr-4"
            />
            <SwitcherDefault
              onChange={setShowArchived}
              checked={showArchived}
              labelText="Display Archived Machines"
            />
          </div>
        </div>
        <div className={styles.map}>
          <Filters
            period={period}
            setPeriod={setPeriod}
            dateFormat={dateFormat}
            projectOptions={projectOptions}
            siteOptions={siteOptions}
            currentProjects={currentProjects}
            setCurrentProjects={setCurrentProjects}
            currentSites={currentSites}
            setCurrentSites={setCurrentSites}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchedMachineKinds={fetchedMachineKinds}
            currentMachineKinds={currentMachineKinds}
            setCurrentMachineKinds={setCurrentMachineKinds}
          />
          <MapWrapper>
            <MapSection machinesData={entities} mapStyle="silver" />
          </MapWrapper>
        </div>
      </div>
    </div>
  );
};

export default Machines;

Machines.propTypes = {
  initialData: machinesFetchedDataType,
};
