import { useGetMultiselectOptions } from '../../hooks';
import { useFetchInsights } from '../../API/hooks';
import { useStorageFilters } from '../../contexts/StorageFiltersContext/useStorageFilters';
import { usePeriodComparison } from 'hooks/usePeriodComparison';

export const useInsights = ({ insights: initialDataFromProps }) => {
  const {
    currentProjects,
    setCurrentProjects,
    debouncedProjects,
    currentSites,
    setCurrentSites,
    debouncedSites,
    period,
    setPeriod,
    currentEntities,
    setCurrentEntities,
    debouncedEntities,
  } = useStorageFilters();

  const { arePeriodsTheSame } = usePeriodComparison({
    period,
    initialPropsPeriod: initialDataFromProps.period,
  });

  const {
    data: {
      projects: projectsFromApi,
      sites: sitesFromApi,
      entities: entitiesFromApi,
      incursionsData,
      chartData,
      dateFormat,
    },
    isLoading,
  } = useFetchInsights({
    initialData: initialDataFromProps,
    shouldFetch: !arePeriodsTheSame,
    currentProjects: debouncedProjects,
    currentSites: debouncedSites,
    currentEntities: debouncedEntities,
    period,
  });

  const projectOptions = useGetMultiselectOptions(projectsFromApi);
  const siteOptions = useGetMultiselectOptions(sitesFromApi);
  const entityOptions = useGetMultiselectOptions(entitiesFromApi);

  return {
    incursionsData,
    chartData,
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    currentEntities,
    setCurrentEntities,
    period,
    setPeriod,
    dateFormat,
    projectOptions,
    siteOptions,
    entityOptions,
    projectsFromApi,
    sitesFromApi,
    entitiesFromApi,
    isLoading,
  };
};
