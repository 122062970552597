import React from 'react';
import { SWRConfig } from 'swr';

import { fetchApi } from '../../utils';
import Analytics from './Analytics';

export const APILinkedAnalytics = (props) => (
  <SWRConfig
    value={{
      fetcher: fetchApi,
    }}
  >
    <Analytics {...props} />
  </SWRConfig>
);

export default (props) => <APILinkedAnalytics {...props} />;
