import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../../assets/stylesheets/_colors.scss';
import styles from '../devicesMap.module.scss';

export const DevicesMapMarker = ({
  label,
  active = false,
  onClick,
  status,
  id,
}) => {
  const getStatusStyle = status => {
    const statusesColors = {
      default: colors.lightGrey,
      inactive: colors.lightGrey,
      good: colors.lightGreen,
      warning: colors.darkOrange,
      problem: 'red',
    };

    return {
      backgroundColor: statusesColors[status] || statusesColors.default,
      boxShadow:
        status !== 'inactive' && statusesColors[status]
          ? `0px 0px 10px ${statusesColors[status]}`
          : 'none',
    };
  };
  return (
    <>
      <div
        className={`${active ? styles.mapMarkerActive : ''} ${
          styles.mapMarker
        } point-id-${id}`}
        {...{ onClick }}
      >
        <div
          style={getStatusStyle(status)}
          className={styles.statusLightIcon}
        />
        <div className={`${styles.mapMarkerLabel}`}>{label}</div>
      </div>
    </>
  );
};

DevicesMapMarker.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  status: PropTypes.string,
};
