import React from 'react';
import PropTypes from 'prop-types';

export const TextField = ({
  placeholder,
  iconName,
  onInput,
  maxWidth,
  className,
  errors = [],
  marginBottom = 3,
  ...props
}) => (
  <div className={`form-group mb-${marginBottom} ${className}`}>
    <div className="input-group" style={{ maxWidth: maxWidth }}>
      <input
        type="text"
        className={`form-control ${errors.length ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        onInput={onInput}
        {...props}
      />
      {iconName && (
        <div
          className="input-group-append"
          style={{
            height: 'calc(1.5em + 0.75rem + 2px)',
          }}
        >
          <span className="input-group-text">
            <i className={`fal ${iconName}`}></i>
          </span>
        </div>
      )}
    </div>
    {errors.map((error, index) => (
      <small id="emailHelp" className="form-text text-danger" key={index}>
        {error}
      </small>
    ))}
  </div>
);

TextField.propTypes = {
  placeholder: PropTypes.string,
  iconName: PropTypes.string,
  onInput: PropTypes.func,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  marginBottom: PropTypes.number,
};

TextField.defaultProps = {
  className: '',
};
