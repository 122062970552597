import React from 'react';
import PropTypes from 'prop-types';

import { topEntities } from '../../../../API/types';
import styles from './TopEntitiesList.module.scss';

export const TopEntitiesList = ({ list, kind }) => {
  const listWithFiveItems = list.slice(0, 5);

  return (
    <ol className="font-weight-bold pl-2 mb-0 mw-100">
      {listWithFiveItems.map((item, i) => (
        <li key={item.id || i} className={`text-left pl-2 ${styles.listItem}`}>
          <span className={`mr-2 ${styles.itemTruncatedText}`}>{item.id}</span>
          <a
            href={kind === 'machines' ? `/base_stations/${item.slug}` : `/tags/${item.slug}`}
            className={styles.link}
          >
            <i className="fa-regular fa-arrow-up-right-from-square" />
          </a>
        </li>
      ))}
    </ol>
  );
};

TopEntitiesList.propTypes = {
  list: topEntities.isRequired,
  kind: PropTypes.oneOf(['machines', 'tags']).isRequired,
};
