import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import styles from './MachineInsights.module.scss';
import { MachineInsightsData } from './MachineInsightsData/MachineInsightsData';

export const MachineInsights = ({ dateFormat, baseStationId }) => {
  return (
    <div className={`card ${styles.card}`}>
      <h3 className={styles.title}>J1939 Data</h3>
      <div className={styles.separator} />
      <MachineInsightsData dateFormat={dateFormat} baseStationId={baseStationId} />
    </div>
  );
};

MachineInsights.propTypes = {
  dateFormat: string,
  baseStationId: oneOfType([string, number]),
};
