import React, { useMemo } from 'react';
import { string } from 'prop-types';

import { MixedChart } from '../../MixedChart';
import { simpleMovingAverage } from '../../../../utils';
import { singleChartDataType } from '../../../../API/hooks/useFetchInsights/types';
import { dateFormatType } from '../../../../API/types';
import { usePeriodName } from '../../hooks';
import { periodType } from '../../../DatePicker/types';

export const IncursionsChart = ({
  acknowledgedIncursions,
  unacknowledgedIncursions,
  period,
  dateFormat,
  wrapperClassName = '',
}) => {
  const periodName = usePeriodName({ period, dateFormat });

  const dataForMovingAverage = useMemo(
    () => unacknowledgedIncursions.map((incursion) => incursion.value),
    [unacknowledgedIncursions],
  );

  const movingAverageData5 = useMemo(
    () => simpleMovingAverage(dataForMovingAverage, 5),
    [simpleMovingAverage, dataForMovingAverage],
  );

  return (
    <div className={wrapperClassName}>
      <MixedChart
        dateFormat={dateFormat}
        periodName={periodName}
        stackedBarDataset1={acknowledgedIncursions}
        stackedBarDataset2={unacknowledgedIncursions}
        lineData5={movingAverageData5}
        dataset1Label="Acknowledged incursions"
        dataset2Label="Unacknowledged incursions"
        lineValuesLabel="Unacknowledged incursions trend"
        chartTitle="People & Machine Incursions"
      />
    </div>
  );
};

IncursionsChart.propTypes = {
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
  period: periodType,
  dateFormat: dateFormatType,
  wrapperClassName: string,
};
