/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useMultiSelect from './useMultiSelect';
import colors from '../../../assets/stylesheets/_colors.scss';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function MultiSelect({
  save,
  initialValues,
  options,
  html_options: { placeholder },
}) {
  const { handleChange, startValues, isLoading } = useMultiSelect(
    save,
    initialValues,
    options,
  );

  const customStyles = {
    control: (provided, { isFocused }) => {
      return {
        ...provided,
        boxShadow: isFocused ? colors.blueBoxShadow : null,
      };
    },
  };

  const customTheme = theme => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: colors.blueFocusBorder,
        neutral20: colors.grayBorder,
        neutral50: colors.graySecondary,
      },
    };
  };

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
          <Select
            styles={customStyles}
            theme={customTheme}
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={startValues}
            isMulti
            options={options}
            isSearchable
            placeholder={placeholder}
            onChange={handleChange}
          />
        )}
    </>
  );
}

MultiSelect.propTypes = {
  save: PropTypes.func,
  initialValues: PropTypes.array,
  options: PropTypes.array,
  html_options: PropTypes.object,
};

export default MultiSelect;
