import { string, arrayOf, shape, oneOfType, number, bool } from 'prop-types';

const multiDataType = arrayOf(
  shape({
    value: oneOfType([string, number]),
    label: string,
  }),
);

const singleDataType = shape({
  value: oneOfType([string, number]),
  label: string,
});

export const dataType = oneOfType([multiDataType, singleDataType]);

export const configOptionsType = shape({
  isMulti: bool,
  closeMenuOnSelect: bool,
  isSearchable: bool,
});
