import React from 'react';
import { func, string, arrayOf } from 'prop-types';

import { MultiSelect, Input } from '../../../../atoms';
import { projectType, worksiteType, dateFormatType } from '../../../../API/types';
import { optionsType } from '../../../../atoms/Filters/types';
import { machineKindType } from '../../../../API/hooks/useFetchEntities/types';
import { useGetMultiselectOptions } from '../../../../hooks';
import {
  changeCurrentItemsFormat,
  changeSelectedItemsFormat,
} from '../../../../atoms/MultiSelect/utils';
import { periodType } from '../../../../atoms/DatePicker/types';
import { DrawerWithDatePicker } from '../../../../atoms/Drawer/components/DrawerWithDatePicker/DrawerWithDatePicker';
import styles from './Filters.module.scss';

export const Filters = ({
  period,
  setPeriod,
  dateFormat,
  projectOptions,
  siteOptions,
  currentProjects,
  setCurrentProjects,
  currentSites,
  setCurrentSites,
  searchText,
  setSearchText,
  fetchedMachineKinds,
  currentMachineKinds,
  setCurrentMachineKinds,
}) => {
  const machinesOptions = useGetMultiselectOptions(fetchedMachineKinds);

  return (
    <div className={styles.wrapper}>
      <DrawerWithDatePicker
        period={period}
        setPeriod={setPeriod}
        dateFormat={dateFormat}
        wrapperClassName={styles.datePicker}
      />
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentProjects)}
        initialValues={changeCurrentItemsFormat(currentProjects)}
        options={projectOptions}
        placeholder="Project"
        onChange={(selectedValues) => setCurrentProjects(changeSelectedItemsFormat(selectedValues))}
      />
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentSites)}
        initialValues={changeCurrentItemsFormat(currentSites)}
        options={siteOptions}
        placeholder="Site"
        onChange={(selectedValues) => setCurrentSites(changeSelectedItemsFormat(selectedValues))}
      />
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentMachineKinds)}
        initialValues={changeCurrentItemsFormat(currentMachineKinds)}
        options={machinesOptions}
        placeholder="Machine Type"
        onChange={(selectedValues) =>
          setCurrentMachineKinds(changeSelectedItemsFormat(selectedValues))
        }
      />
      <div>
        <Input value={searchText} onChange={setSearchText} placeholder="Search ID or Label" />
      </div>
    </div>
  );
};

Filters.propTypes = {
  period: periodType,
  setPeriod: func,
  dateFormat: dateFormatType,
  projectOptions: optionsType,
  currentProjects: arrayOf(projectType),
  setCurrentProjects: func,
  siteOptions: optionsType,
  currentSites: arrayOf(worksiteType),
  setCurrentSites: func,
  searchText: string,
  setSearchText: func,
  fetchedMachineKinds: arrayOf(machineKindType),
  currentMachineKinds: arrayOf(machineKindType),
  setCurrentMachineKinds: func,
};
