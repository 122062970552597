import { useEffect } from 'react';

export const useClickOutsideTimePickerDrawer = (callback, isElementVisible) => {
  useEffect(() => {
    const overlayElement = document.getElementById('overlay');

    if (isElementVisible && overlayElement) {
      overlayElement.addEventListener('click', callback);
    }

    return () => {
      if (overlayElement) {
        overlayElement.removeEventListener('click', callback);
      }
    };
  }, [isElementVisible]);
};
