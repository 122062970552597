import React from 'react';

import { SpinnerPortal } from '../../Portal/SpinnerPortal';
import { LoadingSpinner } from '../LoadingSpinner';

export const NavSpinner = () => {
  return (
    <SpinnerPortal>
      <LoadingSpinner />
    </SpinnerPortal>
  );
};
