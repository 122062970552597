import React from 'react';
import { node } from 'prop-types';

import { StorageFiltersContext } from '../StorageFiltersContext';
import { useStorageFiltersProvider } from './useStorageFiltersProvider';

export const StorageFiltersProvider = ({ children }) => (
  <StorageFiltersContext.Provider value={useStorageFiltersProvider()}>
    {children}
  </StorageFiltersContext.Provider>
);

StorageFiltersProvider.propTypes = {
  children: node,
};
