import React from 'react';
import { func, arrayOf, node, oneOf } from 'prop-types';

import { entityType, projectType, worksiteType, dateFormatType } from '../../API/types';
import { optionsType } from './types';
import { changeCurrentItemsFormat, changeSelectedItemsFormat } from '../MultiSelect/utils';
import { MultiSelect } from '../MultiSelect/MultiSelect';
import { periodType } from '../DatePicker/types';
import { DrawerWithDatePicker } from '../Drawer/components/DrawerWithDatePicker/DrawerWithDatePicker';
import styles from './Filters.module.scss';

export const Filters = ({
  source,
  dateFormat,
  projectOptions,
  siteOptions,
  entityOptions,
  currentProjects,
  setCurrentProjects,
  currentSites,
  setCurrentSites,
  currentEntities,
  setCurrentEntities,
  children = null,
  period,
  setPeriod,
}) => {
  return (
    <div className={`${styles[`${source}Wrapper`]} mb-3`}>
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentProjects)}
        initialValues={changeCurrentItemsFormat(currentProjects)}
        options={projectOptions}
        placeholder="ALL"
        onChange={(selectedValues) => setCurrentProjects(changeSelectedItemsFormat(selectedValues))}
        label="Projects"
      />
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentSites)}
        initialValues={changeCurrentItemsFormat(currentSites)}
        options={siteOptions}
        placeholder="ALL"
        onChange={(selectedValues) => setCurrentSites(changeSelectedItemsFormat(selectedValues))}
        label="Sites"
      />
      {source === 'insights' && (
        <MultiSelect
          currentValues={changeCurrentItemsFormat(currentEntities)}
          initialValues={changeCurrentItemsFormat(currentEntities)}
          options={entityOptions}
          placeholder="ALL"
          onChange={(selectedValues) =>
            setCurrentEntities(changeSelectedItemsFormat(selectedValues))
          }
          label="People & Machines"
        />
      )}
      <div className={`align-self-end ${styles[`${source}DateWrapper`]}`}>
        <DrawerWithDatePicker dateFormat={dateFormat} period={period} setPeriod={setPeriod} />
      </div>
      {children && <>{children}</>}
    </div>
  );
};

Filters.propTypes = {
  source: oneOf(['insights', 'entities', 'heatmap']),
  period: periodType,
  setPeriod: func,
  dateFormat: dateFormatType,
  projectOptions: optionsType,
  currentProjects: arrayOf(projectType),
  setCurrentProjects: func,
  siteOptions: optionsType,
  currentSites: arrayOf(worksiteType),
  setCurrentSites: func,
  entityOptions: optionsType,
  currentEntities: arrayOf(entityType),
  setCurrentEntities: func,
  children: node,
};
