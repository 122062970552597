/* eslint-disable no-use-before-define */

export const getPath = ({ target, period, baseStationId, page, per }) => {
  let path = `/${target}/${baseStationId}/operators?dataFrom=${encodeURIComponent(
    period.startDate,
  )}&dataTo=${encodeURIComponent(period.endDate)}`;

  path += getPathWithPagination(page, per);

  return path;
};

function getPathWithPagination(page, per) {
  let path = '';
  if (page) {
    path += `&page=${page}`;
  }
  if (per) {
    path += `&per=${per}`;
  }
  return path;
}
