export const simpleMovingAverage = (data, days) => {
  if (!data || data.length < days) {
    return [];
  }
  const movingAverageData = [];

  for (let i = 1; i < days; i++) {
    movingAverageData.push(null);
  }

  const maxDataIndex = data.length - days;

  for (let i = 0; i <= maxDataIndex; i++) {
    const dataSlice = data.slice(i, days + i);
    const sum = dataSlice.reduce((acc, current) => acc + current, 0);
    const result = Number((sum / days).toFixed(2));
    movingAverageData.push(result);
  }

  return movingAverageData;
};
