import { useEffect, useState } from 'react';

export const useGetElementHeight = (elementRef) => {
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      const { height } = elementRef.current.getBoundingClientRect();
      setElementHeight(height);
    }
  }, []);

  return { elementHeight };
};
