import React from 'react';
import { string, shape, oneOfType } from 'prop-types';

import { tagSchema, machineSchema } from '../../../../../API/hooks/useFetchEntities/types';
import styles from './InternalIdCell.module.scss';
import cellStyles from '../TableSection.module.scss';

export const InternalIdCell = ({ value, row: { original } }) => {
  return (
    <>
      {value === 'Hidden' ? (
        <div className={cellStyles.cell}>
          <i className="fa-regular fa-eye-slash" /> Hidden
        </div>
      ) : (
        <div className={`d-flex flex-column text-left ${cellStyles.cell}`}>
          <span className={styles.internalId}>{value}</span>
          <span className={styles.id}>{original.id}</span>
        </div>
      )}
    </>
  );
};

InternalIdCell.propTypes = {
  value: string,
  row: shape({
    original: oneOfType([tagSchema, machineSchema]),
  }),
};
