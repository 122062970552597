import useSWR from 'swr';
import axios from 'axios';

import { fetchWorksitesErrorMsg } from '../../utils/errorMsg';
import { useShowMessage } from '../../../hooks';

const fetchWorksites = async () => {
  const { data } = await axios.get(`${process.env.API_URL}/worksites`);
  return data;
};

export const useFetchWorksites = ({ initialData }) => {
  const { msgToDisplay, setMsgToDisplay } = useShowMessage();

  const { data, mutate } = useSWR(`${process.env.API_URL}/worksites`, fetchWorksites, {
    fallbackData: initialData,
    errorRetryCount: 3,
    onError: (err) => {
      setMsgToDisplay({
        msg: `${fetchWorksitesErrorMsg}. Error: ${err.message}`,
        className: 'text-danger',
      });
    },
    onErrorRetry: (err) =>
      setMsgToDisplay({
        msg: `${fetchWorksitesErrorMsg}. Error: ${err.message}`,
        className: 'text-danger',
      }),
    onSuccess: () => {
      setMsgToDisplay({ msg: '', className: '' });
    },
    revalidateOnMount: true,
    keepPreviousData: true,
  });

  return { data, msgToDisplay, mutate };
};
