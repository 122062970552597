import React, { useState } from 'react';

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = event => {
    event && event.preventDefault();
    setIsOpen(true);
  };

  const onClose = () => setIsOpen(false);

  const onClickAway = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return {
    isOpen,
    setIsOpen,
    onOpen,
    onClose,
    onClickAway,
  };
}
