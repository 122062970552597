import React, { useMemo, useCallback } from 'react';

import { ListInfoCardWrapper } from '../../../atoms';
import { BasicCards } from '../../../atoms/TagAndBaseStation';
import { pedestrianIncursionsDataType } from '../../../API/hooks/useFetchTag/types';
import styles from './PedestrianCards.module.scss';

export const PedestrianCards = ({ pedestrianIncursionsData }) => {
  const { totalIncursionsCount, avgDuration, topVehiclesIds } = pedestrianIncursionsData;

  const data = useMemo(
    () => [
      {
        key: 1,
        title: 'Incursions',
        count: totalIncursionsCount || 0,
      },
      {
        key: 2,
        title: 'Average time in detection zone',
        count: avgDuration ? avgDuration.toFixed(2) : 0,
        countUnit: 's',
      },
    ],
    [totalIncursionsCount, avgDuration],
  );

  const getSortedMachines = useCallback(() => {
    return topVehiclesIds.sort((a, b) => b.incursion - a.incursion);
  }, [topVehiclesIds]);

  const sortedMachines = useMemo(() => getSortedMachines(), [getSortedMachines]);

  return (
    <BasicCards title="Pedestrian Safety Insights" data={data}>
      <ListInfoCardWrapper title="Total incursions with Machines:" boxClassName={styles.box}>
        <ol className="pl-3 mb-0 mt-1">
          {sortedMachines.map((item) => (
            <li key={item.id} className="pl-2">
              <div className="d-flex">
                <span className={styles.itemId}>{item.id}</span>
                <span className="font-weight-bold">Total: {item.incursion}</span>
              </div>
            </li>
          ))}
        </ol>
      </ListInfoCardWrapper>
    </BasicCards>
  );
};

PedestrianCards.propTypes = {
  pedestrianIncursionsData: pedestrianIncursionsDataType,
};
