import React from 'react';

export const ForkLiftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <path
        d="M144 0H128V16 160H32 0v32V384v32c0 53 43 96 96 96s96-43 96-96h64c0 53 43 96 96 96s96-43 96-96c0-28.4-12.4-54-32-71.6V288 256v-3.5l-1.5-3.2-112-240L298.2 0H288 144zM128 192h19.5l94.8 87.5 9.2 8.5H264 384v37.5c-10-3.5-20.8-5.5-32-5.5c-41.8 0-77.4 26.7-90.5 64H186.5c-13.2-37.3-48.7-64-90.5-64c-24.6 0-47 9.2-64 24.4V192h96zm136 64L160 160V32H277.8L382.3 256H264zm88 224a64 64 0 1 1 0-128 64 64 0 1 1 0 128zM96 480a64 64 0 1 1 0-128 64 64 0 1 1 0 128zM512 16V0H480V16 432v16h16H624h16V416H624 512V16z"
        stroke="#fff"
        fill="#fff"
      />
    </svg>
  );
};
