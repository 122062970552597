import React from 'react';

import { reportType } from '../../../API/hooks/useFetchReports/types';
import { LoadingSpinner } from '../../../atoms';
import styles from './ReportStatus.module.scss';

export const ReportStatus = ({ generatedReport }) => {
  const renderContent = () => {
    switch (generatedReport.status) {
      case 'waiting':
        return (
          <>
            <h6 className="my-2">Report is being generated</h6>
            <LoadingSpinner size={{ height: 20, width: 20 }} />
            <p className="mt-2 mb-0">Waiting...</p>
          </>
        );
      case 'processing':
        return (
          <>
            <h6 className="my-2">Report is being generated</h6>
            <LoadingSpinner size={{ height: 20, width: 20 }} />
            <p className="mt-2 mb-0">Processing...</p>
          </>
        );
      case 'error':
        return (
          <>
            <h6 className="my-2">Failed to generate report</h6>
            <p className="mt-2 mb-0">Something went wrong!</p>;
          </>
        );
      case 'ready':
        return (
          <>
            <h6 className="my-2">Your report is ready!</h6>
            <a href={generatedReport.downloadUrl}>{generatedReport.filename}</a>
          </>
        );
      default:
        return undefined;
    }
  };

  return <div className={`mt-4 ${styles.wrapper}`}>{renderContent()}</div>;
};

ReportStatus.propTypes = {
  generatedReport: reportType.isRequired,
};
