import React from 'react';
import PropTypes from 'prop-types';
import useSaveToInput from 'hooks/useSaveToInput';
import MultiSelect from './MultiSelect';

const InputLinkedMultiSelect = ({ inputId, ...props }) => {
  const { save } = useSaveToInput(inputId);
  return <MultiSelect save={save} {...props} />;
};

InputLinkedMultiSelect.propTypes = {
  inputId: PropTypes.string,
};

export default props => <InputLinkedMultiSelect {...props} />;
