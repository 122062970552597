import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './SearchBox.module.scss';

export const SearchBox = ({ map, mapsapi, placeholder }) => {
  const input = useRef(null);
  const searchBox = useRef(null);

  useEffect(() => {
    if (!searchBox.current && input.current) {
      searchBox.current = new mapsapi.places.SearchBox(input.current);
      searchBox.current.addListener('places_changed', () => {
        const places = searchBox.current.getPlaces();
        if (places && places[0] && places[0].geometry) {
          map.setCenter(places[0].geometry.location);
          // map.setZoom(12);
        }
      });
    }

    return () => {
      searchBox.current = null;
      mapsapi.event.clearInstanceListeners(searchBox);
    };
  }, [map, mapsapi]);

  return <input ref={input} placeholder={placeholder} type="text" className={styles.input} />;
};

SearchBox.propTypes = {
  map: PropTypes.shape({
    setCenter: PropTypes.func,
    setZoom: PropTypes.func,
  }).isRequired,
  mapsapi: PropTypes.shape({
    places: PropTypes.shape({
      SearchBox: PropTypes.func,
    }),
    event: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
};
