import React, { useState } from 'react';

function useControlledInput(initialValue) {
  const [inputValue, setInputValue] = useState(initialValue);

  const changeHandler = event => {
    setInputValue(event.target.value);
  };

  return {
    inputValue,
    changeHandler,
  };
}

export default useControlledInput;
