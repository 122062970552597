import React from 'react';
import { number, oneOfType, string } from 'prop-types';

import styles from './IdCell.module.scss';

export const IdCell = ({ value }) => {
  return (
    <div className={`py-1 px-2 ${styles.wrapper}`}>
      <i className="fas fa-user-hard-hat mr-2" />
      <span>{value}</span>
    </div>
  );
};

IdCell.propTypes = {
  value: oneOfType([string, number]),
};
