import React from 'react';

export function addInfoWindow(
  googleMapInstance,
  shape,
  properties,
  getApproximateCenter,
) {
  if (properties && properties.info) {
    function createContent() {
      var items = [];
      for (const [key, value] of Object.entries(properties.info)) {
        items.push(
          `<div>
            ${key}: ${value}
          </div>`,
        );
      }
      return `<div>${items.join('')}</div>`;
    }
    const infoWindow = new googleMapInstance.maps.InfoWindow({
      content: createContent(),
      position: getApproximateCenter(shape),
    });

    shape.addListener('click', () => {
      infoWindow.open(googleMapInstance.map, shape);
    });
  }

  return shape;
}

export default addInfoWindow;
