import React from 'react';
import { string } from 'prop-types';

import styles from './DataTypeCell.module.scss';

const getDataTypeProps = (value) => {
  switch (value) {
    case 'event':
      return {
        color: 'green',
        text: 'ON EVENT',
      };
    case 'error':
      return {
        color: 'red',
        text: 'ERROR',
      };
    case 'driver_id':
      return {
        color: 'orange',
        text: 'DRIVER ID',
      };
    case 'software_version':
      return {
        color: 'violet',
        text: 'SOFTWARE VERSION',
      };
    default:
      return {
        color: 'blue',
        text: 'UNKNOWN',
      };
  }
};

export const DataTypeCell = ({ value }) => {
  const { color, text } = getDataTypeProps(value);

  return <div className={`${styles.cell} ${styles[color]}`}>{text}</div>;
};

DataTypeCell.propTypes = {
  value: string,
};
