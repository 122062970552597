import { useMemo } from 'react';
import useSWR from 'swr';

import { errorMessage } from '../../utils';
import { useShowMessage } from '../../../hooks';

export const useFetchMessages = ({ shouldFetch, baseStationId, page, per, sort, sortBy }) => {
  const { msgToDisplay, setMsgToDisplay } = useShowMessage();

  const path = useMemo(() => {
    let localPath = `/base_stations/${baseStationId}/messages`;

    if (page || per || sort || sortBy) {
      localPath += '?';
    }

    if (page) {
      localPath += `page=${page}`;
    }

    if (per) {
      localPath += `&per=${per}`;
    }

    if (sort) {
      localPath += `&sort=${sort}`;
    }

    if (sortBy) {
      localPath += `&sortBy=${sortBy}`;
    }

    return localPath;
  }, [baseStationId, page, per, sort, sortBy]);

  const { data, isLoading, error } = useSWR(shouldFetch ? `${process.env.API_URL}${path}` : null, {
    errorRetryCount: 3,
    onError: (err) => {
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      });
    },
    onErrorRetry: (err) =>
      setMsgToDisplay({
        msg: `${errorMessage}. Error: ${err.message}`,
        className: 'text-danger',
      }),
    onSuccess: () => {
      setMsgToDisplay({ msg: '', className: '' });
    },
    revalidateOnMount: true,
    keepPreviousData: true,
  });

  return { data, msgToDisplay, isLoading, error };
};
