/* eslint-disable no-use-before-define */

import { getDefaultPath, getMultiQueryPathWithId } from '../../utils';

export const getPath = ({
  target,
  period,
  currentProjects,
  currentSites,
  searchText,
  types,
  page,
  per,
  sort,
  sortBy,
  showInactive,
  showArchived,
  machineKinds,
}) => {
  let path = '';

  path += getDefaultPath(null, target, period);
  path += getMultiQueryPathWithId(currentProjects, 'projectsIds');
  path += getMultiQueryPathWithId(currentSites, 'sitesIds');
  path += getPathWithSearchText(searchText);
  path += getPathWithEntitiesTypes(types);
  path += getPathWithPagination(page, per);
  path += getPathWithSorting(sort, sortBy);
  path += getPathWithInactiveEntities(showInactive);
  path += getPathWithArchivedEntities(showArchived);
  path += getPathWithMachineKinds(machineKinds);

  return path;
};

function getPathWithSearchText(searchText) {
  let path = '';

  if (searchText) {
    path += `&search=${searchText}`;
  }

  return path;
}

function getPathWithEntitiesTypes(entitiesTypes) {
  let path = '';

  if (entitiesTypes && entitiesTypes.length > 0) {
    entitiesTypes.forEach((entitiesType) => {
      path += `&types[]=${entitiesType}`;
    });
  }

  return path;
}

function getPathWithPagination(page, per) {
  let path = '';
  if (page) {
    path += `&page=${page}`;
  }
  if (per) {
    path += `&per=${per}`;
  }
  return path;
}

function getPathWithSorting(sort, sortBy) {
  let path = '';

  if (sort) {
    path += `&sort=${sort}`;
  }
  if (sortBy) {
    let mappedSortBy = sortBy;
    if (mappedSortBy === 'label') {
      mappedSortBy = 'name';
    }
    if (mappedSortBy === 'lastActivityFormatted') {
      mappedSortBy = 'lastActivity';
    }
    if (mappedSortBy === 'id') {
      mappedSortBy = 'serial_number';
    }
    path += `&sortBy=${mappedSortBy}`;
  }

  return path;
}

function getPathWithInactiveEntities(showInactive) {
  let path = '';

  if (showInactive) {
    path += `&include_inactive=1`;
  } else {
    path += `&include_inactive=0`;
  }

  return path;
}

function getPathWithArchivedEntities(showArchived) {
  let path = '';

  if (showArchived) {
    path += `&include_archived=1`;
  } else {
    path += `&include_archived=0`;
  }

  return path;
}

function getPathWithMachineKinds(machineKinds) {
  let path = '';

  if (machineKinds && machineKinds.length > 0) {
    machineKinds.forEach((machineKind) => {
      path += `&machine_kinds[]=${machineKind.key}`;
    });
  }

  return path;
}
