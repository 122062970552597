import React from 'react';

import { initialReportsDataType } from './types';
import { useReports } from './useReports';
import { ReportsList } from './ReportsList';
import styles from './Reports.module.scss';
import { ReportStatus } from './ReportStatus';
import GenerateReportForm from './GenerateReportForm/GenerateReportForm';

const Reports = ({ initialData }) => {
  const {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    format,
    setFormat,
    period,
    setPeriod,
    projectOptions,
    siteOptions,
    handleClick,
    isLoading,
    generatedReport,
    reports,
  } = useReports({ initialData });

  return (
    <div className={styles.page}>
      <div className="mb-2">
        <button type="button" className="btn btn-dark">
          Generate a Report
        </button>
      </div>
      <div className={styles.box}>
        <GenerateReportForm
          currentProjects={currentProjects}
          setCurrentProjects={setCurrentProjects}
          currentSites={currentSites}
          setCurrentSites={setCurrentSites}
          format={format}
          setFormat={setFormat}
          period={period}
          setPeriod={setPeriod}
          projectOptions={projectOptions}
          siteOptions={siteOptions}
          dateFormat={initialData.dateFormat}
          isLoading={isLoading}
          handleClick={handleClick}
        />
        {generatedReport && <ReportStatus generatedReport={generatedReport} />}
        {reports.length > 0 && (
          <ReportsList reports={reports} dateFormat={initialData.dateFormat} />
        )}
      </div>
    </div>
  );
};

export default Reports;

Reports.propTypes = {
  initialData: initialReportsDataType,
};
