import React, { forwardRef } from 'react';
import { shape, string, node, bool } from 'prop-types';

import styles from './PageHeader.module.scss';
import { LoadingSpinner } from '../LoadingSpinner';

export const PageHeader = forwardRef(({ title, msgToDisplay, isLoading, children }, ref) => {
  return (
    <div
      className="d-flex flex-wrap justify-content-between align-items-center pb-4 flex-sm-nowrap"
      ref={ref}
    >
      <div className="d-flex align-items-center">
        <h1 className={`${styles.title} mb-0 pr-4`}>{title}</h1>
        {isLoading && <LoadingSpinner />}
      </div>
      {msgToDisplay.msg && (
        <span className={`${msgToDisplay.className} ${styles.fetchInfo}`}>{msgToDisplay.msg}</span>
      )}
      {children && (
        <div className={`pl-sm-4 ${msgToDisplay.msg ? 'mt-2 mt-sm-0' : ''}`}>{children}</div>
      )}
    </div>
  );
});

PageHeader.propTypes = {
  title: string.isRequired,
  msgToDisplay: shape({
    msg: string,
    className: string,
  }).isRequired,
  isLoading: bool,
  children: node,
};
