import { node } from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const useActiveMachine = () => {
  const [activeMachineId, setActiveMachineId] = useState(null);

  const toggleActiveMachineId = (id) =>
    activeMachineId === id ? setActiveMachineId(null) : setActiveMachineId(id);

  return { activeMachineId, toggleActiveMachineId };
};

const ActiveMachineContext = createContext(undefined);

export const ActiveMachineContextProvider = ({ children }) => (
  <ActiveMachineContext.Provider value={useActiveMachine()}>
    {children}
  </ActiveMachineContext.Provider>
);

ActiveMachineContextProvider.propTypes = {
  children: node,
};

export const useActiveMachineContext = () => {
  const context = useContext(ActiveMachineContext);
  if (context === undefined) {
    throw new Error('useActiveMachineContext must be within ActiveMachineContextProvider');
  }
  return context;
};
