import React from 'react';
import { string, node } from 'prop-types';

export const SwitcherWrapper = ({ children, wrapperClassName = '' }) => (
  <div className={`d-inline-flex align-items-center ${wrapperClassName}`}>
    {children}
  </div>
);

SwitcherWrapper.propTypes = {
  children: node.isRequired,
  wrapperClassName: string,
};
