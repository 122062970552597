import React, { useMemo } from 'react';

import { BasicCards } from 'atoms/TagAndBaseStation';
import { incursionsDataType } from 'API/hooks/useFetchBaseStation/types';

export const SafetyInsightsCards = ({ incursionsData }) => {
  const {
    totalIncursionsCount,
    acknowledgedIncursionsCount,
    acknowledgedPercentage,
    operatorsCount,
  } = incursionsData;

  const data = useMemo(
    () => [
      {
        key: 1,
        title: 'Incursions',
        count: totalIncursionsCount,
      },
      {
        key: 2,
        title: 'Acknowledged Incursions',
        count: acknowledgedIncursionsCount,
      },
      {
        key: 3,
        title: 'Acknowledgment Score',
        count: acknowledgedPercentage,
        countUnit: '%',
      },
      {
        key: 4,
        title: 'Number of Operators',
        count: operatorsCount,
      },
    ],
    [totalIncursionsCount, acknowledgedIncursionsCount, acknowledgedPercentage, operatorsCount],
  );

  return <BasicCards data={data} />;
};

SafetyInsightsCards.propTypes = {
  incursionsData: incursionsDataType,
};
