import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../contextualData.module.scss';

export const ContextualMapMarker = ({ properties, mapInstance, position }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  function createContent() {
    if (properties && properties.info) {
      var items = [];
      for (const [key, value] of Object.entries(properties.info)) {
        items.push(
          `<div>
            ${key}: ${value}
          </div>`,
        );
      }
      return `<div>${items.join('')}</div>`;
    }
  }

  const infoWindow = new mapInstance.maps.InfoWindow({
    content: createContent(),
    position: { lat: position[1], lng: position[0] },
  });

  const handleClicked = () => {
    infoWindow.open(mapInstance.map);
    setShowInfoWindow(!showInfoWindow);
  };
  return (
    <>
      <div className={styles.pointMarker} onClick={handleClicked} />
    </>
  );
};

ContextualMapMarker.propTypes = {
  properties: PropTypes.object,
  mapInstance: PropTypes.object,
  position: PropTypes.array,
};
