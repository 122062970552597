import { useMemo } from 'react';

export const useValidData = data => {
  if (Array.isArray(data)) {
    const validaData = useMemo(() => data.filter(item => !!item), [data]);
    return validaData;
  }

  return null;
};
