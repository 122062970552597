import { Controller } from 'stimulus';

const LEAVING_PAGE_MESSAGE = 'Changes you made may not be saved.';
export default class extends Controller {
  setChanged(changed) {
    this.data.set('changed', changed);
  }

  isFormChanged() {
    return this.data.get('changed') == 'true';
  }

  connect() {
    this.setChanged('false');
    this.defaultValues = this.serializedArray();

    // Changing URL event
    window.addEventListener('turbolinks:before-visit', this.changingUrl);

    // Closing tab or browser
    window.addEventListener('beforeunload', this.leavingPage);

    // Submit event
    this.element.addEventListener('submit', this.changingUrl);
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.leavingPage);
    window.removeEventListener('turbolinks:before-visit', this.changingUrl);
    this.element.removeEventListener('submit', this.changingUrl);
  }

  leavingPage = e => {
    this.validateChanges();
    if (this.isFormChanged()) {
      event.returnValue = LEAVING_PAGE_MESSAGE;
      return event.returnValue;
    }
  };

  changingUrl = e => {
    if (event.type == 'submit') {
      window.removeEventListener('beforeunload', this.leavingPage);
      this.defaultValues = this.serializedArray();
      return;
    }
    this.validateChanges();
    if (this.isFormChanged()) {
      if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
        event.preventDefault();
      }
    }
  };

  validateChanges = () => {
    var newValues = this.serializedArray();
    if (
      this.validateErrors() &&
      newValues &&
      this.defaultValues.every(
        (element, index) => element.value === newValues[index].value,
      )
    ) {
      this.setChanged('false');
    } else {
      this.setChanged('true');
    }
  };

  validateErrors = () => {
    var result = true;
    Array.prototype.slice.call(this.element.elements).forEach(el => {
      if (el.classList.contains('is-invalid')) result = false;
    });
    return result;
  };

  serializedArray = () => {
    var arr = [];
    Array.prototype.slice.call(this.element.elements).forEach(function(field) {
      if (
        !field.name ||
        field.disabled ||
        ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1
      )
        return;
      if (['checkbox', 'radio'].indexOf(field.type) > -1 && !field.checked)
        return;
      if (field.name !== 'authenticity_token') {
        arr.push({
          name: field.name,
          value: field.value,
        });
      }
    });
    return arr;
  };
}
