import { shape, number, string, arrayOf, oneOfType } from 'prop-types';
import {
  projectType,
  worksiteType,
  fetchedPeriodType,
  notesType,
  dateFormatType,
  topEntities,
} from '../../types';
import { singleChartDataType } from '../useFetchInsights/types';

export const pedestrianIncursionsDataType = shape({
  totalIncursionsCount: number,
  avgDuration: number,
  topVehiclesIds: topEntities,
});

export const driverIncursionsDataType = shape({
  totalIncursionsCount: number,
  acknowledgedIncursionsCount: number,
  acknowledgedPercentage: number,
  unacknowledgedIncursionsCount: number,
  avgAuthorisationTime: number,
});

const tagType = shape({
  id: oneOfType([string, number]),
  serialNumber: string,
  label: string,
  avgDaysBetweenTests: number,
});

const chartDataType = shape({
  acknowledgedIncursions: singleChartDataType,
  unacknowledgedIncursions: singleChartDataType,
});

const baseStationFilterDataType = arrayOf(
  shape({
    id: oneOfType([string, number]),
    serialNumber: string,
    label: string,
  }),
);

export const tagFetchedDataType = shape({
  projects: arrayOf(projectType),
  sites: arrayOf(worksiteType),
  period: fetchedPeriodType,
  pedestrianIncursionsData: pedestrianIncursionsDataType,
  driverIncursionsData: driverIncursionsDataType,
  tag: tagType,
  chartData: chartDataType,
  notes: notesType,
  userName: string,
  dateFormat: dateFormatType,
  baseStationFilterData: baseStationFilterDataType,
});
