import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../../assets/stylesheets/_colors.scss';
import styles from './Marker.module.scss';

export const InsightsMapMarker = ({ hoverText, onClick, mostRecent }) => (
  <>
    <div
      style={{
        backgroundColor: mostRecent ? colors.markerGreen : colors.markerBlue,
      }}
      className={styles.mapMarker}
      onClick={onClick}
    />
    {hoverText && <span className={styles.mapMarkerTooltip}>{hoverText}</span>}
  </>
);

InsightsMapMarker.propTypes = {
  hoverText: PropTypes.object,
  onClick: PropTypes.func,
  mostRecent: PropTypes.bool,
};
