import React from 'react';
import { TextField } from 'atoms';
import PropTypes from 'prop-types';

import styles from '../worksites.module.scss';

export const ZonesNav = ({
  searchFilter,
  filterFn,
  onAddWorksite,
  onClose,
  isEditing,
}) => (
  <div className={`${isEditing ? 'd-flex justify-content-between' : ''}`}>
    <div
      className={`d-flex ${
        isEditing ? 'justify-content-end' : 'justify-content-between'
      }`}
      style={{ height: '40px' }}
    >
      {isEditing ? (
        <button
          type="button"
          className={`btn btn-outline-secondary ${styles.activateButton}`}
          onClick={onClose}
        >
          <i className="far fa-long-arrow-alt-left"></i>{' '}
          <span className="d-none d-sm-inline">Back to Sites</span>
        </button>
      ) : (
        <>
          <TextField
            placeholder="Search Sites"
            iconName="fa-search"
            defaultValue={searchFilter}
            onInput={filterFn}
            maxWidth="273px"
          />
          <button
            type="button"
            className={`btn btn-success ${styles.activateButton}`}
            onClick={onAddWorksite}
          >
            <i className="fal fa-plus-square"></i>{' '}
            <span className="d-none d-sm-inline px-2">Add Site</span>
          </button>
        </>
      )}
    </div>
  </div>
);

ZonesNav.propTypes = {
  filterFn: PropTypes.func,
  onAddWorksite: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  searchFilter: PropTypes.string,
};

ZonesNav.defaultProps = {
  filterFn: () => {},
};

export default ZonesNav;
