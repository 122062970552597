import { useEffect, useRef, useState } from 'react';
import { createConsumer } from '@rails/actioncable';

import { useFetchVideos } from 'API/hooks/useFetchVideos/useFetchVideos';
import { downloadVideo } from 'API/mutations/videos';

export const useVideos = (baseStationId) => {
  const [isRequestVideoLoading, setIsRequestVideoLoading] = useState(false);
  const [requestVideoError, setRequestVideoError] = useState(null);

  const {
    data: videos,
    isLoading: isHistoricVideosLoading,
    mutate,
  } = useFetchVideos({
    shouldFetch: true,
    baseStationId,
  });

  const wsConsumer = useRef(null);
  const wsChannel = useRef(null);

  useEffect(() => {
    wsConsumer.current = createConsumer();

    return () => {
      wsConsumer.current.disconnect();
    };
  }, []);

  const requestVideo = async (duration, timestamp, description) => {
    try {
      setIsRequestVideoLoading(true);
      setRequestVideoError(null);
      const { data: video } = await downloadVideo({
        baseStationId,
        duration,
        timestamp,
        description,
      });
      if (video.id) {
        mutate();
        wsChannel.current =
          wsConsumer.current &&
          wsConsumer.current.subscriptions.create(
            {
              channel: 'VideoClipsChannel',
              id: video.id,
            },
            {
              received: (receivedVideo) => {
                if (receivedVideo.state === 'ready' || receivedVideo.state === 'error') {
                  mutate();
                }
              },
            },
          );
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.error === 'timestampTaken') {
        setRequestVideoError(error.response.data.error);
      }
    } finally {
      setIsRequestVideoLoading(false);
    }
  };

  useEffect(() => {
    if (!wsChannel.current && !!videos) {
      const unfinishedVideos = videos.filter(
        (video) => video.state === 'requested' || video.state === 'processing',
      );
      if (unfinishedVideos.length > 0) {
        unfinishedVideos.forEach((unfinishedVideo) => {
          wsChannel.current =
            wsConsumer.current &&
            wsConsumer.current.subscriptions.create(
              {
                channel: 'VideoClipsChannel',
                id: unfinishedVideo.id,
              },
              {
                received: (receivedVideo) => {
                  if (receivedVideo.state === 'ready' || receivedVideo.state === 'error') {
                    mutate();
                  }
                },
              },
            );
        });
      }
    }
  }, [videos]);

  return {
    videos,
    isHistoricVideosLoading,
    requestVideo,
    isRequestVideoLoading,
    requestVideoError,
  };
};
