import React, { useMemo } from 'react';

import { driverIncursionsDataType } from '../../../API/hooks/useFetchTag/types';
import { BasicCards } from 'atoms/TagAndBaseStation';

export const VehicleOperatorCards = ({ driverIncursionsData }) => {
  const {
    totalIncursionsCount,
    acknowledgedIncursionsCount,
    avgAuthorisationTime,
    acknowledgedPercentage,
  } = driverIncursionsData;

  const data = useMemo(
    () => [
      {
        key: 1,
        title: 'Incursions',
        count: totalIncursionsCount ? totalIncursionsCount : 0,
      },
      {
        key: 2,
        title: 'Acknowledged Incursions',
        count: acknowledgedIncursionsCount ? acknowledgedIncursionsCount : 0,
      },
      {
        key: 3,
        title: 'Average time to acknowledge',
        count: avgAuthorisationTime ? avgAuthorisationTime : 0,
        countUnit: 's',
      },
      {
        key: 4,
        title: 'Percentage of incursions acknowledged within 10s',
        count: acknowledgedPercentage ? acknowledgedPercentage : 0,
        countUnit: '%',
      },
    ],
    [
      totalIncursionsCount,
      acknowledgedIncursionsCount,
      avgAuthorisationTime,
      acknowledgedPercentage,
    ],
  );

  return <BasicCards title="Vehicle Operator Safety Insights" data={data} />;
};

VehicleOperatorCards.propTypes = {
  driverIncursionsData: driverIncursionsDataType,
};
