import React from 'react';
import { string, node } from 'prop-types';

import { useMapWrapper } from './useMapWrapper';
import styles from './MapWrapper.module.scss';

const MAP_BOTTOM_SPACE = 24;

export const MapWrapper = ({ children, wrapperClassName = '' }) => {
  const { wrapperRef, mapPosition } = useMapWrapper();

  return (
    <div
      ref={wrapperRef}
      style={{
        height: `calc(100vh - ${mapPosition.top}px - ${MAP_BOTTOM_SPACE}px`,
      }}
      className={`${styles.wrapper} ${wrapperClassName}`}
    >
      {children}
    </div>
  );
};

MapWrapper.propTypes = {
  children: node.isRequired,
  wrapperClassName: string,
};
