import { useGetHeatmapData, useGetWorksitesData, useGetMultiselectOptions } from '../../hooks';
import { useFetchIncursions } from '../../API/hooks';
import { useStorageFilters } from '../../contexts/StorageFiltersContext/useStorageFilters';
import { usePeriodComparison } from 'hooks/usePeriodComparison';

export const useHeatmap = (initialDataFromProps) => {
  const {
    currentProjects,
    setCurrentProjects,
    debouncedProjects,
    currentSites,
    setCurrentSites,
    debouncedSites,
    period,
    setPeriod,
  } = useStorageFilters();

  const { arePeriodsTheSame } = usePeriodComparison({
    period,
    initialPropsPeriod: initialDataFromProps.period,
  });

  const {
    data: { projects: projectsFromApi, sites: sitesFromApi, incursions, dateFormat },
    msgToDisplay,
    isLoading,
  } = useFetchIncursions({
    initialData: initialDataFromProps,
    shouldFetch: !arePeriodsTheSame,
    currentProjects: debouncedProjects,
    currentSites: debouncedSites,
    period,
  });

  const projectOptions = useGetMultiselectOptions(projectsFromApi);
  const siteOptions = useGetMultiselectOptions(sitesFromApi);

  const heatmapData = useGetHeatmapData(incursions);
  const worksitesData = useGetWorksitesData(sitesFromApi, currentSites);

  return {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    period,
    setPeriod,
    dateFormat,
    projectOptions,
    siteOptions,
    msgToDisplay,
    heatmapData,
    worksitesData,
    isLoading,
  };
};
