import React from 'react';
import moment from 'moment';
import { string, number, arrayOf, shape } from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { dateFormatType } from '../../../../API/types';
import { ChartWrapper } from '../../ChartWrapper';
import { usePeriodName } from '../../hooks';

import colors from '../../../../../assets/stylesheets/_colors.scss';
import { periodType } from '../../../DatePicker/types';

export const AnalyticsChart = ({
  data,
  period,
  dateFormat,
  chartTitle,
  yAxisLabel = '',
  valuesLabel = '',
  wrapperClassName = '',
}) => {
  const periodName = usePeriodName({ period, dateFormat });

  const values = data.map((v) => v.value);
  const timestamps = data.map((v) => v.timestamp);
  const labels = Array(data.length).fill('');
  const chartBarData = {
    labels,
    datasets: [
      {
        backgroundColor: colors.gray2,
        label: valuesLabel,
        borderWidth: 1,
        data: values,
      },
    ],
  };

  const chartProps = {
    options: {
      title: {
        display: true,
        padding: 20,
        text: chartTitle,
        fontSize: 16,
        fontColor: '#000',
        fontStyle: 'normal',
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            scaleLabel: {
              labelString: yAxisLabel,
              display: true,
            },
            ticks: {
              precision: 3,
              suggestedMin: 0,
            },
          },
        ],
        xAxes: [
          {
            position: 'bottom',
            scaleLabel: {
              labelString: `Time Period - ${periodName}`,
              display: true,
              padding: 0,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem) => {
            return `${valuesLabel}: ${values[tooltipItem.index]}`;
          },
          afterLabel: (tooltipItem) => {
            return `${moment(timestamps[tooltipItem.index]).format(
              `DD/MM/YY${timestamps[tooltipItem.index].length > 20 ? ', HH:mm:ss Z' : ''}`,
            )}`;
          },
        },
      },
    },
    legend: {
      display: false,
    },
  };

  const chartBarProps = {
    data: chartBarData,
    width: 500,
    ...chartProps,
  };

  return (
    <ChartWrapper wrapperClassName={wrapperClassName}>
      <Bar {...chartBarProps} />
    </ChartWrapper>
  );
};

AnalyticsChart.propTypes = {
  data: arrayOf(shape({ value: number, timestamp: string })),
  period: periodType,
  dateFormat: dateFormatType,
  chartTitle: string,
  wrapperClassName: string,
  yAxisLabel: string,
  valuesLabel: string,
};
